import React, { useContext } from "react";
import styled from "styled-components";
import { DirectionContext } from "context/direction";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 12px 12px 24px;

  @media (max-width: 570px) {
    padding: 24px 12px 12px 12px;
  }
`;

const NewsCardWrapper = styled.div`
  max-width: 560px;
  min-height: 290px;
  width: 560px;
  background: #1d1f25;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  display: grid;
  grid-template-rows: 3fr 1fr;
  text-align: ${(props) => props.align};

  img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    max-height: 210px;
  }

  @media (max-width: 570px) {
    grid-template-rows: 1fr 0.7fr;
    width: 90vw;
    min-height: 200px;
    max-height: 320px;

    img {
      max-height: 145px;
      aspect-ratio: unset;
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  line-height: 26px;
  @media (max-width: 570px) {
    font-size: 16px;
  }
`;

const Background = styled.div`
  background-image: url("../assets/news/${(props) => props.image}");
  background-size: cover;
  background-position: center ;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 10px 10px 0px 0px;

  @media (max-width: 570px) {
  }
`;
export default function NewsCard({
  image,
  title,
  subtitle,
  text,
  date,
  like,
  bookmark,
}) {
  const { rightLeft } = useContext(DirectionContext);

  return (
    <NewsCardWrapper align={rightLeft}>
      <Background image={image} />
      {/* <img src={"../assets/news/" + image} alt="news" /> */}
      <TextWrapper>
        <Title>{title}</Title>
      </TextWrapper>
    </NewsCardWrapper>
  );
}
