import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/signin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  place-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  padding: 0 30%;
  @media screen and (max-width: 1450px) {
    padding: 0 20%;
  }
`;
const Title = styled.text`
  font-weight: 700;
  font-size: 48px;
  line-height: 110%;
`;
const Subtitle = styled.text`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
`;
const Logo = styled.div`
  position: absolute;
  top: 50px;
  left: 50px;
`;
export default function SignLeft() {
  const { t } = useTranslation();

  return (
    <LeftSide>
      {/* <Link to="/">
        <Logo>
          <img src="/assets/rightlogo.svg" alt="logo" />
        </Logo>
      </Link> */}

      <TextWrapper>
        <Title> {t("loginpagetitle")} </Title>
        <Subtitle>{t("loginpagetext")}</Subtitle>
      </TextWrapper>
    </LeftSide>
  );
}
