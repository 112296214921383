import React, { createContext, useContext } from "react";
import "./index.css";
import "./App.css";
import i18n from "i18next";
import { I18nextProvider, useTranslation } from "react-i18next";
import eng from "./lang/eng.json";
import ar from "./lang/ar.json";
import { initReactI18next } from "react-i18next";
import LandingPage from "./pages/LandingPage";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import PaymentFailed from "./pages/PaymentFailed";
import PaymentSuccess from "./pages/PaymentSuccess";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./pages/NewPassword";
import BookSuccess from "./pages/BookSuccess";
import FirstCryptoBank from "./pages/FirstCryptoBank";
import GulfCryptoBank from "./pages/GulfCryptoBank";
import GlobalStyles from "GlobalStyles";
import { UserProvider } from "./context/user";
import { AnimationProvider } from "context/animation";
import Referal from "pages/Referal";
import "pages/affiliate";
import { DirectionProvider } from "context/direction";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import Licences from "pages/Licences";
import EmailVerifySuccess from "pages/EmailVerifySuccess";
import Packages from "pages/Packages";
import SupportCheck from "pages/SupportCheck";
import { NewApiContext, NewApiProvider } from "context/newapi";
import Bubble from "pages/Bubble";
import Article3 from "pages/Article3";
import Article4 from "pages/Article4";
import { ModalProvider } from "context/modals";
import { LanguageProvider } from "context/language";
import { SettingModalProvider } from "context/modals";
import { PriceProvider } from "context/prices";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Candidates from "pages/Candidates";
import $ from "jquery";
import config from "./config";
import Banneduser from "pages/Banneduser";

const selectedLanguage = localStorage.getItem("selectedLanguage") || "eng";

i18n.use(initReactI18next).init({
  resources: {
    eng: {
      translation: eng,
    },
    ar: {
      translation: ar,
    },
  },
  lng: selectedLanguage, // default language
  fallbackLng: "ar", // fallback language
});
let isLogged;
if (localStorage.getItem("email")) {
  isLogged = true;
} else {
  isLogged = false;
}

const projectId = "9123dffe7846a58a349e986ec0ea1921";

const chains = [bsc];

export const ProviderContext = createContext(null);

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ version: 2, projectId, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

export default function App() {
  const params = new URLSearchParams(window.location.search);
  const user = useContext(NewApiContext);
  const isBanned = localStorage.getItem("banned");
  useEffect(() => {
    if (user?.user.banned === 0) {
      window.location.href = "/banned";
    }
    // console.log(user);
  }, [user]);

  // useEffect(() => {
  //   $.ajax({
  //     url: config.SHOULD_VOTE,
  //     method: "get",
  //     success: (response) => {
  //       const resp = JSON.parse(response);
  //       console.log(resp.shouldVote);
  //       localStorage.setItem("shouldVote", resp.shouldVote);
  //       // localStorage.setItem("shouldVote", resp.shouldVote);
  //     },
  //     error: (error) => {
  //       console.error("Error fetching user data:", error);
  //     },
  //   });
  // }, []);

  params.delete("lang");

  return (
    <WagmiConfig client={wagmiClient}>
      <ProviderContext.Provider value={provider}>
        <NewApiProvider>
          <GlobalStyles direction={selectedLanguage === "ar" ? "rtl" : "ltr"} />
          <DirectionProvider>
            <SettingModalProvider>
              <ModalProvider>
                <AnimationProvider>
                  <UserProvider>
                    <PriceProvider>
                      <BrowserRouter>
                        <LanguageProvider>
                          <I18nextProvider i18n={i18n}>
                            <Routes>
                              <Route exact path="/" element={<LandingPage />} />
                              <Route path="/register" element={<Signup />} />
                              <Route path="/login" element={<Login />} />
                              <Route
                                path="/dashboard"
                                element={<Dashboard />}
                              />
                              <Route
                                path="/paymentfail"
                                element={<PaymentFailed />}
                              />
                              <Route
                                path="/paymentsuccess"
                                element={<PaymentSuccess />}
                              />
                              <Route
                                path="/booksuccess"
                                element={<BookSuccess />}
                              />
                              <Route
                                path="/resetpassword"
                                element={<ResetPassword />}
                              />
                              <Route
                                path="/newpassword"
                                element={<NewPassword />}
                              />
                              <Route
                                path="/البحرين-تمنح-بنك-الخليج-الرقمي-جائزة-أول-بنك-رقمي-عربي"
                                element={<FirstCryptoBank />}
                              />
                              <Route path="/bubbles" element={<Bubble />} />

                              <Route
                                path="/بنك-الخليج-الرقمي"
                                element={<GulfCryptoBank />}
                              />
                              <Route path="banned" element={<Banneduser />} />
                              <Route
                                path="/بنك-الخليج-الرقمي-يعلن-عن-مفاجأة-للعملات-الرقمية"
                                element={<Article3 />}
                              />
                              <Route
                                path="/بنك-الخليج-الرقمي-حين-يبدع-العرب"
                                element={<Article4 />}
                              />
                              <Route path="/referral" element={<Referal />} />
                              {/* <Route path="/licences" element={<Licences />} /> */}
                              <Route
                                path="/emailverify"
                                element={<EmailVerifySuccess />}
                              />
                              {/* <Route path="/packages" element={<Packages />} /> */}
                              <Route
                                path="/supportcheck"
                                element={<SupportCheck />}
                              />
                              <Route
                                path="/candidates"
                                element={<Candidates />}
                              />
                            </Routes>
                          </I18nextProvider>
                        </LanguageProvider>
                      </BrowserRouter>
                    </PriceProvider>
                  </UserProvider>
                </AnimationProvider>
              </ModalProvider>
            </SettingModalProvider>
          </DirectionProvider>
        </NewApiProvider>
      </ProviderContext.Provider>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-accent-color": "#d71159",
          "--w3m-background-color": "#d71159",
          "--w3m-z-index": "100101",
          "--w3m-button-border-radius": "24px",
        }}
      />
    </WagmiConfig>
  );
}
