import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ErrorAnimation from "../components/ErrorAnimation";
import Navbar from "../components/Navbar";
import Buttons from "../components/Buttons";
import { StyledLink } from "../components/styled";
import { SuccessAnimation } from "components/SuccessAnimation";
import $ from "jquery";
import config from "../config";

const NotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #1d1f25;
  @media (max-width: 768px) {
    padding: 50px 0px;
  }
`;

const NotifMessage = styled.div`
  font-weight: 700;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  color: #fff;
  width: 60%;
  text-align: center;
  div:first-child {
    font-weight: 900;
  }
  @media (max-width: 768px) {
    width: 300px;
  }
`;
export default function EmailVerifySuccess() {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  function getTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("token");
  }

  const token = getTokenFromUrl();

  useEffect(() => {
    $.ajax({
      type: "get",
      url: config.EMAIL_VERIFY,
      data: {
        token: token,
      },
      success: function (response) {
        const resp = JSON.parse(response);
        console.log(resp.status)
        if (resp.status === "error") {
          setIsError(true);
        } else {
          setIsError(false);
        }
      },
    });
  }, []);

  return (
    <>
      <Navbar />
      <NotificationWrapper>
        {isError ? <ErrorAnimation /> : <SuccessAnimation />}
        <NotifMessage>
          {isError ? t("emailfail") : t("emailsuccess")}
        </NotifMessage>
        <StyledLink to="/dashboard">
          <Buttons text={t("gotodashboard")} />
        </StyledLink>
      </NotificationWrapper>
    </>
  );
}
