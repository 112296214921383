import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../../../langcheck";
import CertificateMaker from "CertificateMaker";
import { PdfButton } from "components/styled";
import { Flex } from "components/Layouts";
import { NewApiContext } from "context/newapi";

const CertificateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 700px;
  height: 600px;
  overflow-y: auto;
  place-items: top;
  padding: 0px 12px 12px 0px;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {
    padding: 12px 0px 12px 6px;
    max-height: 600px;
    height: 250px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #34384c;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
`;
const CertificateItem = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding-left: 8px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 4px 0px 4px 8px;
    transition: 0.2s;
  }
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  background: #1d1f25;
  border: 2px solid #34384c;
  padding: 6px 6px 6px 12px;
  position: relative;
  top: 0px;
  max-width: 220px;
  margin: 12px 0px;
  input {
    border: none;
    background-color: transparent;
    width: 80%;
    color: #454752;
  }
  input:focus {
    outline: none;
  }
  @media (max-width: 1024px) {
    margin: 42px 12px 0px 12px;
    max-width: 100%;
    padding: 6px 12px 6px 12px;
    input {
      width: 100%;
    }
  }
`;

const StyledModal = styled(Modal)`
  width: auto !important;
  height: auto !important;

  .ant-modal-content {
    padding: 0px !important;
  }

  .ant-modal-body {
    padding: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #34384c !important;
    border-radius: 8px !important;
  }

  @media (max-width: 1024px) {
    .ant-modal-content {
      padding: 0px !important;
    }

    .ant-modal-body {
      width: 90vw;
      padding: 0px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: .8fr 3fr;

 
    gap: 0px 0px;
    grid-template-rows: 1fr;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
      
      `;

export default function CertificateModal() {
  const [modal2Open, setModal2Open] = useState(false);

  const { t } = useTranslation();
  const direction = LanguageCheck() === "ar" ? "ltr" : "rtl";
  const Language = LanguageCheck();
  const placeself = LanguageCheck() === "ar" ? "flex-end" : "flex-start";
  const [isImage, setIsImage] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState({
    id: "",
    shares: "",
    isgold: false,
  });
  const { certificates } = useContext(NewApiContext);

  useEffect(() => {
    setSelectedCertificate({
      id: certificates[0]?.id,
      shares: certificates[0]?.shares,
      isgold: certificates[0]?.isgold,
    });
  }, [certificates]);

  const [searchText, setSearchText] = useState("");
  const handleIdClick = (id, shares, isgold) => {
    setSelectedCertificate({ id: id, shares: shares, isgold: isgold });
    setIsImage(false);
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  let filteredCertificates;

  filteredCertificates = certificates?.filter((certificate) =>
    certificate.id.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <PdfButton
        direction={direction}
        align={placeself}
        underline
        type="primary"
        onClick={() => setModal2Open(true)}
      >
        {t("downloadcertificate")}
        <img src="/assets/icons/download.svg" alt="pdf-download" />
      </PdfButton>
      <StyledModal
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <Flex column>
          <InputWrapper>
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
            />
            <img src="/assets/icons/search.svg" alt="search" />
          </InputWrapper>
          <Grid>
            <CertificateList>
              {filteredCertificates?.map((certificate, index) => (
                <CertificateItem
                  key={index}
                  onClick={() =>
                    handleIdClick(
                      certificate.id,
                      certificate.shares,
                      certificate.isgold
                    )
                  }
                >
                  {certificate.id}
                </CertificateItem>
              ))}
            </CertificateList>
            <CertificateMaker
              selectedCertificate={selectedCertificate}
              setIsImage={setIsImage}
              isImage={isImage}
            />
          </Grid>
        </Flex>
      </StyledModal>
    </>
  );
}
