import React, { useContext } from "react";
import styled from "styled-components";
import { Flex } from "components/Layouts";
import { SButton } from "components/styled";
import { useTranslation } from "react-i18next";
import { ModalContext } from "context/modals";
const Container = styled(Flex)`
  background: url("assets/kidsbanner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  aspect-ratio: 36/6;
  button {
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 4px 24px;
  }
  @media (max-width: 800px) {
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 16/9;
    height: 130px;
    background-size: cover;
    button {
      font-size: 13px;
      margin-right: 10px;
    }
  }
  @media (max-width: 570px) {
    background: url("assets/kidsbannermobile.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: inherit;
    height: 160px;
    button {
      font-size: 13px;
      margin-right: 10px;
    }
  }

  @media (max-width: 400px) {
    button {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
`;
export default function KidsBanner() {
  const { t } = useTranslation();
  function handleClick() {
    window.location.href = "/packages?openKidBundle=true";
  }

  return (
    <a href="/packages?openKidBundle=true">
      <Container>
        <SButton onClick={handleClick}> {t("investnow")} </SButton>
      </Container>
    </a>
  );
}