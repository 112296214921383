import React from 'react'
import styled, { keyframes } from 'styled-components'

const outer = keyframes`
0% {transform: scale(0); opacity: 0;}
100% {transform: scale(1); opacity: 1;}

`
const inner = keyframes`

30% {transform: scale(0); opacity: 0;}
100% {transform: scale(1); opacity: 1;}

`

const OuterCircle = styled.div`
display:flex;
justify-content:center;
align-items:center;
width: 200px;
height: 200px;
background: #FA2256;

border-radius: 50%;
animation: ${outer} 1s ease-in-out ;


`
const InnerCircle = styled.div`

width: 160px;
height: 160px;
border-radius: 50%;
background: #e1e5e6 ;

animation: ${inner} 1s ease-in-out ;




`

const check = keyframes`
70% { stroke-dasharray: 0 100; }
100% { stroke-dasharray: 100 0; }
`;




const TickSvg = styled.svg`
animation: ${check} 1s ease-in-out ;
stroke: #FA2256;
fill: none;
stroke-linecap: round;
stroke-linejoin: round;
width: 99%;
height:99%;
stroke-width: 10px;
`


export default function ErrorAnimation() {
    return (
        <OuterCircle>
            <InnerCircle>
                <TickSvg viewBox="0 0 100 100">
                    <path id="path1" d="M 10 10 L 90 90" />
                    <path id="path2" d="M 90 10 L 10 90" />
                </TickSvg>
            </InnerCircle>
        </OuterCircle>
    )
}
