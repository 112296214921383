import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { HamburgerMenu } from "./HamburgerMenu";
import WindowSize from "../WindowSizer";
import DropdownMenu from "./DesktopLang";
import { BrownButton, SButton, StyledLink } from "./styled";
import Support from "./dashboard/modals/SupportButton";
import Signout from "./dashboard/Signout";
import { DirectionContext } from "context/direction";
import { Flex } from "./Layouts";
import { LanguageContext } from "context/language";

const Logotext = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 1760px) {
    gap: 16px;
    width: 100%;
    justify-content: initial;
  }
  @media (max-width: 1650px) {
    gap: 14px;
    width: 100%;
    justify-content: initial;
  }

  @media (max-width: 1555px) {
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
  }
  @media (max-width: 1440px) {
    justify-content: space-between;
  }
`;

const Nav = styled.nav`
  height: 80px;
  background: #141518;
  display: flex;
  top: 0px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  z-index: 100;
  direction: ${({ direction }) => direction};
`;

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  color: #fff;
  width: 100%;
  padding: 0px 4rem;
  @media (max-width: 1555px) {
    padding: 0px 2rem;
  }
  @media (max-width: 1024px) {
    padding: 0px 1.4rem;
  }
  a {
    text-decoration: none !important;
    color: #fff;
  }
`;
const Img = styled.img`
  position: absolute;
  bottom: 0;
  width: unset !important;
  height: 22px !important;
  padding: 6px !important;
  margin-left: 10px;
`;
const StyledBundle = styled(StyledLink)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export default function Navbar() {
  // const isMobile = WindowSize()
  const { t } = useTranslation();
  const [route, setRoute] = useState();
  const [buttonName, setButtonName] = useState("Dashboard");
  const [carrouselRoute, setCarrouselRoute] = useState();
  const [progRoute, setProgRoute] = useState();
  const [cardRoute, setCardRoute] = useState();
  const { direction, isMobile } = useContext(DirectionContext);
  const isLogged = localStorage.getItem("token") !== null ? true : false;
  const { language } = useContext(LanguageContext);
  const { align } = useContext(DirectionContext);
  useEffect(() => {
    if (isLogged !== false) {
      setRoute(`/dashboard`);
      setButtonName(t("dashboard"));
    } else {
      setRoute("/login");
      setButtonName(t("nButton"));
    }
  }, []);
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("paymentsuccess")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progresbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("paymentfailed")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("referral")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("login")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("register")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("licences")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("packages")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("supportcheck")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("bubbles")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else if (currentUrl.includes("candidates")) {
      setCarrouselRoute("/#carrousel");
      setProgRoute("/#progressbar");
      setCardRoute("/#cards");
    } else {
      setCarrouselRoute("#carrousel");
      setProgRoute("#progressbar");
      setCardRoute("#cards");
    }
  }, []);
  const [stylesLoaded, setStylesLoaded] = useState(false);

  useEffect(() => {
    // check if styled-components have loaded
    if (document.querySelectorAll('style[data-styled="active"]').length > 0) {
      setStylesLoaded(true);
    }
  }, []);

  const [inBubbles, setInBubbles] = useState(true);
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("bubbles")) {
      setInBubbles(true);
      console.log(inBubbles);
    } else {
      setInBubbles(false);
    }
  }, []);

  const [isMobilee, setIsMobilee] = useState(true);
  const [isDesktop, setIsDesktop] = useState();
  useEffect(() => {
    if (window.innerWidth <= 1420) {
      setIsMobilee(true);
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
      setIsMobilee(false);
    }
  }, []);

  useEffect(() => {
    const HandleResize = () => {
      if (window.innerWidth > 1420) {
        setIsDesktop(true);
        setIsMobilee(false);
      } else {
        setIsMobilee(true);
        setIsDesktop(false);
      }
    };
    window.addEventListener("resize", HandleResize);

    return () => {
      window.removeEventListener("resize", HandleResize);
    };
  }, []);
  return (
    <>
      <Nav direction={direction}>
        <Items>
          {!isMobilee ? (
            <>
              <Logotext
                alignitems={align}
                onClick={() => window.scrollTo(0, 0)}
              >
                {!inBubbles ? (
                  <>
                    <StyledLink to="/">
                      <img src="/assets/rightlogo.svg" alt="logo" />
                    </StyledLink>
                    <StyledLink to={"/"}>{t("home")}</StyledLink>

                    <a href={progRoute}>{t("nav3")}</a>

                    <a href={carrouselRoute}>{t("nav2")}</a>
                    {isLogged && (
                      <StyledLink to="/referral">{t("nav4")}</StyledLink>
                    )}
                    {/* <StyledLink to="/licences">{t("licences")}</StyledLink> */}
                    <StyledLink to="/supportcheck">
                      
                      {t("teamidentifier")}
                    </StyledLink>

                    {/* <StyledBundle to="/packages">
                      <div>{t("navbundle")}</div>
                      <Img src="/assets/packages/goldenmark.svg" />
                    </StyledBundle> */}

                    <StyledLink to="/bubbles">{t("kidszone")}</StyledLink>
                    <StyledLink to="/candidates">{t("election")}</StyledLink>
                    {/* <a href="https://www.gcbex.com">{t("ourPlatform")}</a> */}

                  </>
                ) : (
                  <>
                    <a href="/">
                      <img src="/assets/rightlogo.svg" alt="logo" />
                    </a>
                    <a href={"/"}>{t("home")}</a>

                    <a href={progRoute}>{t("nav3")}</a>

                    <a href={carrouselRoute}>{t("nav2")}</a>
                    {isLogged && <a href="/referral">{t("nav4")}</a>}
                    {/* <a href="/licences">{t("licences")}</a> */}
                    <a href="/supportcheck">{t("teamidentifier")}</a>
                    
                    {/* <a href="/packages">
                      <div>{t("navbundle")}</div>
                      <Img src="/assets/packages/goldenmark.svg" />
                    </a> */}

                    <StyledLink to="/bubbles">{t("kidszone")}</StyledLink>
                    <a href="/candidates">{t("election")}</a>
                    
                    
                  </>
                )}
              </Logotext>

              <Flex alignItem="center" gap="12px">
              <a href={"https://www.gcbex.com/"} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <BrownButton padding="4px 10px"> 
                    
                   <span>{t("ourPlatform")}</span>
                  
                  
                  <img  className="button-logo" alt="Logo"
                  style={{ height: 40, marginTop: 8 }}
                  src="/assets/simple-logo.svg"/> 
                  
                  </BrownButton>
                  </a>
                {!isLogged && (
                  <StyledLink to={"/register"}>
                    <SButton padding="4px 20px"> {t("register")}</SButton>
                  </StyledLink>
                )}
             

                {!inBubbles ? (
                  <StyledLink to={route}>
                    <SButton padding="4px 20px" transparent>
                      {buttonName}
                    </SButton>
                  </StyledLink>
                ) : (
                  <a href={route}>
                    <SButton padding="4px 20px" transparent>
                      {buttonName}
                    </SButton>
                  </a>
                )}

                <DropdownMenu />

                {isLogged && <Signout />}
              </Flex>
            </>
          ) : inBubbles ? (
            <Logotext>
              <a href="/">
                <img
                  style={{ height: 40, marginTop: 8 }}
                  src="/assets/rightlogo.svg"
                  alt="logo"
                />
              </a>
              <HamburgerMenu />
            </Logotext>
          ) : (
            <Logotext>
              <StyledLink to="/">
                <img
                  style={{ height: 40, marginTop: 8 }}
                  src="/assets/rightlogo.svg"
                  alt="logo"
                />
              </StyledLink>
              <HamburgerMenu />
            </Logotext>
          )}
        </Items>
      </Nav>
    </>
  );
}
