import React from "react";
import styled from "styled-components";
const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: ${(props) =>
    props.transparent === true ? "transparent" : "#D71159"};
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding === true ? "10px 60px" : "4px 54px")};
  font-weight: 800;
  width: 100%;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    padding: 12px 42px;
    height: 50px;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
  }
`;
export default function Buttons({ transparent, text, padding }) {
  return (
    <StyledButton transparent={transparent} padding={padding}>
      {text}
    </StyledButton>
  );
}
