import React , { useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import Carrousel1 from "../components/Carrousel1";
import "./slider-dots.css";
import WindowSize from "../WindowSizer";
import { useTranslation } from "react-i18next";
import ColoredCarrousel from "../components/ColoredCarrousel";
import $ from "jquery";
import config from "../config";

const CarrouselWrapper = styled.div`
  max-width: 100%;

  .slick-prev:before {
    display: none !important;
  }
  .slick-next:before {
    display: none !important;
  }
`;
const Sect = styled.section`
  position: relative;
  top: -170px;
  @media (max-width: 1024px) {
    top: 0px;
  }
`;
const PrevNextButton = styled.button`
background-image: url(${props => props.img }) !important;
background-repeat: no-repeat !important;
background-size: 100% 100% !important;
`;
export default function Carrousel() {
  const isMobilee = WindowSize();
  const { t } = useTranslation();



  const cards = [
    // { title: t('carrousel1-t'), name: '/assets/carrousel/carrousel1.png', text: t('carrousel1') },
    {
      title: t("carrousel2-t"),
      name: "/assets/carrousel/carrousel2.png",
      text: t("carrousel2"),
    },
    {
      title: t("carrousel3-t"),
      name: "/assets/carrousel/carrousel3.png",
      text: t("carrousel3"),
    },
    {
      title: t("carrousel4-t"),
      name: "/assets/carrousel/carrousel4.png",
      text: t("carrousel4"),
    },
    {
      title: t("carrousel5-t"),
      name: "/assets/carrousel/carrousel5.png",
      text: t("carrousel5"),
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevNextButton img="/assets/PrevArrow.svg" />,
    nextArrow: <PrevNextButton img="/assets/NextArrow.svg" />,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToScroll: 1,
          initialSlide: 0,
          autoplay: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          initialSlide: 0,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
        },
      },
    ],
  };
  let done = false;
  useEffect(() => {
    var settings = {
      "url": config.SECTION_VIEWS,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "section": "Slider"
      }
    };
    if (done === false) {
      $.ajax(settings).done(function (response) {
      });
    }
    done = true;
  }, [])
  return !isMobilee ? (
    <CarrouselWrapper>
      <Sect id="carrousel" />
      <Slider {...settings}>
        <ColoredCarrousel />

        {cards.map((card, index) => {
          return (
            <Carrousel1
              image={card.name}
              text={card.text}
              title={card.title}
              key={index}
            />
          );
        })}
      </Slider>
    </CarrouselWrapper>
  ) : (
    <>
      <Sect id="carrousel" />
      <Slider {...settings}>
        <ColoredCarrousel />
        {cards.map((card, index) => {
          return (
            <Carrousel1
              image={card.name}
              text={card.text}
              title={card.title}
              key={index}
            />
          );
        })}
      </Slider>
    </>
  );
}
