import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ErrorAnimation from "../components/ErrorAnimation";
import Navbar from "../components/Navbar";
import { SuccessAnimation } from "../components/SuccessAnimation";
import Buttons from "../components/Buttons";
import { StyledLink } from "../components/styled";

const NotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #1d1f25;
`;

const NotifMessage = styled.div`
  font-weight: 700;
  font-size: 25px;
  color: #fff;
  text-align: center;

  div:first-child {
    span {
      font-weight: 500;
    }
  }

  @media (max-width: 768px) {
    width: 300px;
  }
`;

export default function PaymentSuccess() {
  const { t } = useTranslation();

  const Language = localStorage.getItem("selectedLanguage");

  return (
    <>
      <Navbar />
      <NotificationWrapper>
        <SuccessAnimation />
        <NotifMessage>
          {Language === "eng" ? (
            <div>
              Payment Success:
              <br /> <span> Your transaction has been processed</span>
            </div>
          ) : (
            "تمت معالجة المعاملة الخاصة بك"
          )}

          {/* {t('paymentsuccess')} */}
        </NotifMessage>
        <StyledLink to="/dashboard">
          <Buttons text={t("gotodashboard")} />
        </StyledLink>
      </NotificationWrapper>
    </>
  );
}
