import React, { createContext, useState, useEffect } from "react";

export const AnimationContext = createContext();

export const AnimationProvider = (props) => {
  const [phase, setPhase] = useState("pay");

  const value = { phase, setPhase };

  return (
    <AnimationContext.Provider value={value}>
      {props.children}
    </AnimationContext.Provider>
  );
};
