import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import LanguageCheck from '../../langcheck';

const breatheAnimation = keyframes`
0% { opacity: 0;  }
99% { opacity: 1;  }
`
const Wrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
width: 100%;
gap:0px 16px;
animation-name: ${breatheAnimation};
animation-duration: 2s;
margin-bottom:36px;


`
const Title = styled.div`
grid-column: 1/ span 2;
font-weight: 700;
font-size: 18px;
/* identical to box height, or 29px */
line-height: 29px;
text-align: ${props => props.direction};

color: #FFFFFF;

`
const Error = styled.div`
color: ${props => props.about || "#D91B60;"} 
font-size: 14px;
position: relative;
botttom:0;
font-weight:600;
line-height: 26px;
`

const Item = styled.div`
display: flex;
flex-direction: column;
gap:4px;
text-align: ${props => props.Align} !important;
label {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
}


select{
    background: #1D1F25;
    border: 2px solid #34384C;
    border-radius: 8px;
    height: 52px;
    color:#5D6588;
    width:100%;
    font-size: 16px;
    padding: 0 10px;
    :focus{
        outline: none;
        }
       
    @media (max-width: 1024px) {
    }
}

`


export default function DatePicker({ setTimer, setDater, timeError, dayError }) {
    const { t } = useTranslation();
    const Language = LanguageCheck();
    const [align, setAlign] = useState("")
    useEffect(() => {

        Language === "ar" ? setAlign("right") : setAlign("left")

    }, [Language])
    const direction = LanguageCheck() === 'ar' ? 'right' : 'left';

    const timePick = (e) => {
        setTimer(e.target.value)
    };

    const datePick = (e) => {
        setDater(e.target.value)
    };


    // var today = new Date();

    // Set the number of days to show in the date picker
    var numDays = 7;

    // Loop through the number of days
    var today = new Date()

    useEffect(() => {

        for (var i = 0; i < numDays; i++) {
            // Check if the current day is a weekend day (Saturday or Sunday)
            if (today.getDay() === 0 || today.getDay() === 6) {
                // If it is a weekend day, skip it and continue to the next iteration
                today.setDate(today.getDate() + 1);
                continue;
            }

            // Create a new option element
            var option = document.createElement("option");

            // Set the value and text of the option to the current date, formatted as dd/mm/yy
            option.value = today.getDate().toString().padStart(2, '0') + '/' + (today.getMonth() + 1).toString().padStart(2, '0') + '/' + today.getFullYear().toString().substr(-2);
            option.text = today.getDate().toString().padStart(2, '0') + '/' + (today.getMonth() + 1).toString().padStart(2, '0') + '/' + today.getFullYear().toString().substr(-2);

            // Add the option to the select element
            document.getElementById("date").add(option);

            // Increment the date by one day
            today.setDate(today.getDate() + 1);
        }


    }, [])



    return (


        <Wrapper>
            <Title direction={direction}>
                {t('bookmeet')}
            </Title>

            <Item Align={align}>
                <label>{t('day')}</label>
                <select id="date" name="day" onChange={(e) => datePick(e)} />
                {dayError && <Error >{dayError}</Error>}
            </Item>
            <Item Align={align}>

                <label>{t('hour')}</label>
                <select id="time" name="time" onChange={(e) => timePick(e)}>
                    <option value="">{t('bookhour')}  </option>
                    <option value="12:00">12:00</option>
                    <option value="12:30">12:30</option>
                    <option value="13:00">13:00</option>
                    <option value="13:30">13:30</option>
                    <option value="14:00">14:00</option>
                    <option value="14:30">14:30</option>
                    <option value="15:00">15:00</option>
                    <option value="15:30">15:30</option>
                    <option value="16:00">16:00</option>
                    <option value="16:30">16:30</option>
                    <option value="17:00">17:00</option>
                    <option value="17:30">17:30</option>
                    <option value="18:00">18:00</option>
                </select>
                {timeError && <Error >{timeError}</Error>}

                <Error style={{ color: "#5D6588" }} > {t("timezone")} </Error>
            </Item>
        </Wrapper>

    )
}
