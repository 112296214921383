import Navbar from "components/Navbar";
import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";
import CircleProfile from "components/vote/UserCards";
import $ from "jquery";
import config from "../config";
import CandidateProfile from "components/vote/CandidateProfile";
import UserCards from "components/vote/UserCards";
import FormModal from "components/vote/FormModal";
import Votepopup from "components/Votepopup";
import { NewApiContext } from "context/newapi";
import Nosharepopup from "components/Noshareepopup";
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  color: #000;
  background-image: url("assets/dashbg.png");
  background-size: cover;
  background-repeat: no-repeat;

  color: #fff;
  min-height: 100vh;
  margin-top: 80px;
  padding: 60px 60px;
  h1 {
    margin: 0;
  }

  @media (max-width: 600px) {
    padding: 0px;
    gap: 30px;
    height: max-content;
    background-position: top;
    background-size: 100% 100%;
  }
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: auto minmax(300px, 600px) minmax(300px, 600px) auto;
  gap: 24px;

  @media (max-width: 770px) {
    grid-template-columns: 1fr;
    place-content: center;
    justify-items: center;
    padding: 15px 30px;
  }
`;

export default function Candidates() {
  const [showInfo, setShowInfo] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);
  const [hasSharePopup, setHasSharePopup] = useState(false);
  const apiObj = useContext(NewApiContext);
  const userShare = apiObj.sharesWithoutKids;

  // false ise mesajı göstericez
  // share sayısı 125k dan azsa hasshare = false
  // share sayısı 125k dan fazlaysa hiç bir türlü göstermiycez
  // sayfaya ilk girdiğinde her türlü göster
  // eğer logged in değilse forma tıklkadığında logine yönlendir

  useEffect(() => {
    if (userShare >= 125000) {
      setHasSharePopup(true);
    }

    //eğer share sayısı 125k dan azsa ve daha önce popup gösterilmediyse göster
    if ((userShare <= 125000) & !sessionStorage.getItem("popup")) {
      sessionStorage.setItem("hasShownPopup", true);
      setHasSharePopup(false);
    }
  }, [userShare]);

  const isLogged = localStorage.getItem("token") !== null ? true : false;



  const Fll = styled.div`
display:flex;
justify-content:space-be
`

  return (
    <>
      <Navbar />
      <PageWrapper>
        <Flex>
          <div></div>
          <Date setHasSharePopup={setHasSharePopup} />

          <div
            style={{ placeSelf: "flex-end" ,fontSize:"22px" , }}
          >مجلس هيئة العموم</div>
        </Flex>

        {/* <Votepopup /> */}

        {/* {!isLogged ? <Nosharepopup /> : null} */}

        <UserCards
          showAll={showAll}
          setShowAll={setShowAll}
          showInfo={showInfo}
          setShowInfo={setShowInfo}
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
          searchFilter={searchFilter}
        />

        {!showAll & (selectedProfile !== null) ? (
          <CandidateProfile selectedProfile={selectedProfile} />
        ) : null}
      </PageWrapper>
    </>
  );
}

const DateFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  color: #fa2256;
  width: max-content;
  max-width: max-content;
  align-items: flex-start;
  gap: 6px;
  font-size: 18px;
  line-height: 1;

  button {
    margin-top: 6px;
  }
  div:first-child {
    line-height: 1;
    color: #fff;
    font-size: 94px;
  }
  img {
    max-width: 220px;
  }
  @media (max-width: 1300px) {
    img {
      max-width: 260px;
    }
  }
`;

function Date({ setHasSharePopup }) {
  const isVote = localStorage.getItem("shouldVote");
  return (
    <DateFlex column>
      <img src="/assets/elections.png" />
      {/* {isVote === "false" ? (
        <FormModal setHasSharePopup={setHasSharePopup} />
      ) : null} */}
    </DateFlex>
  );
}
const Effect = styled.div`
  position: absolute;
  background-image: url("/assets/effect.svg");
  background-repeat: no-repeat;
  overflow: hidden !important;
  width: 50vw;
  right: 0px;
  height: 100vw;
  z-index: -1;
`;
const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1d1f25;
  border: 1px solid #34384c;
  border-radius: 8px;
  padding: 8px 14px;
  box-sizing: border-box;

  input {
    background-color: transparent;
    border: none;
    color: #5d6588;
    font-weight: 700;s
    font-size: 14px;
    outline: none;
    text-align: right;
    ::placeholder {
      color: #5d6588;
    }
  }
  

  img {
    width:20px;
  }
  @media (max-width: 770px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

const SearcFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  font-size: 30px;
  direction: rtl;
  place-items: flex-start;
  text-align: right;
  width: 100%;
  @media (max-width: 770px) {
    place-items: center;
    gap: 12px;
    div:first-child {
      place-self: flex-start;
    }
  }
`;

function SearchContainer({ searchFilter, setSearchFilter }) {
  const { t } = useTranslation();
  const { align } = useContext(DirectionContext);

  const handleChange = (event) => {
    setSearchFilter(event.target.value);
  };

  return (
    <SearcFlex align={align} column>
      <div> sss </div>
      {/* <Search>
        <input
          placeholder="إبحث"
          value={searchFilter}
          onChange={handleChange}
        />
        <img src="assets/icons/search.svg" alt="searchbar" />
      </Search> */}
    </SearcFlex>
  );
}
