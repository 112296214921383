import React, { useContext, useState, useEffect } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import $ from "jquery";
import TableSkeleton from "./TableSkeleton";
import Share from "./Share";
import config from "../config";
import AvatarMaker from "./Avatarmaker";
import { formatNumber } from "../FormatNumber";
import { DirectionContext } from "context/direction";
const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;

  color: #ffffff;
  gap: 36px;
  direction: ${(props) => props.direction};
  .ant-pagination {
    direction: ltr !important;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    margin: 0px;
  }
  @media (max-width: 1024px) {
    gap: 20px;
    h1 {
      font-size: 28px;
    }
  }
  .ant-table-wrapper table {
    text-align: center !important ;
    div {
      justify-content: center !important;
    }
  }
  .ant-table-thead > tr > th {
    text-align: center !important;
  }
`;
export default function Users() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { direction } = useContext(DirectionContext);
  const [topUsers, setTopUsers] = useState([{}]);

  useEffect(() => {
    $.ajax({
      type: "POST",
      url: config.TABLE,
      data: {
        action: "user_fetch",
        page: 1,
        length: 10000,
        search: "",
        token: localStorage.getItem("token"),
      },
      success: function (response) {
        let resp = JSON.parse(response);
        setTopUsers(
          resp.map((user, index) => {
            return {
              name: user.name,
              lastname: user.lastname,
              shares: user.shares,
              id: user.id,
              imgVersion: user.imgVersion,
            };
          })
        );
        setIsLoading(false);
      },
      error: function (error) {
        console.error("Error:", error);
      },
    });
  }, []);

  const columnsEng = [
    {
      title: " ",
      dataIndex: "image",
      key: "image",
    },
    {
      title: t("table1"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("table4"),
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: t("table3"),
      dataIndex: "shares",
      key: "shares",
    },
  ];

  const BackgroundColors = [
    "url('/assets/badges/gold.svg')",
    "url('/assets/badges/silver.svg')",
    "url('/assets/badges/bronze.svg')",
    "url('/assets/badges/iron.svg')",
  ];

  const data = [
    topUsers.slice().map((user, index) => {
      let badge;
      if (index < 10) {
        badge = BackgroundColors[0];
      } else if (index >= 10 && index < 30) {
        badge = BackgroundColors[1];
      } else if (index >= 30 && index < 50) {
        badge = BackgroundColors[2];
      } else if (index >= 10) {
        badge = BackgroundColors[3];
      }

      return {
        key: index,
        name: user.name + " " + user.lastname,
        shares: <Share amount={formatNumber(user.shares)} />,
        rank: index + 1,
        image: <AvatarMaker name={user.name} background={badge} id={user.id} imgVersion={user.imgVersion} />,
      };
    }),
  ];
  let done = false;
  useEffect(() => {
    var settings = {
      url: config.SECTION_VIEWS,
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        section: "Leaderboard",
      },
    };
    if (done === false) {
      $.ajax(settings).done(function (response) {});
    }
    done = true;
  }, []);
  return (
    <TableWrapper direction={direction}>
      <h1>{t("leaderboard")} </h1>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          pagination={{ pageSize: 5 }}
          columns={columnsEng}
          dataSource={data[0]}
        />
      )}
    </TableWrapper>
  );
}
