import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Langswitch from "./Langswitch";
import { Link } from "react-router-dom";
import Signout from "./dashboard/Signout";
import { StyledLink } from "./styled";
import Support from "./dashboard/modals/SupportButton";
import LanguageCheck from "langcheck";
const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: #1e1f25;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  text-align: left;
  position: fixed;
  padding: 2rem 0rem;
  align-items: center;
  width: 30%;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 102;
  span {
    position: relative;
    bottom: -24px;
  }

  a {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.2rem;
    max-height: 30px;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: #d71159;
    }
  }

  @media (max-width: 576px) {
    width: 100%;
    max-width: 100vw;
  }
  @media (min-width: 577px) and (max-width: 1300px) {
    width: 50%;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    
    a {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.2rem;
      color: #fff;
      text-decoration: none;
      transition: color 0.3s linear;

      &:hover {
        color: #d71159;
      }
    }
  }
`;

const StyledA = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  font-size: 20px !important;
  letter-spacing: 0px !important;
  img {
    max-width: 30px !important;
    width: 30px !important;
    height: 30px !important;

    max-height: 30px !important;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  border: none;
  background-color: transparent;
  color: #fff;
  font-weight: 800;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
  img {
    max-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
    margin-bottom: 6px;s
    max-height: 30px !important;
  }
  font-weight: 800;
  font-size: 20px;
  margin: 0 !important;
  padding: 0 !important;
`;

const ButtonBox = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  button {
    width: 100%;
  }
`;
const ItemSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  gap: 8px;
`;
const ButtonSection = styled(ItemSection)`
  align-items: center;
  margin: 0 auto;
`;
const Divider = styled.div`
  height: 1px;
  margin: 34px 0px 46px 0px;
  background-color: #34384c;
  width: 100%;
`;
const Menu = ({ open, setOpen }) => {
  const [route, setRoute] = useState();
  const [buttonName, setButtonName] = useState();
  const { t } = useTranslation();
  const [isLoged, setIsLoged] = useState();

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      setIsLoged(false);
    } else {
      setIsLoged(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setRoute("/dashboard");
      setButtonName(t("dashboard"));
    } else {
      setRoute("/login");
      setButtonName(t("nButton"));
    }
  }, []);

  function CloseMenu() {
    if (window.location.pathname === "/dashboard") {
      setOpen(!open);
    }
    if (window.location.pathname === "/login") {
      setOpen(!open);
    }
  }
  const [inBubbles, setInBubbles] = useState(true);
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("bubbles")) {
      setInBubbles(true);
      console.log(inBubbles);
    } else {
      setInBubbles(false);
    }
  }, []);
  return (
    <StyledMenu open={open}>
      <ItemSection>
        <StyledA onClick={() => setOpen(!open)} href="/#cards">
          <img alt="icon" src="/assets/icons/mobilemenu/nav1.svg" />
          {t("nav1")}
        </StyledA>
        <StyledA onClick={() => setOpen(!open)} href="/#carrousel">
          <img alt="icon" src="/assets/icons/mobilemenu/nav2.svg" />
          {t("nav2")}
        </StyledA>
        <StyledA onClick={() => setOpen(!open)} href="/#progressbar">
          <img alt="icon" src="/assets/icons/mobilemenu/nav3.svg" />
          {t("nav3")}
        </StyledA>

        <StyledA onClick={() => setOpen(!open)} href="/referral">
          <img alt="icon" src="/assets/icons/mobilemenu/nav4.svg" />
          {t("nav4")}
        </StyledA>

        {/* <StyledA onClick={() => setOpen(!open)} href="/licences">
          <img alt="icon" src="/assets/icons/mobilemenu/nav5.svg" />
          {t("licences")}
        </StyledA> */}
      
        <StyledA href={"/supportcheck"}>
          <StyledButton onClick={CloseMenu}>
            <img alt="icon" src="/assets/icons/mobilemenu/teamidentity.svg" />
            <div> {t("teamidentifier")}</div>
          </StyledButton>
        </StyledA>
        <StyledA href={"/candidates"}>
          <StyledButton onClick={CloseMenu}>
            <img  alt="icon" src="/assets/icons/mobilemenu/election.svg" />
            <div> {t("election")}</div>
          </StyledButton>
        </StyledA>
        <StyledA    onClick={() => setOpen(!open)} href="https://www.gcbex.com/">
          <img  style={{ transform: 'scale(0.85)' }} alt="icon" src="/assets/icons/mobilemenu/simple-icon.svg" />
          {t("ourPlatform")}
        </StyledA>

        <>
          {!inBubbles ? (
            <>
              <StyledLink to={"/bubbles"}>
                <StyledButton onClick={CloseMenu}>
                  <img alt="icon" src="/assets/icons/mobilemenu/start.svg" />
                  <div> {t("kidszone")}</div>
                </StyledButton>
              </StyledLink>
              {/* <StyledLink to={"/packages"}>
                <StyledButton onClick={CloseMenu}>
                  <img
                    alt="icon"
                    src="/assets/icons/mobilemenu/navbundle.svg"
                  />
                  <div> {t("navbundle")}</div>
                </StyledButton>
              </StyledLink> */}

              <StyledLink to={route}>
                <StyledButton onClick={CloseMenu}>
                  <img alt="icon" src="/assets/icons/mobilemenu/nav6.svg" />
                  {buttonName}
                </StyledButton>
              </StyledLink>
            </>
          ) : (
            <>
              <a href={"/bubbles"}>
                <StyledButton onClick={CloseMenu}>
                  <img alt="icon" src="/assets/icons/mobilemenu/start.svg" />
                  <div> {t("kidszone")}</div>
                </StyledButton>
              </a>
              {/* <a href={"/packages"}>
                <StyledButton onClick={CloseMenu}>
                  <img
                    alt="icon"
                    src="/assets/icons/mobilemenu/navbundle.svg"
                  />
                  <div> {t("navbundle")}</div>
                </StyledButton>
              </a> */}
              <a href={"/candidates"}>
                <StyledButton onClick={CloseMenu}>
                  <img alt="icon" src="/assets/icons/mobilemenu/election.svg" />
                  <div> {t("election")}</div>
                </StyledButton>
              </a>
              
              <a href={route}>
                <StyledButton onClick={CloseMenu}>
                  <img alt="icon" src="/assets/icons/mobilemenu/nav6.svg" />
                  {buttonName}
                </StyledButton>
              </a>
            </>
          )}
        </>

        {!isLoged && (
          <StyledLink to={"/register"}>
            <StyledButton onClick={CloseMenu}>
              <img alt="icon" src="/assets/icons/mobilemenu/nav7.svg" />
              <div style={{ color: "#D71159", lineHeight: 0 }}>
                {" "}
                {t("register")}
              </div>
            </StyledButton>
          </StyledLink>
        )}
      </ItemSection>
      <Divider />
      <ButtonSection>
        <ButtonBox>
          <Langswitch />

          <Support />
        </ButtonBox>
        {isLoged && (
          <span>
            <Signout />
          </span>
        )}
      </ButtonSection>
    </StyledMenu>
  );
};

const StyledBurger = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#EFFFFA" : "#EFFFFA")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    z-index: 10;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  const Language = LanguageCheck();

  return (
    <StyledBurger lang={Language} open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export const HamburgerMenu = () => {
  const [open, setOpen] = useState(false);
  const node = React.useRef();
  useOnClickOutside(node, () => setOpen(false));
  return (
    <div>
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} id={"hammenu"} />
      </div>
    </div>
  );
};

const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};
