import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'
const breatheAnimation = keyframes`
0% { bottom: 0px; opacity:1; }
50% { bottom: 205px; opacity:1;  }
100% { bottom: 0px; opacity:1; }
`
export default function Notification({ text, image, backgroundColor }) {

    const NotificationWrapper = styled.div`
        display:flex;
        position: absolute;
        opacity: 1;
        height: 86px;
        align-items: center;
        font-weight: 500;
        font-size: 19px;
        width: 350px;
        right:200px;
        border-radius: 32px;
        background-color: ${backgroundColor ? backgroundColor : "#D71159"};
        animation-name: ${breatheAnimation};
        img{
            margin:0px 20px;
            width: 70px;
            height: 60px;
        }
        animation-duration: 8s;
        `
        
    return (
        <NotificationWrapper>
            <img src={image} alt="notification" />
            <p>{text}</p>

        </NotificationWrapper>
    )
}
