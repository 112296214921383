import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const Shares = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #fff;
    gap: 2px;
    direction: ltr !important;
    line-height: 120%;
    font-size: ${props => props.textSize ? props.textSize : "20px"};
    span {
      color: #e48f35;
    }
    @media (max-width: 468px) {
      font-size: 20px;
    }
  `;
export default function Share({ amount, textSize }) {
  const { t } = useTranslation();

  return (
    <Shares textSize={textSize}>
      {t('share')} <span>{amount}</span>{" "}
    </Shares>
  );
}
