import { useEffect, useState } from 'react'

export default function WindowSize() {
  const [isMobilee, setIsMobilee] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1100) {
      setIsMobilee(true);
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
      setIsMobilee(false);
    }
  }, []);
  
  useEffect(() => {
    const HandleResize = () => {
      if (window.innerWidth > 1100) {
        setIsDesktop(true);
        setIsMobilee(false);
      } else {
        setIsMobilee(true);
        setIsDesktop(false);
      }
    };
    window.addEventListener('resize', HandleResize);
  
    return () => {
      window.removeEventListener('resize', HandleResize);
    };
  }, []);
  return isMobilee;
};