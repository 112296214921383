import Navbar from "components/Navbar";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledInput, SButton, RevertedFlex } from "components/styled";
import { DirectionContext } from "context/direction";
import $ from "jquery";
import config from "../config";
import useBubbleRemover from "hooks";

const SupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  gap: 40px;
  background-color: #282c34;
  padding: 0px 33vw;
  align-items: center;
  background-image: url("/assets/dashbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  min-height: calc(100vh - 80px);

  h1 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 0px;
    margin-top: 60px;
    line-height: 1.4;
  }
  margin-top: 80px;
  @media (max-width: 1450px) {
    h1 {
      font-size: 28px;
      line-height: 42px;
    }
    padding: 0px 20vw;
  }
  @media (max-width: 768px) {
    padding: 20px 10vw;

    h1 {
      font-size: 22px;
      line-height: 32px;
    }
  }
`;
const WarnWrapper = styled(SupportWrapper)`
  background: rgba(239, 137, 15, 0.21);
  height: unset;
  border: 1px solid #ffaa06;
  margin-top: unset;
  font-size: 16px;
  font-weight: 500;
  gap: 10px;
  color: #fdd65b;
  min-height: unset;

  padding: 0px;
  p {
    padding: 0px 40px;
    margin: 0px 0px 20px 0px;
  }
  border-radius: 20px;
  img:first-child {
    position: relative;
    align-self: flex-end;
    right: 10px;
    top: 10px;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  gap: 2px;
  width: 100%;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align};
  input {
    width: 100%;
  }
`;
const StatusIcon = styled.img`
  margin-left: 5px;
  position: absolute;
  ${(props) =>
    props.direction === "rtl"
      ? " margin: 40px -26px 0px 0px;"
      : " margin: 40px 0px 0px -26px;"}
`;
const Error = styled.div`
  color: #ff4a4a;
  font-size: 16px;
  height: max-content;
  font-weight: 500;
  text-align: ${(props) => props.align};
  a {
    font-weight: 700;
    text-decoration: underline;
    color: #ff4a4a;
  }
`;
const Succcess = styled(Error)`
  color: #11cabe;
  a {
    color: #11d760;
  }
`;

function ErrorDisplayer() {
  const { rightLeft, direction } = useContext(DirectionContext);

  return direction === "rtl" ? (
    <Error align={rightLeft}>
      عذراً، الرقم الذي أدخلته ليس في قائمتنا. يرجى التأكد من أنك قد أدخلت الرقم
      الصحيح. إذا كانت لديك أي مشاكل، يرجى <a>التواصل</a> مع خدمة العملاء لدينا
      للمساعدة.
    </Error>
  ) : (
    <Error align={rightLeft}>
      Sorry, the number you entered is not in our list. Please make sure you
      have entered the correct number. If you have any problems, please{" "}
      <a>contact</a> our customer service for help.
    </Error>
  );
}
function SuccessDisplayer({ name, telegram, phone }) {
  const { rightLeft, direction } = useContext(DirectionContext);
  console.log(name);
  return (
    <Succcess align={rightLeft}>
      {name} ,{" "}
      {phone ? (
        <a rel="noreferrer" target="_blank" href={phone}>
          {" "}
          {phone}
        </a>
      ) : (
        <a rel="noreferrer" target="_blank" href={telegram}>
          {" "}
          {telegram}
        </a>
      )}
    </Succcess>
  );
}

export default function SupportCheck() {
  const { t } = useTranslation();
  const { align, direction, rightLeft } = useContext(DirectionContext);
  useBubbleRemover(true);

  const [inputValues, setInputValues] = useState({
    phone: "",
    telegram: "",
  });
  const [icons, setIcons] = useState({
    phone: "",
    telegram: "",
  });

  const [errors, setErrors] = useState({
    phone: "",
    telegram: "",
  });

  const [responseValues, setResponseValues] = useState({
    phone: { name: "", whatsapp: "" },
    telegram: { name: "", telegram: "" },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e, field) => {
    e.preventDefault();

    $.ajax({
      url: config.SUPPORT_CHECK,
      type: "POST",
      data: { [field]: inputValues[field], submit: field },
      success: (response) => {
        const parsedResponse = JSON.parse(response);

        setIcons((prevIcons) => ({
          ...prevIcons,
          [field]:
            parsedResponse.status === "not_exists"
              ? "/assets/icons/warning.svg"
              : "/assets/icons/verified.svg",
        }));

        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]:
            parsedResponse.status === "not_exists"
              ? "Error: Value not found"
              : "",
        }));

        if (parsedResponse.status === "exists") {
          setResponseValues((prevValues) => ({
            ...prevValues,
            [field]: {
              name: parsedResponse.name,
              [field === "phone" ? "whatsapp" : field]:
                parsedResponse[field === "phone" ? "whatsapp" : field],
            },
          }));
        } else {
          setResponseValues((prevValues) => ({
            ...prevValues,
            [field]: { name: "", [field === "phone" ? "whatsapp" : field]: "" },
          }));
        }
      },
      error: (error) => {
        console.error("Error:", error);
      },
    });
  };
  return (
    <>
      <Navbar />
      <SupportWrapper>
        <h1>{t("supportchecktitle")}</h1>

        <RevertedFlex flexDirection="column" gap="24px">
          <WarnWrapper>
            <img src="/assets/icons/yellowarn.svg" alt="warning" />
            <img src="/assets/supportcheck.svg" alt="support" />
            <p>{t("supportcheckcontent")}</p>
          </WarnWrapper>

          <InputWrapper>
            <Item align={align}>
              <label>{t("phone")}</label>
              <RevertedFlex direction={direction} gap="20px" width="100%">
                <StyledInput
                  name="phone"
                  value={inputValues.phone}
                  onChange={handleChange}
                  direction={rightLeft}
                  dir={direction === "rtl" ? "ltr" : ""}
                />

                <SButton
                  padding={" 0px 30px"}
                  onClick={(e) => handleSubmit(e, "phone")}
                >
                  {t("check")}
                </SButton>
              </RevertedFlex>
              {icons.phone && (
                <StatusIcon
                  direction={direction}
                  src={icons.phone}
                  alt="status"
                />
              )}
            </Item>
            {errors.phone && <ErrorDisplayer />}
            {responseValues.phone.name !== "" && (
              <SuccessDisplayer
                name={responseValues.phone.name}
                phone={responseValues.phone.whatsapp}
              />
            )}

            <Item align={align}>
              <label>{t("telegram")}</label>
              <RevertedFlex direction={direction} gap="20px" width="100%">
                <StyledInput
                  name="telegram"
                  value={inputValues.telegram}
                  onChange={handleChange}
                  direction={rightLeft}
                  dir={direction === "rtl" ? "ltr" : ""}
                />
                <SButton
                  padding={" 0px 30px"}
                  onClick={(e) => handleSubmit(e, "telegram")}
                >
                  {t("check")}
                </SButton>
              </RevertedFlex>
              {icons.telegram && (
                <StatusIcon
                  direction={direction}
                  src={icons.telegram}
                  alt="status"
                />
              )}
            </Item>
            {errors.telegram && <ErrorDisplayer />}
            {responseValues.telegram.name !== "" && (
              <SuccessDisplayer
                name={responseValues.telegram.name}
                telegram={responseValues.telegram.telegram}
              />
            )}
          </InputWrapper>
        </RevertedFlex>
      </SupportWrapper>
    </>
  );
}
