import CryptoModal from "components/dashboard/modals/CryptoModal";
import React, { useContext, useState } from "react";
import CardPayment from "./CardPayment";
import { Radio } from "antd";
import styled from "styled-components";
import CryptoPayment from "./CryptoPayment";
import { useTranslation } from "react-i18next";
import { AnimationContext } from "context/animation";
import { SuccessAnimation } from "components/SuccessAnimation";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 0rem;
  font-size: 20px;
  color: #fff;
  text-align: center;
  gap: 20px;
  transition: 0.2s;

  h1 {
    font-size: 28px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  margin-top: 80px;

  .ant-radio-button {
    background: #d71159 !important;
    border-color: #d71159 !important;
    border-radius: 24px !important;
  }
  .ant-radio-button-checked {
    background: #d71159 !important;
    border-radius: inherit !important;
  }

  .ant-radio-button-wrapper {
    border-color: #d71159 !important;
    background-color: transparent !important;
    color: #fff !important;
  }
  .ant-radio-button-wrapper:hover {
    color: #fff !important;
  }
  .ant-radio-button:hover {
    color: #fff !important;
  }
  .ant-radio-button-wrapper::before {
    background-color: transparent !important;
  }
`;

export default function PackageModal({
  numberofShare,
  bundleId,
  remaining,
  totalPackage,
  totalPrice,
}) {
  const [paymentMethod, setPaymentMethod] = useState("card");
  const { t } = useTranslation();
  const { phase, setPhase } = useContext(AnimationContext);
  const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return (
    <Wrapper>
      <Radio.Group
        style={{ alignSelf: "center" }}
        defaultValue="b"
        buttonStyle="solid"
      >
        <Radio.Button onClick={() => setPaymentMethod("crypto")} value="a">
          {t("paywithcrypto")}
        </Radio.Button>
        <Radio.Button onClick={() => setPaymentMethod("card")} value="b">
          {t("paywithcard")}
        </Radio.Button>
      </Radio.Group>

      {paymentMethod === "crypto" ? (
        phase === "pay" ? (
          <CryptoPayment
            remaining={remaining}
            totalPackage={totalPackage}
            bundleId={bundleId}
            numberofShare={numberofShare}
            totalPrice={totalPrice}
          />
        ) : phase === "loading" ? (
          <MessageWrapper>
            <Spin indicator={antIcon} />
            <h1>{t("processing")}</h1>
          </MessageWrapper>
        ) : phase === "success" ? (
          <MessageWrapper>
            <SuccessAnimation />
            <h1>{t("paymentsuccess")}</h1>
          </MessageWrapper>
        ) : null
      ) : (
        <CardPayment
          remaining={remaining}
          totalPackage={totalPackage}
          bundleId={bundleId}
          numberofShare={numberofShare}
          totalPrice={totalPrice}
        />
      )}
    </Wrapper>
  );
}
