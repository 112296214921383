import React, { useContext, useEffect, useState, useRef } from "react";
import { erc20ABI } from "../../ABIs/ABI";
import $ from "jquery";
import styled from "styled-components";
import { StyledInput } from "../styled";
import config from "../../config";
import { ethers } from "ethers";
import { ModalContext } from "context/modals";
import { AnimationContext } from "context/animation";
import LanguageCheck from "langcheck";
import { useTranslation } from "react-i18next";
import { useAccount, useSigner } from "wagmi";
import { Web3Button } from "@web3modal/react";
import { Flex } from "components/Layouts";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { NewApiContext } from "context/newapi";
import { PriceContext } from "context/prices";
const Dragzone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 2px dashed #34384c;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }

  input {
    display: flex;
    z-index: 2;
    color: transparent;
    opacity: 0;
    width: 100%;
    height: 130px;
  }
`;
const Drag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
`;
const Inp = styled.input`
  background: transparent;
  border: none;
  outline: none;
  font-weight: 700;
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 12px !important;

  ::focus {
    outline: none;
  }
  @media (max-width: 472px) {
    padding: 10px 12px !important;
  }
`;
const Comp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 14px !important;
  z-index: 1;
  cursor: pointer;
  margin-top: 12px;
  img {
    width: 56px;
    height: 56px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 24px;
  text-align: center;
  color: #fff;
`;
const Grid = styled.div`
  display: flex;
  gap: 0px 12px;
  width: 99%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  text-align: ${(props) => props.Align};
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #949ab2;
  svg {
    fill: #949ab2 !important;
  }
`;
const Wrapper = styled.div`
  display: grid;
  grid-gap: 12px;
  text-align: center;
  grid-template-columns: 1fr;
  padding: 36px 24px;
  button {
    margin-top: 24px;
  }
  @media (max-width: 1024px) {
    padding: 24px 12px;
    grid-gap: 4px;
  }
`;
const SOption = styled.option`
  &:hover {
    color: #000;
    background-color: #fff !important;
  }
`;
const InputTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  text-align: ${(props) => props.alignSelf};
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${(props) => props.align};
  gap: 4px;

  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  select {
    background: #1d1f25;
    border: 1.5px solid #34384c;
    border-radius: 8px;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: ${(props) => props.align};
    padding: 8px 10px;
    option:hover {
      background: #fff !important;
      color: black !important;
    }

    :focus {
      outline: none;
    }
  }
`;
const SButton = styled.button`
  border: 1px solid #d71159;
  position: relative;
  background-color: ${(props) =>
    props.transparent ? "transparent" : "#D71159"};
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    tr
  }
`;
const FlexBox = styled(Flex)`
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;
var first = true;
export default function KidCryptoPay({
  remaining,
  totalPackage,
  bundleId,
  numberofShare,
  totalPrice,
}) {
  const { t } = useTranslation();
  const { gulfPrice, sharePrice } = useContext(PriceContext);
  const { modal2Open, setModal2Open } = useContext(ModalContext);
  const { phase, setPhase } = useContext(AnimationContext);
  const abi = erc20ABI; // replace with your contract ABI
  var userAddress;
  const [contract, setContract] = useState();
  var balance;
  var coeff = 1;
  const [isMobile, setIsMobile] = useState(false);
  const [changed, setChanged] = useState(false);
  var ethereum = useSigner();
  const { data: signer, isError, isLoading } = useSigner();
  var account = useAccount();
  let uAddress = account.address;
  var tokenSelect = document.getElementById("token");
  var balanceInput = document.getElementById("balance");

  var amountInput = document.getElementById("amount");
  var itemsInput = document.getElementById("items");
  var ageInput = document.getElementById("birthday");
  const fileInputRef = useRef(null);
  var nameInput = document.getElementById("name");
  var buyButton = document.getElementById("buy");
  var totalAmount = document.getElementById("totalAmount");
  const childerens = useContext(NewApiContext);
  const kids = childerens.children;

  const [gulfSendAmount, setGulfSendAmount] = useState(0);
  var provider;
  const [selectedKid, setSelectedKid] = useState({
    id: "",
    name: "",
    childBirthday: "",
  });
  useEffect(() => {
    document.getElementById("buy").className = "disabled-button";

    if (uAddress != undefined) {
      console.log("uAddress = " + uAddress);
      document.getElementById("buy").className = "enabled-button";

      updateInputs(uAddress);
      OnInputChenged(uAddress);
      $.ajax({
        type: "POST",
        url: config.WALLET_REG,
        data: {
          userAddress: uAddress,
          userId: localStorage.getItem("id"),
          token: localStorage.getItem("token"),
        },
        dataType: "json",
        success: function (response) {
          // console.log(response);
          localStorage.setItem("address", userAddress);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("AJAX call failed: " + textStatus + ", " + errorThrown);
        },
      });
    }
  }, [signer]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(/iphone|ipad|ipod|android/.test(userAgent));
  }, []);

 

  useEffect(() => {
    let gokhan = nameInput?.value;
    let burhan = ageInput?.value;
    console.log(gokhan, burhan);
  }, [nameInput?.value, ageInput?.value]);

  function onTransactionComplete(receipt) {
    $.ajax({
      type: "POST",
      url: config.KID_CRYPTO,
      data: {
        hash: receipt.transactionHash,
        userId: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        bundleId: bundleId,
        email: localStorage.getItem("email"),
        quantity: amountInput.value,
        childBirthday: ageInput?.value,
        childName: nameInput?.value,
      },
    }).done(function (response) {
      const resp = JSON.parse(response);

      if (resp.status === "error") {
        setPhase("error");
      } else {
        setPhase("success");
      }

      setTimeout(() => {
        window.location.reload();
      }, 700);
    });
  }

  async function Main(ethereum, account) {
    if (ethereum == null) return;
    //alert("main");
    await sleep(100);
    // await InitMetaMask();
    let uAddress = account.address;

    await updateInputs(uAddress);

    // await ethereum.enable();
    provider = ethereum.data.provider;

    // setSigner(gokhan);

    //signer = useSigner();

    // userAddress = await signer.getAddress();

    await updateInputs(uAddress);
    // console.log(`Address: ${userAddress}`);
    let userId = localStorage.getItem("id");
    let addresinLocal = localStorage.getItem("address");
    // Make the AJAX call
    let currentNetworkId;
    currentNetworkId = await ethereum.request({ method: "eth_chainId" });

    // Check if the current network ID matches the ID of the network we want to switch to
    if (currentNetworkId !== "0x38") {
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x38" }],
        });

        setModal2Open(true);
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set("openModal", "true");
        window.location.href = currentUrl.toString();
      } catch (error) {
        // Handle the error if the user did not switch networks
        console.error("error = " + error);
      }
    }

    if (addresinLocal === null) {
      $.ajax({
        type: "POST",
        url: config.WALLET_REG,
        data: {
          userAddress: uAddress,
          userId: userId,
          token: localStorage.getItem("token"),
        },
        dataType: "json",
        success: function (response) {
          // console.log(response);
          localStorage.setItem("address", userAddress);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("AJAX call failed: " + textStatus + ", " + errorThrown);
        },
      });
    }

    tokenSelect.selectedIndex = 2;
    await sleep(100);
    await updateInputs(uAddress);
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const [val, setVal] = useState(0);
  const [gulfOrDollar, setGulfOrDollar] = useState(true); // true mean its dollar
  async function updateInputs(uAddress) {
    if (tokenSelect.value === "0x55d398326f99059fF775485246999027B3197955") {
      coeff = 1 / sharePrice;
      setGulfOrDollar(true);
    } else if (
      tokenSelect.value === "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    ) {
      coeff = 1 / sharePrice;
      setGulfOrDollar(true);
    } else if (
      tokenSelect.value === "0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a"
    ) {
      coeff = gulfPrice / sharePrice;
      setGulfOrDollar(false);
    }
    let cont = new ethers.Contract(tokenSelect.value, abi, signer);
    // console.log(signer);
    setContract(cont);
    balance = await cont.balanceOf(uAddress);
    balanceInput.value = ethers.utils.formatUnits(balance, 18);
    // console.log(balance);
  }

  //updateInputs();
  async function OnInputChenged(uAddress) {
    balance = 999999;
    let cont = new ethers.Contract(tokenSelect.value, abi, signer);
    balance = await cont.balanceOf(uAddress);
    setChanged(!changed);
    // console.log(balance);

    try {
      //   console.log("userAddress = " + userAddress)
      if (uAddress === undefined) {
        await Main(ethereum, account);
      }
    } catch {
      console.log("error");
    }
    const rate = coeff; // replace with your rate

    const items = amountInput.value * coeff; // ethers.utils.formatUnits(amount.mul(rate), 0);
    // console.log("amount= " + amount + " balance= " + balance);
    // if (amount.gt(balance)) {
    //   itemsInput.value = "Insufficient balance";

    //   buyButton.disabled = true;
    //   document.getElementById("buy").className = "disabled-button";
    // } else {
    itemsInput.value = amountInput.value * numberofShare;
    totalAmount.value = amountInput.value * totalPrice;
    setVal(totalAmount.value);
    if (tokenSelect.value === "0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a") {
      totalAmount.value = (amountInput.value * totalPrice) / gulfPrice;
      setVal(totalAmount.value);
    }
    setGulfSendAmount(totalAmount.value);
    buyButton.disabled = false;
    document.getElementById("buy").className = "enabled-button";
    // }
  }
  const [error, setError] = useState(false);

  useEffect(() => {
    tokenSelect = document.getElementById("token");
    balanceInput = document.getElementById("balance");
    amountInput = document.getElementById("amount");
    itemsInput = document.getElementById("items");
    buyButton = document.getElementById("buy");
    updateInputs(uAddress);

    OnInputChenged(uAddress);
    // This code will be executed once when the component is mounted
    first = false;
    // console.log("first");

    Main(ethereum, account);
    amountInput.addEventListener("input", async () => {
      await OnInputChenged(uAddress);
    });

    tokenSelect.addEventListener("change", async () => {
      if (uAddress === undefined) await Main(ethereum, account);
      await updateInputs(uAddress);
      return await OnInputChenged(uAddress);
    });

    buyButton.addEventListener("click", async () => {
      try {
        setError(false);
        const amount = ethers.utils.parseUnits(totalAmount.value, 18);
        console.log(amount);

        let tx;
        let cont = new ethers.Contract(tokenSelect.value, abi, signer);
        if (gulfOrDollar === false) {
          tx = await cont.transfer(
            "0x2B6574E4191822bEaf8cCBE59dDC622Ee502fdD8",
            amount
          );
        } else {
          tx = await cont.transfer(
            "0x2B6574E4191822bEaf8cCBE59dDC622Ee502fdD8",
            amount
          );
        } // replace with your wallet address
        setPhase("loading");
        // console.log(phase);
        const receipt = await tx.wait();

        console.log(
          "token value = ",
          tokenSelect.value,
          " amount = ",
          amount,
          " signer= ",
          signer
        );

        if (receipt.status === 1) {
          onTransactionComplete(receipt);
        }
      } catch (error) {
        // alert(error);
        // console.log(error);
        setError(true);
      }
    });
  }, [tokenSelect, uAddress, signer]);

  useEffect(() => {
    updateInputs(uAddress);
    OnInputChenged(uAddress);
  }, [tokenSelect, uAddress, signer, changed]);

  const align = LanguageCheck() === "ar" ? "right" : "left";

  function registerKid(childName, childBirthday) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("id");

    return new Promise((resolve, reject) => {
      $.ajax({
        url: config.KID_REGISTER,
        method: "POST",
        data: {
          childName: childName,
          childBirthday: childBirthday,
          userId: userId,
          token: token,
        },
        success: function (data) {
          const resp = JSON.parse(data);
          console.log(resp.id);
          return resolve(resp.id);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("Error:", textStatus, errorThrown);
          reject(errorThrown);
        },
      });
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];
    var formData = new FormData();
    const childName = document.getElementById("name").value;
    const childBirthday = document.getElementById("birthday").value;

    registerKid(childName, childBirthday)
      .then((kidId) => {
        formData.append("fileToUpload", file);
        formData.append(
          "fileName",
          localStorage.getItem("id") + "_" + kidId + ".png"
        );
        formData.append("id", localStorage.getItem("id"));
        formData.append("token", localStorage.getItem("token"));
        formData.append("submit", "submit");

        fetch(config.AVATAR_UPLOAD, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            let KidimgVersion = localStorage.getItem("imgVersion");
            if (KidimgVersion === null) {
              KidimgVersion = 0;
            } else {
              KidimgVersion = parseInt(KidimgVersion, 10) + 1;
            }
            localStorage.setItem("KidimgVersion", KidimgVersion);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error("Error registering kid:", error);
      });
  }

  return (
    <Wrapper>
      <Title>{t("cryptopay.title")}</Title>
      <FlexBox justify="space-around">
        <Web3Button />
        {/* <RefreshBalance address={uAddress} /> */}
      </FlexBox>
      <Drop
        kids={kids}
        selectedKid={selectedKid}
        setSelectedKid={setSelectedKid}
      />
      {selectedKid.id === "add" ? (
        <>
          <Grid>
            <InputWrapper>
              <InputTitle alignSelf={align}>Child Name</InputTitle>
              <StyledInput
                name="name"
                type="text"
                placeholder="Child Name"
                id="name"
              />
            </InputWrapper>

            <InputWrapper>
              <InputTitle alignSelf={align}>Child Birthday</InputTitle>

              <StyledInput
                name="birthday"
                type="date"
                placeholder="Child Birthday"
                id="birthday"
              />
            </InputWrapper>
          </Grid>
          <Dragzone id="dropzone">
            <input
              ref={fileInputRef}
              type="file"
              name="fileToUpload"
              id="fileToUpload"
              onChange={handleSubmit}
            />

            <Comp>
              <img src="/assets/icons/dragdrop.svg" alt="dragdrop" />

              <Drag>{t("uploadthephoto")}</Drag>
            </Comp>
          </Dragzone>
        </>
      ) : (
        <>
          <Inp
            name="name"
            value={selectedKid.name}
            id="name"
            type="text"
            hidden
            readOnly={true}
          />
          <Inp
            name="birthday"
            value={selectedKid.childBirthday}
            id="birthday"
            type="date"
            hidden
            readOnly={true}
          />
        </>
      )}
      {/* <Grid>
        <InputWrapper>
          <InputTitle alignSelf={align}>Kid Name</InputTitle>
          <BorderedInput
            name="name"
            type="text"
            placeholder="Child Name"
            id="name"
          />
        </InputWrapper>

        <InputWrapper>
          <InputTitle alignSelf={align}>Kid Birthday</InputTitle>

          <BorderedInput name="age" type="date" id="age" />
        </InputWrapper>
      </Grid> */}
      <Item align={align}>
        <label htmlFor="token">{t("cryptopay.1")}</label>

        <select id="token">
          <SOption value="0x55d398326f99059fF775485246999027B3197955">
            USDT
          </SOption>
          <SOption value="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56">
            BUSD
          </SOption>
          {/* <SOption value="0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a">
            GULF
          </SOption> */}
        </select>
      </Item>

      <Item align={align}>
        <label htmlFor="balance"> {t("cryptopay.2")} </label>
        <StyledInput
          direction={align}
          type="text"
          id="balance"
          placeholder="0.00"
          readOnly
        />
      </Item>

      <Item align={align}>
        <label htmlFor="amount">{t("numberofpackages")}</label>
        <StyledInput
          direction={align}
          type="number"
          id="amount"
          placeholder="0.00"
          defaultValue={1}
        />
      </Item>

      <Item align={align}>
        <label htmlFor="items">{t("cryptopay.4")}</label>
        <StyledInput
          direction={align}
          type="text"
          style={{ cursor: "not-allowed" }}
          id="items"
          placeholder="0"
          readOnly
        />
      </Item>
      <Item align={align}>
        <label htmlFor="totalAmount">
          {gulfOrDollar === true
            ? t("totalamountindollar")
            : t("totalamountgulf")}
        </label>
        <StyledInput
          direction={align}
          type="text"
          style={{ cursor: "not-allowed" }}
          id="totalAmount"
          placeholder="0"
          readOnly
        />
      </Item>
      {error && (
        <Item align={align}>
          <label htmlFor="error" style={{ color: "red", fontWeight: 700 }}>
            {t("insufficientbalance")}
          </label>
        </Item>
      )}

      <SButton id="buy">{t("buynow")}</SButton>
    </Wrapper>
  );
}

const StyledDropDown = styled.div`
  width: 100%;
  .ant-dropdown-trigger {
    background: #1d1f25;
    border: 1.5px solid #34384c;
    border-radius: 8px;
    color: #fff !important;
    font-weight: 600;
    display: flex;
    padding: 8px 10px;
  }
`;
const StyledSpace = styled(Space)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
function Drop({ kids, setSelectedKid, selectedKid }) {
  const { t } = useTranslation();
  const items = kids.map((kid) => {
    return {
      key: kid.id,
      label: (
        <a
          onClick={() => {
            setSelectedKid({
              id: kid.id,
              name: kid.name,
              childBirthday: kid.birthday,
            });
          }}
        >
          {kid.name}
        </a>
      ),
    };
  });
  // items.unshift({
  //   key: "add",
  //   label: (
  //     <a
  //       onClick={() => {
  //         setSelectedKid({
  //           id: "add",
  //           name: "Add Family Member",
  //           childBirthday: "",
  //         });
  //       }}
  //     >
  //       Add Family Member
  //     </a>
  //   ),
  // });
  return (
    <StyledDropDown direction="vertical">
      <Dropdown menu={{ items }} trigger={["click"]} arrow>
        <a onClick={(e) => e.preventDefault()}>
          <StyledSpace>
            {selectedKid.name === "" ? t("selectkid") : selectedKid.name}
            <DownOutlined />
          </StyledSpace>
        </a>
      </Dropdown>
    </StyledDropDown>
  );
}
