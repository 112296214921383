import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Buttons from "./Buttons";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import config from "../config";
import PersonalCheckPopup from "./PersonelCheckPopup";
import { NewApiContext } from "context/newapi";
import { StyledLink } from "./styled";
const Container = styled.div`
  display: flex;
  position: fixed;
  color: #fff;
  top: 110px;
  justify-content: center;
  background-image: url("/assets/gotovote.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 500px;
  height: 436px;
  justify-content: flex-end;
  padding: 40px;
  z-index: 99;
  @media (max-width: 820px) {
    background-image: url("/assets/mobilegotovote.png");
    background-size: cover;
    min-width: 300px;
    max-width: 310px;
    border-radius: 20px;
    height: 536px;
    padding: 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  img {
    width: 16px;
  }
  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 820px) {
    img {
      width: 14px;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
  }
`;
const StyledButton = styled.button`
  border: 1px solid #d71159 !important;
  background-color: #d71159 !important;
  color: #fff !important;
  font-size: 18px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 6px 54px;
  font-weight: 800;
  margin-bottom: 10px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  gap: 8px;
  ${(props) =>
    props.disabled &&
    `
    background-color: #2C2C30 !important;
    color: #616165 !important;
    border: none !important;
  `}
  img {
    width: 30px;
  }
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    font-weight: 800;
    line-height: 160%;
  }
  @media (max-width: 820px) {
    margin-bottom: 16px;
    margin-right: 10px;
  }
`;
const Mask = styled.div`
  display: flex;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
  z-index: 99;
  width: 99vw;
  height: 100vh;
  top: 0px;
`;
export default function Voteopened() {
  const [show, setShow] = useState(false);
  const isLogged = localStorage.getItem("token") !== null ? true : false;
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);
  const apiObj = useContext(NewApiContext);
  const [userShare, setUserShare] = useState(0);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setUserShare(apiObj.sharesWithoutKids);
    if (userShare <= 4999) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [apiObj, userShare]);

  useEffect(() => {
    if (sessionStorage.getItem("popup") === "true") {
      setShow(false);
    }
    if (sessionStorage.getItem("popup") === null) {
      setShow(true);
      sessionStorage.setItem("popup", "true");
    }
  }, []);

  function handleShare() {
    setShow(!show);
    setShowWarning(!showWarning);
  }
  function ClickHandle() {
    if (localStorage.getItem("token") === null) {
      window.location.href = "/login";
      return;
    }
    window.location.href = "/candidates";
  }

  function ClickHandlers() {
    if (localStorage.getItem("token") === null) {
      window.location.href = "/login";
      return;
    }
    window.location.href = "/packages";
  }

  return (
    <>
      {show ? (
        <Wrapper>
          <Mask />

          <Container>
            <Flex>
              <button onClick={() => handleShare()}>
                <img src="/assets/icons/cross.svg" alt="x" />
              </button>
              <TextWrapper>
                <StyledButton
                  disabled={disabled}
                  onClick={ClickHandle}
                  transparent
                >
                  صوت الآن
                </StyledButton>

                <Po>
                  للتصويت في الانتخابات، يتعين عليك امتلاك أكثر من{" "}
                  <span> 5 ألف</span> سهم على الأقل في بنك الخليج الرقمي. احصل
                  على المزيد من  . {' '}
                  <StyledLink
                    style={{ fontWeight: "800" }}
                    onClick={ClickHandlers}
                  >
                    الأسهم
                  </StyledLink>{" "}
                  للانضمام
                </Po>
              </TextWrapper>
            </Flex>
          </Container>
        </Wrapper>
      ) : null}
    </>
  );
}

const Po = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-right: 14px;
  letter-spacing: 0.4px;
  a {
    text-decoration: underline !important;
  }
  span {
    color: #fdd65b;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  p {
    display: inline;
    padding: 0px;
    margin: 0px;
  }

  width: 80%;
  span {
    color: #fdd65b;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;