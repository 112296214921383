import React, { useEffect, useState, useContext } from "react";
import { createCanvas } from "canvas";
import imger from "./certificate.jpg";
import gold from "./goldcertficate.jpg";
import styled from "styled-components";
import { PdfButton } from "components/styled";
import { Spin, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";
import WindowSize from "WindowSizer";
import { NewApiContext } from "context/newapi";
const CertificateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  img {
    width: 100%;
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    margin: 16px 12px 0px 0px;
    justify-content: flex-start;
    img {
      width: 94%;
      aspect-ratio: 16/9;
    }
  }
`;
const NoCert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 32px;
  color: #fff;
  img {
    width: 100%;
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    margin: 16px 12px 0px 0px;
    justify-content: flex-start;
    img {
      width: 94%;
      aspect-ratio: 16/9;
    }
  }
`;

const AbsoluteButton = styled(PdfButton)`
  background-color: #fa2256;
  margin-top: 16px;
  color: #fff;
  border-radius: 30px;
  padding: 6px 16px;
  direction: ${(props) => props.direction};
  top: 20%;
  right: 7%;
  img {
    width: 20px;
  }
  @media (max-width: 1024px) {
    right: 10%;
    top: 17%;
    padding: 3px 12px;
    font-size: 12px;

    img {
      width: 20px;
    }
  }
`;

export default function CertificateMaker({
  selectedCertificate,
  setIsImage,
  isImage,
}) {
  const [imageData, setImageData] = useState(null);
  const { t } = useTranslation();
  const showName =
    localStorage.getItem("name") + " " + localStorage.getItem("lastname");
  const [noCertif, setnoCertif] = useState(false);
  const shares = selectedCertificate.shares;
  const certificateNumber = selectedCertificate.id;
  const { direction, align, rightLeft } = useContext(DirectionContext);
  const isMobile = WindowSize();
  const user = useContext(NewApiContext);

  const img = new Image();
  const img2 = new Image();
  img.src = imger;
  img2.src = gold;

  function containsArabic(text) {
    const arabicPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
    return arabicPattern.test(text);
  }
  function wrapText(context, text, x, y, maxWidth, lineHeight) {
    const words = text.split(" ");
    let line = "";

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + " ";
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }

    context.fillText(line, x, y);
  }

  useEffect(() => {
    if (selectedCertificate.id === "") {
      setnoCertif(true);
    }
    if (selectedCertificate.id === undefined) {
      setnoCertif(true);
    }
   
  }, [user, selectedCertificate]);


  useEffect(() => {
    const createImage = async () => {
      const canvas = createCanvas(1794, 1192);
      const ctx = canvas.getContext("2d");

      const certificateImage = selectedCertificate.isgold ? img2 : img;

      await new Promise((resolve) => {
        certificateImage.onload = () => {
          resolve();
        };
      });

      ctx.drawImage(certificateImage, 0, 0);

      const fontSizeCertificateNumber = 32;
      //CERTIF
      ctx.font = `${fontSizeCertificateNumber}px 'IBM Plex Mono'`;
      const x = 162;
      const y = 285;
      ctx.fillText(certificateNumber, x, y);

      // Shares
      const fontSizeShares = 32;
      ctx.font = `${fontSizeShares}px 'IBM Plex Mono'`;
      const x1 = 1384;
      const y1 = 285;
      ctx.fillText(shares, x1, y1);

      // Name
      const fontSizeName = 40;

      ctx.font = containsArabic(showName)
        ? `${fontSizeName}px 'Cairo'`
        : `${fontSizeName}px 'IBM Plex Mono'`;
      const y2 = 436;
      const centerX = canvas.width / 2;
      ctx.textAlign = "center"; // Horizontal alignment
      ctx.textBaseline = "middle"; // Vertical alignment

      // ctx.fillText(showName.toUpperCase(), centerX, y2);

      const maxWidth = 570;
      const lineHeight = fontSizeName + 5;
      wrapText(ctx, showName.toUpperCase(), centerX, y2, maxWidth, lineHeight);
      //  wrapText(ctx, "gokhan burhan the seconn  ".toUpperCase(), centerX, y2, maxWidth, lineHeight);

      const dataUrl = canvas.toDataURL();

      // Set the data URL as the image source

      setImageData(dataUrl);
      setIsImage(true);
    };

    createImage();
  }, [selectedCertificate, shares, certificateNumber, showName, isImage]);

  const handleDownload = () => {
    // Create a download link with the data URL
    const link = document.createElement("a");
    link.download = certificateNumber;
    link.href = imageData;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [skeletonSize, setSkeletonSize] = useState({
    height: "74vh",
    width: "60vw",
  });

  useEffect(() => {
    isMobile
      ? setSkeletonSize({
          height: "40vw",
          width: "80vw",
        })
      : setSkeletonSize({
          height: "68vh",
          width: "60vw",
        });
  }, [isMobile]);

  return (
    <CertificateContainer>
      {noCertif ? (
        <NoCert style={{ width: "60vw", maxWidth: "1000px" }}>
          {t("nocertificate")}
        </NoCert>
      ) : (
        <>
          {!imageData ? (
            <Skeleton.Image
              style={{
                width: skeletonSize.width,
                maxWidth: "1000px",
                height: skeletonSize.height,
              }}
            />
          ) : (
            <>
              <img src={imageData} alt={certificateNumber} />
              <AbsoluteButton direction={direction} onClick={handleDownload}>
                <img src="/assets/icons/download.svg" alt="pdf-download" />
                {t("download")}
              </AbsoluteButton>
            </>
          )}
        </>
      )}
    </CertificateContainer>
  );
}
