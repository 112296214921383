import { Progress } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Modal } from "antd";
import PackageModal from "./PackageModal";
import { RevertedFlex } from "components/styled";
import { DirectionContext } from "context/direction";
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    72.35% 72.35% at 50% 35.92%,
    #24111d 0%,
    #1d1f25 100%
  );
  border: 1px solid #5d6588;
  border-radius: 28px;
  width: max-content;
  padding-bottom: 30px;

  img:first-child {
    position: absolute;
    margin-left: 24px;
  }
  img:nth-child(2) {
    padding-top: 24px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px;
  color: #fff;
  div {
    display: flex;
    justify-content: space-between;
  }
`;
const Progressbar = styled(Progress)`
  .ant-progress-bg {
    background-color: #d71159;
    height: 18px !important;
  }
  .ant-progress-inner {
    background-color: #35373c;
  }
  .ant-progress-text {
    color: #fff !important;
    position: absolute;
    padding-top: 6px !important;
    padding-left: 65%;
    font-weight: 700;
  }
  .ant-progress-text {
    color: #fff;
  }
  .ant-progress-outer {
    margin-inline-end: 0px !important;
    padding-inline-end: 0px !important;
  }
  .ant-progress-line {
    margin-inline-end: 0px !important;
  }
`;
const BuyButton = styled.button`
  background-image: ${(props) => props.rank};
  border-radius: 100px;
  border: none;
  color: #1d1f25;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;

  ${(props) =>
    props.isSoldOut &&
    `
    cursor: not-allowed;
    background-image:unset;
    background-color: #35373C;
    color:#585A5F;
    
    `}
  &:hover {
    opacity: 0.8;
  }
`;
const CustomText = styled.span`
  position: absolute;
  font-weight: 500;
  z-index: 1;
  color: #fff;
  padding-left: 6px;
  font-size: 14px;
  @media (max-width: 1024px) {
    margin-top: -1px !important;
  }
`;
const ProgressWrapper = styled.div`
  margin: 20px 0px;
`;
export default function PackageCard({
  amount,
  rank,
  percentage,
  mark,
  numberofShare,
  totalPrice,
  bundleId,
  totalPackage,
  remaining,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { direction } = useContext(DirectionContext);
  const isLogged = localStorage.getItem("token") !== null ? true : false;
  const [isSoldOut, setIsSoldOut] = useState(remaining === 0 ? true : false);

  useEffect(() => {
    remaining === 0 ? setIsSoldOut(true) : setIsSoldOut(false);
    console.log(isSoldOut);
  }, [remaining]);

  const showModal = () => {
    if (isSoldOut) {
      return;
    }
    if (!isLogged) {
      window.location.href = "/login";
      return;
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { t } = useTranslation();
  const remainingPercent = (remaining / totalPackage) * 100;
  const current = totalPackage - remaining;
  const completedPercent = (current / totalPackage) * 100;

  return (
    <CardWrapper>
      <img src={mark} alt="package" />
      <img src={amount} alt="package" />
      {isSoldOut && (
        <img
          src="/assets/packages/soldout.svg"
          alt="sold-out"
          style={{
            position: "absolute",
            margin: "auto",
          }}
        />
      )}
      <ContentWrapper>
        <RevertedFlex direction={direction}>
          <div>{t("numberofshares")}</div>
          <div>{numberofShare}</div>
        </RevertedFlex>
        <RevertedFlex direction={direction}>
          <div>{t("totalprice")}</div>
          <div>{totalPrice}$</div>
        </RevertedFlex>
      </ContentWrapper>
      <ContentWrapper>
        <ProgressWrapper>
          <CustomText>
            <div>{t("remainingpackage")}</div>
          </CustomText>

          <Progressbar
            percent={completedPercent}
            success={{ strokeColor: "#aeaeae" }}
            format={() => `${remaining}`}
          />
        </ProgressWrapper>

        <BuyButton isSoldOut={isSoldOut} onClick={showModal} rank={rank}>
          {isSoldOut ? t("soldout") : t("pButton")}
        </BuyButton>
        <Modal
          destroyOnClose={true}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <PackageModal
            totalPackage={totalPackage}
            remaining={remaining}
            numberofShare={numberofShare}
            bundleId={bundleId}
            totalPrice={totalPrice}
          />
        </Modal>
      </ContentWrapper>
    </CardWrapper>
  );
}
