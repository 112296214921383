import styled, { keyframes } from "styled-components";

export const Circle = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  ${(props) => (props.clicked === true ? `  border: 6px solid #fa2256;` : null)}
  box-sizing: border-box;
  min-width: 180px;
  min-height: 180px;
  max-width: 180px;
  max-height: 180px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;

  @media (max-width: 1300px) {
    min-width: 154px;
    min-height: 154px;
    max-width: 154px;
    max-height: 154px;
  }
`;

export const Label = styled.div`
  position: absolute;
  bottom: -20px;
  ${(props) => props.clicked === true && `border: 2px solid #fa2256;`}
  box-sizing: border-box;
  left: 50%;
  padding: 4px 8px;
  transform: translateX(-50%);
  background: #141518;
  border-radius: 5px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  @media (max-width: 600px) {
    white-space: nowrap;
    font-size: 16px;
  }
`;
export const SButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
`;

export const UserWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const ExtendedUserWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
  }
`;
export const MobileUserWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  z-index: 10;
`;

export const ShowButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  color: #fdd65b;
  font-size: 24px;
  font-family: Cairo;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
`;
export const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const ExtendedProfilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(120px, 1fr));
  gap: 28px;
  animation: ${slideIn} 0.5s ease-out;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, minmax(120px, 1fr));
    gap: 32px 6px;
    margin-bottom: 32px;
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(120px, 1fr));
    gap: 32px 6px;
    margin-bottom: 32px;
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    gap: 32px 6px;
    margin-bottom: 32px;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
  flex-wrap: wrap;
  gap: 28px;
  justify-content: center;

  @media (max-width: 1300px) {
    gap: 16px;
  }
  @media (max-width: 600px) {
    gap: 0px;
  }
`;
