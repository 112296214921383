import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DirectionContext } from "context/direction";
import { LanguageContext } from "context/language";

const LangButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #5d6588;
  color: #fff;
  background: #1e1f25;
  padding: 0px 24px;
  direction: ${({ direction }) => direction};
  gap: 8px;
  border-radius: 24px;
  img {
    max-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
    max-height: 30px !important;
  }
`;
const LangWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
export default function Langswitch() {
  const { t } = useTranslation();
  const { direction } = useContext(DirectionContext);
  const { changeLanguage } = useContext(LanguageContext);

  return (
    <LangWrapper>
      <LangButton
        direction={direction}
        onClick={() => changeLanguage("eng")}
      >
        <img src="/assets/icons/mobilemenu/usa.svg" />
        {t("english")}
      </LangButton>
      <LangButton
        direction={direction}
        onClick={() => changeLanguage("ar")}
      >
        <img src="/assets/icons/mobilemenu/ar.svg" />
        {t("arabic")}
      </LangButton>
    </LangWrapper>
  );
}
