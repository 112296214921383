import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ErrorAnimation from '../components/ErrorAnimation'
import Navbar from '../components/Navbar'
import { SuccessAnimation } from '../components/SuccessAnimation'
import Buttons from '../components/Buttons'
import { StyledLink } from '../components/styled'

import LanguageCheck from '../langcheck'
const NotificationWrapper = styled.div`
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
gap:40px;
height:100vh;
width:100vw;
max-width:100vw;
max-height:100vh;
background-color:#1D1F25;
`


const NotifMessage = styled.div`
font-weight: 700;
font-size: 25px;
color:#fff;
text-align:center;
width:420px;
span {
    color:#D71159;
}
@media (max-width: 768px) {
    width:300px;
}

`

export default function BookSuccess() {
    const { t } = useTranslation()
    const Language = LanguageCheck()


    return (
        <>
            <Navbar />
            <NotificationWrapper>
                <SuccessAnimation />
                {
                    Language === 'eng' ?
                        <NotifMessage>
                            Thank you for booking your appointment, our agents will contact you within <span>24</span> to <span>48</span> hours
                        </NotifMessage>
                        :
                        <NotifMessage>
                            شكرا لقد تم تحديد موعدكم سيتصل بك الوكلاء بين <span>24  </span> و <span>48  </span> ساعة
                        </NotifMessage>

                }
                <StyledLink to="/dashboard">
                    <Buttons text={t('gotodashboard')} />
                </StyledLink>


            </NotificationWrapper>
        </>

    )
}
