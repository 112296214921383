import React from "react";
import styled from "styled-components";
import { Container, Flex } from "../Layouts";
import { useTranslation } from "react-i18next";
const SocialButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  background: transparent;
  color: #fff;
  min-width: 100px;
  max-width: 110px;
  white-space: nowrap;
  flex-wrap: nowrap;

  text-decoration: none !important;
  gap: 6px;
  border-radius: 24px;
  border: 1.5px solid #d71159;
  cursor: pointer;
  h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
  }
  transition: 0.2s;
  @media (max-width: 1024px) {
    font-size: 14px;
    max-width: 90px;
    min-width: 90px;
    padding: 4px 16px;
    img {
      width: 22px;
    }
    h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 700;
    }
  }
  :hover {
    opacity: 0.8;
  }

  @media (max-width: 600px) {
    padding: 4px 6px;
    max-width: 84px;
    min-width: 84px;
    h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 700;
    }
  }
`;
const Flexx = styled(Flex)`
  justify-content: ${(props) => props.justify && "flex-end"};
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export default function SocialMedia({
  youtube,
  whatsapp,
  telegram,
  linkedin,
  justify,
  tiktok
}) {
  const { t } = useTranslation();

  const linkCorrection = (str) => {
    if (!str || str.includes("http")) return false;
    return true;
  };

  let whatsappUrl = whatsapp;
  let telegramUrl = telegram;
  if (linkCorrection(whatsapp)) {
    whatsappUrl = "https://wa.me/" + whatsapp;
  }
  if (linkCorrection(telegram)) {
    telegramUrl = "https://t.me/" + telegram;
  }

  return (
    <Flexx justify={justify} gap="12px">
      {youtube && (
        <SocialButton target="_blank" href={youtube}>
          <img src="/assets/icons/youtube.svg" alt="whatsapp" />
          <h4> {t("youtube")} </h4>
        </SocialButton>
      )}

      {whatsapp && (
        <SocialButton target="_blank" href={whatsappUrl}>
          <img src="/assets/icons/whatsapp.svg" alt="whatsapp" />
          <h4> {t("whatsapp")} </h4>
        </SocialButton>
      )}

      {telegram && (
        <SocialButton target="_blank" href={telegramUrl}>
          <img src="/assets/icons/telegram.svg" alt="telegram" />
          <h4>{t("telegram")} </h4>
        </SocialButton>
      )}

      {tiktok && (
        <SocialButton target="_blank" href={tiktok}>
          <img src="/assets/icons/tiktok.svg" alt="tiktok" />
          <h4>{t("tiktok")} </h4>
        </SocialButton>
      )}
      {linkedin && (
        <SocialButton target="_blank" href={linkedin}>
          <img src="/assets/icons/linkedin.svg" alt="linkedin" style={{ maxWidth: "34px" }} />
          <h4>{t("linkedin")} </h4>
        </SocialButton>
      )}
    </Flexx>
  );
}
