import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import "../App.css"
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import SignLeft from '../components/SignLeft';
import config from '../config';
import LanguageCheck from '../langcheck';
import PwChangedSucces from '../components/PwChangedSucces';
import LogPageMobilIcon from '../components/LogPageMobilIcon';
import BackHome from '../components/BackHome';

const Form = styled.form`
color: #fff;
display: flex;
flex-direction: column;
gap:70px;


@media (max-width: 1024px) {
    gap: 40px;
}
`

const Grid = styled.div`
    display:grid;
    grid-template-rows: 1fr 1fr ;
    gap: 20px;
    padding-top:28px;

@media (max-width: 1024px) {
}
`

const Item = styled.div`
display: flex;
flex-direction: column;
gap:4px;
label{
    display:block;
    font-size:14px;
}
`

const ButtonBox = styled.div`
display: flex;
flex-direction: column;
text-align: center;
font-size: 18px;
font-weight: 800;
white-space: nowrap;
gap:15px;
img {
margin-top:60px;
}
@media (max-width: 1024px) {
img {
margin-top:100px;
margin-bottom:50px;
}
}

button {
  border:1px solid #D71159;
  position:relative;
  background-color: #D71159;
  color: #fff;
  font-size:18px;
  padding:10px 54px;
  font-weight:800;
  border-radius:30px;
  cursor:pointer;
  &:hover {
    opacity:0.8;
  }
  
  @media (max-width: 1024px) {
    padding:12px 42px;
    height: 50px;
    font-weight: 800;
    font-size: 18px;
        line-height: 160%;
      }
    }
    
    `


const Title = styled.div`

color:#fff;
font-weight: 800;
font-size: 28px;
line-height: 160%;
`

const Error = styled.div`
color:#D91B60;
font-size: 14px;
position: relative;
botttom:0;
font-weight: 500;
`
const FormWrapper = styled.div`
    display:flex;
    flex-direction:column;
    padding:220px 150px 150px 150px;
    @media (max-width: 1600px) {
        padding:240px 50px;
    }
    @media (max-width: 1024px) {
        height:50vh;
    }
    
    @media (max-width: 550px) {
        padding:280px 30px;
    }
`
const Page = styled.div`
display:grid;
grid-template-columns: 1.5fr 1fr;
background-color: #141518;
height:100vh;

text-align: ${props => props.direction};
@media (max-width: 1024px) {
grid-template-columns: 1fr;
height:100%;
}
    `
const Input = styled.input`
background: #1D1F25;
border: 1.5px solid #34384C;
border-radius: 8px;
height: 50px;
color:white;
text-align: ${props => props.direction};
padding: 0 20px;
:focus{
outline: none;
}
:hover{
transition: 0.2s;
border-color: #D71159;
}

::placeholder {
color: #5D6588;
text-align: ${props => props.direction};
}

@media (max-width: 1024px) {
    width:auto;
max-width:100%;
}
`

export default function NewPassword() {
    const [succes, setSucces] = useState(false)
    const [confirmPw, setConfirmPw] = useState("");
    const Language = LanguageCheck()
    const [expired, setExpired] = useState(false)
    const [user, setUser] = useState({
        password: '',
        confirmError: '',
        emptyError: ''
    });
    const direction = LanguageCheck() === 'ar' ? 'right' : 'left';
    const { t } = useTranslation();

    let token
    const url = new URL(window.location.href);
    token = useRef(url.searchParams.get("token"))




    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
    }

    const validate = () => {
        let isError = false;
        const errors = {
            passwordError: '',
            emailError: '',
        };
        if (user.password === '') {
            isError = true;
            Language === 'ar' ? user.emptyError = 'هذه الخانه اجباريه' : user.emptyError = 'This field is required'
        }
        if (user.password !== confirmPw) {
            isError = true;
            Language === 'ar' ? user.confirmError = 'كلمة السر غير متطابقة' : user.confirmError = 'Password does not match'
        }
        else isError = false;

        setUser({ ...user, ...errors });
        console.log(isError);
        return isError;
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        document.forms[0].reset();
        const err = validate();

        if (err === false && confirmPw === user.password)
            $.ajax({
                type: 'POST',
                url: config.TOKEN_PW + '?token=' + token.current,
                data: {
                    password: user.password,
                    submit: 'submit'
                },
                success: function (response) {
                    let resp = JSON.parse(response);
               
                    if (resp.status === "success") {
                        setSucces(true)
                    } else {
                        setExpired(true)
                    }
                },
                error: function (error) {
                    console.error('Error:', error);
                }
            });

    }

    return (

        <Page direction={direction}>
            <SignLeft />

            <FormWrapper>
                {
                    !succes
                        ?
                        <>
                            <LogPageMobilIcon />
                            <BackHome />
                            <Title>
                                {t('newpassword')}
                            </Title>
                            <Form onSubmit={handleSubmit} >
                                <Grid>
                                    <Item>
                                        <label>{t('password')}</label>
                                        <Input direction={direction} name="password" value={user.password} type="password" onChange={handleChange} placeholder={t('password')} />
                                        {user.emptyError && <Error >{user.emptyError}</Error>}
                                    </Item>
                                    <Item>
                                        <label>{t('cPassword')}</label>
                                        <Input type="password" value={confirmPw} onChange={(e) => setConfirmPw(e.target.value)} placeholder={t('cPassword')} direction={direction} />
                                        {user.confirmError && <Error >{user.confirmError}</Error>}
                                        {expired && <Error >"expired"</Error>}
                                    </Item>
                                </Grid>

                                <ButtonBox >
                                    <button type='submit'>
                                        {t('nButton')}
                                    </button>
                                </ButtonBox>
                            </Form>
                        </>

                        :

                        <PwChangedSucces />
                }

            </FormWrapper>

        </Page>
    )
}
