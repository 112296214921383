import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Verified from "./Verified";
import LanguageCheck from "../../langcheck";
import axios from "axios";
import { formatNumber } from "../../FormatNumber";
import config from "../../config";
import { UserContext } from "../../context/user";
import $ from "jquery";
import { NewApiContext } from "context/newapi";
const NumberShares = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  div {
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
  img {
    max-width: 26px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  direction: ${(props) => props.dir};
  gap: 16px;
  img {
    max-width: 70px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const UserInvest = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    place-items: center;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  gap: 6px;
  img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
`;

const Persona = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  gap: 2px;
  line-height: 120%;
  color: #fff;
  div:last-child {
    color: #848ba9;

    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }
`;

const Flex = styled.div`
  display: flex;
  place-items: flex-start;
  gap: 10px;
  @media (max-width: 630px) {
    flex-direction: column;
    place-items: center;
    text-align: center;
  }
`;
const Avatar = styled.div`
  display: flex;
  width: 60px;
  max-width: 100px;
  height: 60px;
  max-height: 100px;
  background-image: url("${(props) => props.avatar}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`;

export default function ProfileContainer({ kyc }) {
  const { t } = useTranslation();
  const name = localStorage.getItem("name");
  const lastname = localStorage.getItem("lastname");
  const direction = LanguageCheck() === "ar" ? "rtl" : "ltr";
  const data = useContext(NewApiContext);
  const avatar = data.user.avatar + "?imgVersion=" + data.user.imgVersion;

  return (
    <InfoContainer dir={direction}>
      <Flex>
        <AvatarWrapper>
          <Avatar avatar={avatar} />
          {/* <img src={user.avatar} alt="profile" /> */}
          <Verified kyc={kyc} />
        </AvatarWrapper>

        <UserInvest>
          <Persona>
            <div>
              {name} {lastname}
            </div>
            <div>{data.user.bio}</div>
          </Persona>

          <NumberShares>
            <div>
              {t("numberofshares")}
              <div style={{ color: "#fff" }}>
                {formatNumber(data.user.totalshares)}
              </div>
            </div>
          </NumberShares>
        </UserInvest>
      </Flex>
    </InfoContainer>
  );
}
