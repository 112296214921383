import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #141518;

  background-image: url("/assets/dashbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 40px 190px 40px 250px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 900px) {
    padding: 40px 90px;
    background-position: unset;
  }
  @media (max-width: 500px) {
    padding: 40px 30px;
  }
`;

const Title = styled.text`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  direction: rtl;
  gap: 20px;
  figure {
    margin: 0px;
  }
  p {
    margin: 6px 0px;
  }

  h2 {
    margin: 8px 0px;
  }

  img {
    aspect-ratio: 11/5;
    border-radius: 16px;
    max-width: 70%;
  }
  @media (max-width: 900px) {
    img {
      max-width: 100%;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

export default function Article4() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <Navbar />
      <NewsWrapper>
        <Wrapper>
          <Header>
            <Title> بنك الخليج الرقمي | حين يبدع العرب</Title>
          </Header>

          <figure class="wp-block-image size-large">
            <img
              src="https://crypto-horizon.com/wp-content/uploads/2023/05/IMG_20230504_212305_837.jpg"
              alt=""
            />
          </figure>

          <p class="has-text-align-right">
            منذ بداية 2023، وعلى خلاف ما هو متعارف عليه من تأخر العالم العربي في
            المجال الرقمي، تبرز في العالم الرقمي شركة عربية وهي شركة الخليج
            للبرمجيات والتقنية، وتعلن طرحها لأول مشاريع عملتها GULF، وهو بنك
            الخليج الرقمي،
            <br />
            <br />
            ضربة معلم، هذا ما وصفته المجلات والتقارير الصحفية المعنية بالعالم
            الرقمي، وعلى رأسها بلومبرغ، كما صنفته كإحدى أقوى عشرة مشاريع
            استثمارية في الشرق الأوسط والعالم العربي.
          </p>

          <h2 class="wp-block-heading has-text-align-right">
            بنك الخليج الرقمي | حين يبدع العرب
          </h2>

          <p class="has-text-align-right">
            <br />
            بنك الخليج الرقمي هو مشروع طوّرته شركة
            <strong> “جَلف سوفتوير اند تكنولوجي”</strong> كمشروع بنكي متكامل، ذو
            خدمات رقمية متطورة ، تربط ما بين خدمات واحتياجات المستخدم على شبكة
            الإنترنت وأرض الواقع. ويسعى البنك من خلال رعايته للعديد من المؤتمرات
            إلى نشر ثقافة التكنولوجيا المالية حول العالم العربي وزيادة الاهتمام
            بالعملات المشفرة وتكنولوجيا التداول المالي في الشرق الأوسط.
          </p>

          <p class="has-text-align-right">
            كما يسعى من خلال خدماته التي سيُطلقها للجمهور إلى تطوير كافة الخدمات
            المصرفية في العام 2023 في إطار خطة التحول الرقمي التي تتواصل بنجاح
            كبير. ‏البنك الذي أعلن عن تفعيل خدماته خلال هذا العام سيسمح
            للمستخدمين بـإيداع الأموال بجميع فئاتها النقدية المختلفة{" "}
            <strong>“الدولار، اليورو، الباوند،…. إلخ”</strong>، ومعها الكثير من
            العملات العربية والعالمية. وكذلك فهو يتيح بـتحويلها من حساب لآخر، أو
            إلى أي مكان في العالم. بلا أي حدود، وبِحُريّة مُطلقة، ومن خلال بيئة
            مالية رقمية آمنة. كما يمكن للمستخدم استخراج بطاقة صراف آلي صادرة من
            بنك الخليج الرقمي، يستطيع من خلالها المُستخدم سحب الأموال النقدية من
            رصيده من أي صرّاف آلي موجود في العالم. علاوةً على إجراء كافة
            المشتريات من أي محل أو مركز تجاري، والشراء أونلاين على كافة المواقع
            والمتاجر الإلكترونية.
          </p>

          <h3 class="wp-block-heading has-text-align-right">
            <strong>
              وقد أكّد المستشار هاني الأصفر – المدير التنفيذي للمشروع – في حوار
              مباشر معه
            </strong>
          </h3>

          <p class="has-text-align-right">
            أن{" "}
            <a
              href="https://gulfcryptobankshares.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              العالًم الرقمي
            </a>{" "}
            وعالم العُملات المشفرة بات أمراً واقعاً لا يمكن تجاهله، وسوق مالي
            ضخم مؤثر في المعادلة المالية والاقتصادية الدولية، وبالتالي أهمية
            الحاجات لمثل هذه المشاريع. حيث صرّح أن البنك سيكون جاهزاً لإطلاق
            خدماته الكاملة سواء النقدية أو الرقمية في هذا العام، بعد انتهاء
            واكتمال اكتتاب طرح الأسهم، وأنه سيكون من حق المستخدمين استخدام
            المنصة الخاصة للبنك لإجراء المعاملات البنكية والرقمية المختلفة.
          </p>

          <p class="has-text-align-right">
            <a
              href="https://gulfcryptobankshares.com/"
              target="_blank"
              rel="noreferrer noopener"
            ></a>
          </p>
        </Wrapper>
      </NewsWrapper>
    </>
  );
}
