import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import LanguageCheck from '../langcheck';
export default function KycSuccess() {
    const { t } = useTranslation();
    const KycSuccess = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    border: 1px solid #11CABE;
    border-radius: 14px;
    gap: 6px;
    font-weight: 500;
    padding: 8px 24px;
    font-size: 14px;
    direction: ${({ directon }) => directon};
    line-height: 26px;
    `


    const direction = LanguageCheck() === 'ar' ? 'ltr' : 'rtl';

    return (
        <KycSuccess id='kycsuccess' directon={direction} >
            <img src='/assets/icons/tick.svg' alt='kyc' />
            {t('kycsuccess')}
        </KycSuccess>
    )
}
