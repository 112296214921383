import React, { useRef, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import CircleDate from "./CircleDate";
import { Tooltip } from "antd";
import WindowSize from "../WindowSizer";
import { SInput } from "./styled";
import GulfvsDolar from "./GulfvsDolar";
import { formatNumber } from "../FormatNumber";
import { DirectionContext } from "context/direction";
import Shareprice from "./Shareprice";
import { PriceContext } from "context/prices";

const IconWrapper = styled.div`
  .ant-tooltip-open::before {
    content: "${(props) => props.about}" !important;
    color: #fff;
    position: relative;
    font-weight: 800;
    white-space: nowrap;
    top: 30px !important;
    font-size: 18px;
    line-height: 160%;
    z-index: 2 !important;
  }
`;
const CalculatInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1px 2fr;
  border-bottom: 1px solid #9397ab;
  text-align: left;
  direction: ltr !important;
  gap: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #fff;

  input {
    background-color: transparent;
    outline: none;
    border: none;
    color: #fff;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 18px;
    max-height: 34px !important;

    width: 100%;
  }

  div {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    gap: 8px;
    grid-template-columns: 3fr 1px 4fr;
  }
`;
const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e1f25;
  text-align: right;
  padding: 2.5vh 2vw;
  border-radius: 12px;
  width: 30vw;
  min-width: 300px;
  max-width: 500px;
  height: auto;
  min-height: 520px;
  font-size: 1.25vw;
  @media (max-width: 1024px) {
    padding: 22px 20px 32px 20px;
    min-width: 300px;
    margin: 0px 24px !important;
    width: 78vw;
  }
`;

const Subtitle = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #ffffff;
  text-align: ${(props) => props.align};
  margin: 20px 0px 6px 0px;
  @media (max-width: 1024px) {
    margin: 0px 2px 6px 0px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  margin: 24px 0px 16px 0px;
  place-self: center;
`;
const Divider = styled.div`
  position: relative !important;
  width: 1px;
  height: 80%;
  max-height: 34px !important;
  background-color: #9397ab;
`;
const CircleWrapper = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  direction: ltr !important;
  & :last-child {
    border-color: #ffad32;

    & div:last-child {
      color: #ffad32;
    }
  }
  @media (max-width: 1024px) {
    gap: 12px;
  }
`;
const Box = styled.div`
  display: flex;
  color: #fff;
  justify-content: space-between;
  font-size: 24px;
  text-align: ${(props) => props.about};

  p {
    margin: 0px !important;
    img {
      width: 1rem;
      height: 1rem;
    }
  }
  img {
    width: 100px;
    height: 100px;
  }
  font-weight: 700;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
const TextContainer = styled.div`
  font-weight: 700;
  position: relative;
  overflow: hidden;
  direction: ltr !important;
  font-size: 25px;
  color: #fff;
  text-align: right;
  margin: 15px 0px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${(props) => props.textAlign};
  width: ${(props) => (props.width === "left" ? "70%" : "unset")};

  @media (max-width: 1024px) {
    font-size: 20px;
  }
  span {
    color: #d71159;
  }
`;
const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  grid-column: 1/3;
  white-space: nowrap;
`;

export default function Calculator() {
  const { t } = useTranslation();
  const [selectValue, setSelectValue] = useState(30000);
  const [inputValue, setInputValue] = useState(10000);
  const [goldText, setGoldText] = useState("");
  const { i18n } = useTranslation();
  const isMobile = WindowSize();
  const inputRef = useRef();
  const [botToolTip, setBotToolTip] = useState();
  const [textAlign, setTextAlign] = useState();
  // const direction = LanguageCheck() === 'ar' ? 'right' : 'left';
  const { direction, align, rightLeft } = useContext(DirectionContext);
return;
  useEffect(() => {
    // to change the text of the gold image hover
    i18n.language === "ar"
      ? setGoldText("كبار المستثمرين")
      : setGoldText("Big investors");
    // to change the text of bottom tooltip
    i18n.language === "ar"
      ? setBotToolTip("المبلغ المستثمر")
      : setBotToolTip("Invested amount");
    // to change price info text align
    i18n.language === "ar" ? setTextAlign("right") : setTextAlign("left");
  }, [i18n.language]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      inputRef.current.blur();
    }
  };

  const [result, setResult] = useState(300);
  const [monthly, setMonthly] = useState(0);
  var [dollarsValue, setDolarsValue] = useState(0);
  var [sharesValue, setSharesValue] = useState(0);
  //var dollarsValue;
  const [gulfcoinPrice, setGulfcoinPrice] = useState(0.05);
  const { sharePrice } = useContext(PriceContext);
  useEffect(() => {
    if (sharePrice !== null) {
      setGulfcoinPrice(sharePrice);
    }
  }, [sharePrice]);

  function calculateValue(shares, transactions) {
    // The value of each share is determined by the logarithmic function
    // y = a * log(x) + b, where a and b are constants

    // a is for steep if this value is high the curve will be more steep
    //const a = shares * 2;
    let dollars = shares;
    dollarsValue = dollars; // Math.pow(dollars,3) ;
    setDolarsValue(dollarsValue);

    shares = dollarsValue / gulfcoinPrice;
    setSharesValue(shares);
    const valuePerShare = shares; //* Math.exp(2);
    //console.log("dollars = "+dollars);
    //console.log("shares = "+shares);
    //console.log("valuePerShare = "+valuePerShare);
    //console.log("(valuePerShare/1000000000000) = "+(valuePerShare/1000000000000));

    // The total value is equal to the value of each share multiplied by the number of transactions

    let result = (valuePerShare / 1000000000) * transactions * 10;
    //daly.setValue("New Value");
    //.innerHTML=result;
    return result;
  }

  const [inputValueDollars, setDollarsInput] = useState("");

  useEffect(() => {
    setResult(calculateValue(Number(selectValue), inputValue));
    setMonthly(result * 30);
  }, [
    inputValue,
    selectValue,
    monthly,
    setSelectValue,
    setInputValue,
    setMonthly,
    result,
  ]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setResult(calculateValue(inputValueDollars, inputValue));
    //console.log(result);
    setMonthly(result * 30);
  };

  const [isAr, setIsAr] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("selectedLanguage") === "ar") {
      setIsAr(true);
    } else {
      setIsAr(false);
    }
  }, []);

  return (
    <CalculatorContainer>
      <>
        <Box about={textAlign}>
          <GulfvsDolar />
          <Shareprice />
        </Box>

        <IconWrapper about={botToolTip} className="icon-wrapper">
          <img
            src="/assets/icons/golds.svg"
            alt="limitation"
            style={{
              maxWidth: 100,
              position: "relative",
              top: 64,
              right: "35%",
            }}
          />
          <Slider
            min={250}
            max={100000}
            onChange={(val) => setSelectValue(val)}
            defaultValue={selectValue}
            tooltip={{
              formatter: (selectValue) => `${formatNumber(dollarsValue)}$`,
            }}
          />
          <Tooltip placement="leftTop" title={"gokhan"}></Tooltip>

          <Tooltip title={goldText}>
            <img
              className="anticon"
              src="/assets/icons/gold.png"
              alt="limitation"
              style={{ maxWidth: 100, marginRight: 6 }}
            />
          </Tooltip>
        </IconWrapper>
        <Subtitle align={rightLeft}>{t("calc2")}</Subtitle>
        <CalculatInputWrapper>
          <SInput
            id="inputValueDollars"
            name="inputValueDollars"
            type="number"
            //key="password"
            value={selectValue}
            onChange={(val) => setSelectValue(val.target.value)}
          />

          <Divider />
          <div>{t("$")}</div>
        </CalculatInputWrapper>

        <Subtitle align={rightLeft}>{t("calc1")}</Subtitle>
        <CalculatInputWrapper>
          <SInput
            id="inputValue"
            name="inputValue"
            type="number"
            key="password"
            ref={inputRef}
            value={inputValue}
            autoFocus={false}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
          />

          <Divider />
          <div>{t("calcday")}</div>
        </CalculatInputWrapper>

        {i18n.language === "ar" ? (
          <TextContainer width={rightLeft} textAlign={rightLeft}>
            باستثمار
            {inputValue === "" ? (
              <span> $0 </span>
            ) : (
              <span> {formatNumber(dollarsValue, isAr)}$ </span>
            )}
            ستحصل على
            <span> {formatNumber(sharesValue, isAr)} </span>
            أسهم
          </TextContainer>
        ) : (
          <TextContainer width={rightLeft} textAlign={rightLeft}>
            By Investing <span> {formatNumber(dollarsValue)}$</span> <br /> you
            will get <span>{formatNumber(sharesValue)}</span> shares
          </TextContainer>
        )}

        <Title>{t("calcBottom")}</Title>
        <CircleWrapper>
          <CircleDate
            id="calcDay"
            string={t("calcDay")}
            value={formatNumber(monthly / 30)}
          />
          <CircleDate string={t("calcMonth")} value={formatNumber(monthly)} />
          <CircleDate
            string={t("calcYear")}
            value={formatNumber(monthly * 12)}
          />
        </CircleWrapper>
      </>
    </CalculatorContainer>
  );
}
