import React, { createContext, useState, useEffect } from "react";
import config from "../config";
import $ from "jquery";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState({
    name: "",
    lastname: "",
    country: "",
    id: "",
    shares: "",
    avatar: "",
  });
  const [userAvatar, setUserAvatar] = useState();
  const imgVersion =
    localStorage.getItem("imgVersion") ||
    localStorage.setItem("imgVersion", "1");

  useEffect(() => {
    $.ajax({
      type: "get",
      url: config.AVATAR_EXIST,
      data: {
        fileName: localStorage.getItem("id") + ".jpg",
        token: localStorage.getItem("token"),
      },
      success: function (response) {
        // DEFAULT AVATAR
        if (response.status === "error") {
          // console.log(response)
          setUserAvatar("/assets/icons/avatar.svg");
        } else {
          // USER AVATAR FROM SERVER
          setUserAvatar(
            config.AVATAR_COMPRESSED2 +
              "?filename=" +
              localStorage.getItem("id") +
              ".png?imgVersion=" +
              imgVersion
          );
        }
      },
    });
  }, []);

  useEffect(() => {
    setUser({
      name: localStorage.getItem("name"),
      lastname: localStorage.getItem("lastname"),
      shares: localStorage.getItem("shares"),
      id: localStorage.getItem("id"),
      avatar: userAvatar,
    });
  }, [setUserAvatar, userAvatar]);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};
