import React, { useState, useContext, useEffect, useRef } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { StyledInput, StyledTextArea, SButton, SInput } from "./styled";
import { DirectionContext } from "../context/direction";
import $ from "jquery";
import config from "../config";
import countries from "../countrycodes.json";
import DragDrop from "./dashboard/DragDrop";
import { NewApiContext } from "context/newapi";
import { SettingContext } from "context/modals";

const Selector = styled.select`
  background: #1d1f25;
  border: 1.5px solid #34384c;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  padding: 7px 6px;
  width: 100%;

  option {
    color: #5d6588;
  }

  :focus {
    outline: none;
  }
  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }
  ::placeholder {
    color: #5d6588;
  }
  @media (max-width: 1024px) {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;
const Svg = styled.img`
  position: relative;
  left: 70px;
  top: 70px;

  @media (max-width: 1024px) {
    left: 80px;
    top: 80px;
  }
`;
const StyledDate = styled(StyledInput)`
  @media (max-width: 576px) {
    display: block;
    padding: 4px 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 28px !important;
  }
`;
const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-family: "Cairo", sans-serif;
  background-color: transparent !important;
  border: 1px solid #425166 !important;
  border-radius: 100px !important;
  padding: 20px 32px !important;
  font-weight: 700;
  font-size: 18px;
  box-shadow: none !important;
  margin-top: 8px;
`;
const StyledModal = styled(Modal)`
  .ant-modal-title {
    display: none !important;
  }
  padding: 0 !important;

  .ant-modal-header {
    margin: 0 !important;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 12px;
  color: #fff;

  @media (max-width: 1024px) {
    padding: 32px 14px;
  }
`;
const FormWrapper = styled.form`
  display: grid;
  grid-template-rows: 1fr 0.2fr 0.9fr 0.2fr 0.2fr 0.2fr 0.2fr;
  grid-gap: 12px;
  width: 100%;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${(props) => props.align};
  textarea {
    overflow: hidden;
    resize: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  gap: 4px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }
`;

const VerifyItem = styled(Item)`
  input {
    width: 88%;
  }
  img {
    position: absolute;

    ${(props) =>
      props.align === "right"
        ? `margin: 10px -28px 0px 6px;`
        : `margin: 10px 30px 0px -28px;`}
  }
`;

const HalfGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(120px, 1fr) minmax(0, 1fr);
  gap: 20px;
  &:last-child {
    margin-top: 14px;
  }
  @media (max-width: 1024px) {
  }
`;

// it makes positioning of the input fields and icons verify
const VerifyHalf = styled(HalfGrid)`
  grid-template-columns: minmax(120px, 2fr) minmax(0, 1fr);
  gap: 8px;
  direction: ${(props) => props.direction};
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 24px;
  text-align: center;
`;
const EmailSent = styled.div`
  color: #ef890f;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  direction: ${(props) => props.direction};
`;
const PhoneInput = styled(StyledInput)`
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-radius: 0px 8px 8px 0px;
  :hover {
    transition: 0.2s;
    border-color: #34384c;
  }
  @media (max-width: 1024px) {
    padding: 6px 4px;
  }
`;

const Dial = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

const PhoneWrapper = styled.div`
  display: flex;
  border: 1.5px solid #34384c;
  place-items: center;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 8px;
  background: #1d1f25;
  padding: 0px 6px 0px 0px;
  justify-content: space-between;

  img {
    position: absolute;

    ${(props) =>
      props.align === "right"
        ? `
        margin: 10px 30px 10px -30px;
        right:4px;
        `
        : `margin: 10px 30px 10px -30px;`}
  }
  div:first-child {
    margin: 0px 6px;
  }

  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }
  input {
    width: 50%;
  }
  ${(props) =>
    props.isEmailNotif === true
      ? `:hover {
      transition: 0.2s;
    border-color: rgba(17, 202, 190);
  }
  `
      : `:hover {
  transition: 0.2s;
  border-color: #EF890F;
  `}

  @media (max-width: 1024px) {
    font-size: 12px !important;

    div:first-child {
      margin-left: 10px;
    }

    img {
      ${(props) =>
        props.align === "right"
          ? `
          margin: 10px 4px 10px -30px;
          `
          : `margin: 10px 30px 10px -30px;`}
    }
  }
`;
const IdProofWrapper = styled(PhoneWrapper)`
  display: flex;
  padding: 6px 2px;
  input {
    width: 88%;
  }
  ${(props) =>
    props.direction === "right"
      ? `justify-content: flex-end;
`
      : `justify-content: flex-start;`}

  img {
    position: absolute;

    ${(props) =>
      props.direction === "right"
        ? `margin: 10px -28px 0px 0px;
     
      
  `
        : `margin: 10px 30px 10px -28px;`}
  }

  ${(props) =>
    props.isEmailNotif === true
      ? `:hover {
      transition: 0.2s;
    border-color: rgba(17, 202, 190);
  }
  `
      : `:hover {
  transition: 0.2s;
  border-color: #EF890F;
  `}
`;
const ImageWrapper = styled.div`
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;

  @media (max-width: 1024px) {
    max-width: 110px;
    min-height: 110px;
  }
`;
const Mask = styled.div`
  display: none;
  z-index: 10000;

  ${(props) =>
    props.imageShow === true &&
    `
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  transition: 0.2s;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;

  img {
    border-radius: 12px;
    max-width: 800px;

  }

@media (max-width: 1024px) {

  img {
    width: 90%;
  }

}

  `}
`;
const FileInput = styled.label`
  display: inline-block;
  cursor: pointer;
  background: #fff;
  position: relative;
  width: 0%;
  height: 0%;
  input[type="file"] {
    display: none;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #4caf50;
  }
`;
const VerifyButton = styled.button`
  background: #ef890f;

  ${(props) =>
    props.isEmailNotif === true &&
    `
  background: rgba(17, 202, 190, 0.2);
  `}
  border: none;
  border-radius: 16px;
  color: #fff;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    opacity: 0.9;
  }
`;

const VerifyInput = styled(StyledInput)`
  ${(props) =>
    props.isEmailNotif === true
      ? `:hover {
      transition: 0.2s;
    border-color: rgba(17, 202, 190);
  }
  `
      : `:hover {
  transition: 0.2s;
  border-color: #EF890F;
  `}
`;

const IDTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  border-radius: 16px;
  padding: 4px 16px;
  ${(props) =>
    props.isEmailNotif === true
      ? `
  color: #96FFF8;
  background: rgba(17, 202, 190, 0.2);
  
  `
      : `
  color: #FFDEB7;
  background: #EF890F;

  `}

  @media (max-width: 450px) {
  }
`;
export default function SettingsModal({
  isEmailNotif,
  isKycVerified,
  isPhoneVerified,
  fileExist,
  setKyc,
}) {
  const user = useContext(NewApiContext);
  const { isSettingOpen, setSettingIsOpen } = useContext(SettingContext);
  const { t } = useTranslation();
  const [isMailSent, setIsMailSent] = useState(false);
  const fileInputRef = useRef(0);
  const [selectedCountry, setSelectedCountry] = useState(user.user.country);
  const { rightLeft, direction } = useContext(DirectionContext);
  const [dialCode, setDialCode] = useState();
  const [imageShow, setImageShow] = useState(false);
  const imgVersion = user.user.imgVersion;
  const [idStatus, setIdStatus] = useState({
    status: Boolean,
    image: "/assets/icons/verified.svg",
    text: t("kycuploaded"),
  });
  const [avatar, setAvatar] = useState(user?.user.avatar + imgVersion);

  const [form, setForm] = useState({
    id: user.user.id,
    bio: user.user.bio,
    occupation: user.user.occupation,
    birthday: user.user.birthday,
    country: selectedCountry,
    phone2: user.user.phone,
    personal_address: user.userpersonal_address,
    fileToUpload: File,
  });

  useEffect(() => {
    setAvatar(user?.user.avatar + "?imgVersion=" + imgVersion);
  }, [user]);

  const SendEmail = (event) => {
    event.preventDefault();
    if (isEmailNotif === true) {
      return false;
    } else {
      $.ajax({
        type: "post",
        url: config.SEND_EMAIL,
        data: {
          email: localStorage.getItem("email"),
          token: localStorage.getItem("token"),
        },
        success: function (resp) {
          setIsMailSent(true);
        },
      });
    }
  };

  useEffect(() => {
    setForm({
      id: user.user.id,
      bio: user.user.bio,
      occupation: user.user.occupation,
      birthday: user.user.birthday,
      country: user.user.country,
      phone2: user.user.phone,
      personal_address: user.user.personal_address,
      fileToUpload: File,
    });
  }, [user]);

  useEffect(() => {
    setTimeout(() => {
      if (
        form.country &&
        countries[form.country] &&
        countries[form.country].dial_code !== undefined
      ) {
        setDialCode(countries[form.country].dial_code);
      }
    }, 1000);
  }, [form.country]);

  const showModal = () => {
    setSettingIsOpen(true);
  };
  const handleOk = () => {
    setSettingIsOpen(false);
  };

  const handleCancel = () => {
    setSettingIsOpen(false);
  };

  const [changed, setChanged] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
        setChanged(file);
      };
      reader.readAsDataURL(file);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    var fileInput = document.getElementById("fileToUpload");
    var file = fileInput.files[0];
    var formData = new FormData();

    formData.append("fileToUpload", changed);
    formData.append("fileName", localStorage.getItem("id") + ".png");
    formData.append("bio", form.bio);
    formData.append("occupation", form.occupation);
    formData.append("birthday", form.birthday);
    formData.append("country", selectedCountry);
    formData.append("phone2", form.phone2);
    formData.append("personal_address", form.personal_address);
    formData.append("id", localStorage.getItem("id"));
    formData.append("token", localStorage.getItem("token"));
    formData.append("submit", "submit");

    fetch(config.USER_UPDATE, {
      method: "POST",
      body: formData,
      token: localStorage.getItem("token"),
    })
      .then((response) => response.json())
      .then((data) => {
        $.ajax({
          url: config.INC_IMGVERSION + "?id=" + localStorage.getItem("id"),
          type: "get",

          success: function (response) {
            setTimeout(() => {
              window.location.reload();
            }, 1100);
          },
          error: function (error) {
            console.error("Error fetching data:", error);
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    console.log(selectedCountry);
    setDialCode(countries[event.target.value].dial_code);
  };

  useEffect(() => {
    // isKycVerified ise verified göster
    isKycVerified
      ? setIdStatus({
          status: true,
          image: "/assets/icons/verified.svg",
          text: "verified",
        })
      : // isKycVerified değilse fileExist kontrol et
      fileExist
      ? setIdStatus({
          status: false,
          image: "/assets/icons/refresh.svg",
          text: "kycuploaded",
        })
      : setIdStatus({
          status: false,
          image: "/assets/icons/yellowarn.svg",
          text: "kycnotuploaded",
        });
  }, [setIdStatus, isKycVerified, fileExist]);

  return (
    <>
      <Mask onClick={() => setImageShow(!imageShow)} imageShow={imageShow}>
        <img src={avatar} alt="profile-settings" />
      </Mask>
      <StyledButton type="primary" onClick={showModal}>
        <img src="/assets/icons/edit.svg" alt="profile-settings" />
        {t("profilesettings")}
      </StyledButton>

      <StyledModal
        title="Basic Modal"
        open={isSettingOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ModalContent>
          <FormWrapper enctype="multipart/form-data" onSubmit={handleSubmit}>
            <div>
              <Title>{t("settings.modal.title")}</Title>

              <ImageWrapper
                image={avatar}
                imageShow={imageShow}
                onClick={() => setImageShow(!imageShow)}
              >
                <FileInput>
                  <Svg src="/assets/icons/editpp.svg" />
                  <input
                    ref={fileInputRef}
                    type="file"
                    name="fileToUpload"
                    id="fileToUpload"
                    onChange={handleFileChange}
                  />
                </FileInput>
              </ImageWrapper>
            </div>
            <div style={{ textAlign: "center", fontSize: 20, fontWeight: 700 }}>
              {user.user.name + " " + user.user.lastname}
            </div>
            {!fileExist || !isKycVerified ? (
              <DragDrop
                setKyc={setKyc}
                setIdStatus={setIdStatus}
                idStatus={idStatus}
                settings={true}
              />
            ) : null}

            <Item align={rightLeft}>
              <label>{t("settings.modal.1")}</label>
              <StyledTextArea
                direction={rightLeft}
                maxLength={190}
                name="bio"
                onChange={handleChange}
                value={form.bio !== "null" ? form.bio : ""}
                placeholder={t("settings.modal.1.placeh")}
              />
            </Item>
            <Item align={rightLeft}>
              <label>{t("settings.modal.2")}</label>
              <StyledInput
                type="text"
                onChange={handleChange}
                name="occupation"
                value={form.occupation}
                placeholder={t("settings.modal.2.placeh")}
                direction={rightLeft}
              />
            </Item>
            <Item id="quesadilla" align={rightLeft}>
              <label>{t("settings.modal.3")}</label>
              <StyledDate
                type="date"
                onChange={handleChange}
                name="birthday"
                value={form.birthday}
                placeholder={t("settings.modal.3.placeh")}
                direction={rightLeft}
              />
            </Item>
            <Item align={rightLeft}>
              <label>{t("settings.modal.4")}</label>
              <StyledInput
                type="text"
                onChange={handleChange}
                name="personal_address"
                value={
                  form.personal_address !== "null" ? form.personal_address : ""
                }
                placeholder={t("settings.modal.4.placeh")}
                direction={rightLeft}
              />
            </Item>
            <VerifyHalf direction={direction}>
              <VerifyItem align={rightLeft}>
                <label>{t("email")}</label>
                <div>
                  {!isEmailNotif ? (
                    <img src="/assets/icons/yellowarn.svg" alt="verifided" />
                  ) : (
                    <img src="/assets/icons/verified.svg" alt="verifided" />
                  )}
                  <VerifyInput
                    type="text"
                    isEmailNotif={isEmailNotif}
                    onChange={handleChange}
                    name="personal_address"
                    value={localStorage.getItem("email")}
                    placeholder={t("settings.modal.4.placeh")}
                    direction={rightLeft}
                    readOnly
                  />
                </div>
              </VerifyItem>
              <Item align={rightLeft}>
                <label> &nbsp; </label>
                <VerifyButton
                  isEmailNotif={isEmailNotif}
                  onClick={SendEmail}
                  type="primary"
                >
                  {!isEmailNotif ? t("sendmail") : t("verified")}
                </VerifyButton>
              </Item>
            </VerifyHalf>

            {isMailSent && (
              <EmailSent direction={direction}> {t("emailsent")} </EmailSent>
            )}

            <Item direction={direction}>
              <VerifyItem align={rightLeft}>
                <label>{t("settings.modal.6")}</label>
                <PhoneWrapper align={rightLeft} isEmailNotif={isPhoneVerified}>
                  {isPhoneVerified ? (
                    <img src="/assets/icons/verified.svg" alt="verifided" />
                  ) : (
                    <img src="/assets/icons/refresh.svg" alt="verifided" />
                  )}

                  <Dial>
                    <Dial>{dialCode}</Dial>

                    <PhoneInput
                      onChange={handleChange}
                      value={form.phone2}
                      name="phone2"
                      placeholder={t("settings.modal.6.placeh")}
                    />
                  </Dial>
                  <IDTag isEmailNotif={isPhoneVerified}>
                    {isPhoneVerified ? t("verified") : t("underverif")}
                  </IDTag>
                </PhoneWrapper>
              </VerifyItem>
            </Item>
            <VerifyItem align={rightLeft}>
              <label>{t("kycproof")}</label>
              <div>
                <img src={idStatus.image} alt="verifided" />
                <IdProofWrapper
                  isEmailNotif={idStatus.status}
                  direction={rightLeft}
                >
                  <IDTag isEmailNotif={idStatus.status}>
                    {t(idStatus.text)}
                  </IDTag>
                </IdProofWrapper>
              </div>
            </VerifyItem>
            {idStatus.image === "/assets/icons/refresh.svg" && (
              <EmailSent direction={direction}>
                {" "}
                {t("waitforverify")}{" "}
              </EmailSent>
            )}

            <Item>
              <Item align={rightLeft}>
                <label>{t("settings.modal.5")}</label>
                <Selector
                  id="country"
                  name="country"
                  value={user.country}
                  onChange={handleCountryChange}
                  placeholder="المغرب"
                >
                  <option value={form.country}>{form.country}</option>

                  {Object.keys(countries).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Selector>
              </Item>
            </Item>

            <HalfGrid>
              <SButton transparent type="primary" onClick={handleOk}>
                {t("settings.modal.button.1")}
              </SButton>
              <SButton type="submit">{t("settings.modal.button.2")}</SButton>
            </HalfGrid>
          </FormWrapper>
        </ModalContent>
      </StyledModal>
    </>
  );
}
