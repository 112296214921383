import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { StyledLink } from './styled';

const BackHomeW = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:transparent;
    border:1px solid #D71159;
    position: absolute;
    top: 55px;
    padding: 8px 24px;
    right: 50px;
    gap:6px;
    color:#fff;
    border-radius:24px;
    font-size:18px;
    direction:rtl; 
    img {
        transform:rotate(180deg);
    }

    :hover {
       opacity:0.8;
    }

    @media (max-width: 1024px) {
        font-size:14px;
        right: 30px;
    }
    `
export default function BackHome() {
    const { t } = useTranslation();
    
    
    return (
        <StyledLink to={"/"}>
            <BackHomeW>
                <img src='/assets/icons/backarrow.svg' alt='gulf-crypto' />
                {t('backhome')}
            </BackHomeW>
        </StyledLink>

    )
}
