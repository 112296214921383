import React, { useState, useEffect, createContext } from "react";

export const ModalContext = createContext();

export const ModalProvider = (props) => {
  const [modal2Open, setModal2Open] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const myParam = queryParams.get("openModal");
    if (myParam === "true") {
      setModal2Open(true);
    }
  }, []);

  const value = { modal2Open, setModal2Open };

  return (
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  );
};

export const SettingContext = createContext();

export const SettingModalProvider = (props) => {
  const [isSettingOpen, setSettingIsOpen] = useState(false);
  const value = { isSettingOpen, setSettingIsOpen };

  return (
    <SettingContext.Provider value={value}>
      {props.children}
    </SettingContext.Provider>
  );
};
