import React from "react";
import styled from "styled-components";
const Circle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 6px;
  background-color: transparent;
  border: 1px solid #fa2256;

  div {
    &:first-child {
      color: #fff;
      font-weight: 700;
      font-size: 18px;
    }
    &:last-child {
      font-weight: 700;
      font-size: 26px;
      line-height: 100%;
    }
    color: #fa2256;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    width: 80px;
    height: 80px;
    div {
      &:first-child {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
      }
      &:last-child {
        font-weight: 700;
        font-size: 19px;
        line-height: 100%;
      }
      color: #fa2256;
      font-weight: 700;
    }
  }
`;

export default function CircleDate({ string, value }) {
  return (
    <Circle>
      <div>{string}</div>
      <div>{value}</div>
    </Circle>
  );
}
