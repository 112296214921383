import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { StyledLink } from './styled';

export default function CheckMail() {
  const { t } = useTranslation();
  const PwWrapper = styled.div`
        display:flex;
        flex-direction:column;
        color: #FFFFFF;
        text-align:center;
        justify-content:center;
        align-items:center;
        gap: 20px;
        div:nth-child(2){
            font-size: 30px;
            font-weight: 400 !important;
        }
        div:nth-child(3){
            font-weight: 500 !important;
            font-size: 20px;
        }

        img {
            max-width: 200px;
            margin-left:18px;
        }
        @media (max-width: 1024px) {
            padding: 0px;
            gap:20px;
            div:nth-child(2){
              font-size: 24px;
              font-weight: 400 !important;
          }
  
  
          div:nth-child(3){
              font-weight: 500 !important;
              font-size: 16px;
          }
        }
          `

  const ButtonBox = styled.div`
  border:1px solid #D71159;
  display:flex;
    justify-content:center;
    align-items:center;
  position:relative;
  background-color: #D71159;
  color: #fff;
  width: 100%;
  font-size:18px;
  padding:10px 54px;
  font-weight:800;
  border-radius:30px;
  cursor:pointer;
  &:hover {
    opacity:0.8;
  }
  
  @media (max-width: 1024px) {
    white-space: nowrap;
    width: auto;
    font-weight: 800;
    font-size: 18px;
        line-height: 160%;
      }
        
        `



  return (
    <PwWrapper>
      <img src="/assets/checkmail.svg" alt='success' />
      <div>   {t('checkemail')}</div>
      <div>{t('emailsended')}</div>
      <ButtonBox>
        <StyledLink to="/login">
          {t('backtologin')}
        </StyledLink>

      </ButtonBox>
    </PwWrapper>
  )
}
