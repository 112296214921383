import React, { useState } from 'react';
import { Skeleton, Space } from 'antd';
import styled from 'styled-components';


const SkeletonWrapper = styled.div`
background-color:#1E1F25;;
border-radius: 14px;
gap:14px;
padding:50px;
div{
    display:flex;
    
}
`

export default function TableSkeleton() {

    const [active, setActive] = useState(false);
    const [block, setBlock] = useState(true);
    const [size, setSize] = useState('large');



    return (
        <SkeletonWrapper>

            <div>
                <Skeleton.Button active={active} size={size} block={block} />
                <Skeleton.Avatar active={active} size={size} shape={'circle'} />
            </div>




            <br />
            <div>
                <Skeleton.Button active={active} size={size} block={block} />
                <Skeleton.Avatar active={active} size={size} shape={'circle'} />
            </div>

            <br />
            <div>
                <Skeleton.Button active={active} size={size} block={block} />
                <Skeleton.Avatar active={active} size={size} shape={'circle'} />
            </div>
            <br />
            <div>
                <Skeleton.Button active={active} size={size} block={block} />
                <Skeleton.Avatar active={active} size={size} shape={'circle'} />
            </div>
            <br />
            <div>
                <Skeleton.Button active={active} size={size} block={block} />
                <Skeleton.Avatar active={active} size={size} shape={'circle'} />
            </div>



        </SkeletonWrapper>

    )
}


