import React, { useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../../langcheck";
import axios from "axios";
import { formatNumber } from "../../FormatNumber";
import config from "../../config";
import { UserContext } from "../../context/user";
import $ from "jquery";
import { NewApiContext } from "context/newapi";
const NumberShares = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  div {
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
  img {
    max-width: 26px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  direction: ${(props) => props.dir};
  gap: 16px;
  img {
    max-width: 18px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const UserInvest = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    place-items: center;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  gap: 6px;
  img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
`;

const Persona = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  gap: 2px;
  line-height: 120%;
  color: #fff;
  div:last-child {
    color: #848ba9;

    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }
`;

const Flex = styled.div`
  display: flex;
  place-items: flex-start;
  gap: 10px;
  @media (max-width: 630px) {
    flex-direction: column;
    place-items: center;
    text-align: center;
  }
`;
const Avatar = styled.div`
  display: flex;
  width: 60px;
  max-width: 100px;
  height: 60px;
  max-height: 100px;
  background-image: url("${(props) => props.avatar}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`;
const FileInput = styled.label`
  display: inline-block;
  cursor: pointer;
  background: #fff;
  position: relative;
  width: 0%;
  height: 0%;
  input[type="file"] {
    display: none;
  }

  svg {
    width: 12px;
    height: 12px;
    fill: #4caf50;
  }
`;
const Svg = styled.img`
  position: relative;
  left: 52px;
  ${(props) => (props.direction === "rtl" ? "left: -10px;" : "")}
  top: 28px;

  @media (max-width: 630px) {
    left: 12px;
    ${(props) => (props.direction === "rtl" ? "left: 30px;" : "")}
    top: 80px;
  }
`;
export default function KidsProfile({ kid, father }) {
  const { t } = useTranslation();
  const name = localStorage.getItem("name");
  const lastname = localStorage.getItem("lastname");
  // const user = useContext(UserContext)
  // const id = localStorage.getItem('id');
  const direction = LanguageCheck() === "ar" ? "rtl" : "ltr";

  const fileInputRef = useRef(0);

  function handleSubmit(e) {
    e.preventDefault();
    const fileInput = fileInputRef.current;
    const file = e.target.files[0];
    var formData = new FormData();

    formData.append("fileToUpload", file);
    formData.append(
      "fileName",
      localStorage.getItem("id") + "_" + kid.id + ".png"
    );
    formData.append("id", localStorage.getItem("id"));
    formData.append("token", localStorage.getItem("token"));
    formData.append("submit", "submit");

    fetch(config.AVATAR_UPLOAD, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        $.ajax({
          url: config.INC_IMGVERSION + "?id=" + localStorage.getItem("id"),
          type: "get",

          success: function (response) {
            setTimeout(() => {
              window.location.reload();
            }, 1100);
          },
          error: function (error) {
            console.error("Error fetching data:", error);
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <InfoContainer dir={direction}>
      <Flex>
        <FileInput>
          <Svg direction={direction} src="/assets/icons/editpp.svg" />
          <input
            ref={fileInputRef}
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            onChange={handleSubmit}
          />
        </FileInput>
        <AvatarWrapper>
          <Avatar avatar={kid.img + "?imgVersion=" + father.user.imgVersion} />
        </AvatarWrapper>

        <UserInvest>
          <Persona>
            <div>{kid.name}</div>
            <div>{kid.birthday}</div>
          </Persona>

          <NumberShares>
            <div>
              {t("numberofshares")}
              <div style={{ color: "#fff" }}>{formatNumber(kid.shares)}</div>
            </div>
          </NumberShares>
        </UserInvest>
      </Flex>
    </InfoContainer>
  );
}
