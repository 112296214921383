import React, { useState, useEffect } from 'react';
import { Skeleton, Space } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import WindowSize from '../WindowSizer';
export default function InputSkeleton() {
    const isMobile = WindowSize();
    const [active, setActive] = useState(false);
    const [block, setBlock] = useState(false);
    const [size, setSize] = useState();

    useEffect(() => {

        isMobile ? setSize('small') : setSize('large')

    }, [isMobile])


    const { t } = useTranslation();
    const Grid = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    gap:0px 16px;
    font-size:14px;

`
    const Flex = styled.div`
display:flex;
flex-direction:column;
line-height:35px;
.ant-skeleton{
    place-self:flex-end;
    margin:0px !important;
}
`

    return (
        <>
            <Grid>
                <Flex>

                    {t('modalamount')}
                    <Skeleton.Input active={active} size={size} />
                </Flex>

                <Flex>
                    {t('shares')}
                    <Skeleton.Input active={active} size={size} />
                    <Skeleton.Input active={active} size={size} />
                </Flex>

            </Grid>
        </>

    )
}


