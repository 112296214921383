import React from "react";
import styled from "styled-components";
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url("../assets/${(props) => props.image}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 315px;
  aspect-ratio: 2/3;

  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  color: #fff;
  font-size: 34px;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;

  :hover {
    -webkit-transition: 0.7s ease;
    -moz-transition: 0.7s ease;
    -o-transition: 0.7s ease;
    background-image: url("../assets/${(props) => props.hover}");
  }
  div {
    margin-bottom: 36px;
    margin-right: 28px;
    width: 80%;
  }

  @media (max-width: 1024px) {
    :hover {
      transition: 0.7s ease;
      background-image: url("../assets/${(props) => props.image}");
    }
    div {
      margin-bottom: 12px;
      margin-right: 28px;
      width: 80%;
      font-weight: 800;
      font-size: 24px;
      line-height: 117.5%;
    }
  }
`;
export default function Card({ image, hover }) {
  return <CardWrapper hover={hover} image={image} />;
}
