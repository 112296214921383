import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WindowSize from 'WindowSizer';
export const DirectionContext = createContext();

export const DirectionProvider = (props) => {
    const { i18n } = useTranslation();
    const [direction, setDirection] = useState();
    const [align, setAlign] = useState();
    const [rightLeft, setRightLeft] = useState();
    const isMobile = WindowSize();
    const [lang, setLang] = useState(i18n.language)

    useEffect(() => {
        setDirection(lang === 'ar' ? 'rtl' : 'ltr')
        setAlign(lang === 'ar' ? 'flex-end' : 'flex-start')
        setRightLeft(lang === 'ar' ? 'right' : 'left')
    }, [])

    // console.log("direction=" + direction, " align=" + align + " rightleft=" + rightLeft)




    const value = { direction, align, rightLeft , isMobile }

    return (
        <DirectionContext.Provider value={value}>
            {props.children}
        </DirectionContext.Provider>
    );
}