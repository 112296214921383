import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NewApiContext } from "context/newapi";

const VerifyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  background: ${(props) =>
    props.kyc ? "rgba(17, 202, 190, 0.2);" : "rgba(215, 17, 76 , 0.4);"};
  padding: 2px 10px;
  color: ${(props) => (props.kyc ? "#96FFF8;" : "#FFBAD3;")};
  font-weight: 700;
  line-height: 14px;
  font-size: 12px;
  border-radius: 11px;
`;

export default function Verified() {
  const [verified, setVerified] = useState();
  const { t } = useTranslation();
  const userData = useContext(NewApiContext);
  useEffect(() => {
    if (userData.verified === false) {
      setVerified(t("unverified"));
    } else {
      setVerified(t("verified"));
    }
  }, [userData]);

  return (
    <VerifyContainer kyc={userData.verified} id="verify">
      {verified}
    </VerifyContainer>
  );
}
