import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Buttons from "./Buttons";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import config from "../config";
import PersonalCheckPopup from "./PersonelCheckPopup";
import { NewApiContext } from "context/newapi";
const Container = styled.div`
  display: flex;
  position: fixed;
  color: #fff;
  top: 180px;
  justify-content: center;
  background-image: url("/assets/telegramdesktop.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-width: 700px;
  height: 436px;
  justify-content: flex-end;
  padding: 40px;
  z-index: 101;
  @media (max-width: 820px) {
    background-image: url("/assets/telegrammobile.png");
    min-width: 300px;
    max-width: 310px;
    height: 316px;
    padding: 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  img {
    width: 20px;
  }
  button:first-child {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 820px) {
    img {
      width: 16px;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
  }
`;
const StyledButton = styled.a`
  border: 1px solid #d71159;
  background-color: ${(props) =>
    props.transparent === true ? "transparent" : "#D71159"};
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-weight: 800;
  width: 100%;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  gap: 8px;
  img {
    width: 30px;
  }
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    font-weight: 800;
    line-height: 160%;
    padding: 4px 8px;
  }
`;
const Mask = styled.div`
  display: flex;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
  z-index: 100;
  width: 99vw;
  height: 100vh;
  top: 0px;
`;
export default function Telegrampopup() {
  const [show, setShow] = useState(true);
  const [shares, setShares] = useState("0");
  const isLogged = localStorage.getItem("token") !== null ? true : false;
  const { t } = useTranslation();
  let popup;
  const [hasShare, setHasShare] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const apiObj = useContext(NewApiContext);
  const userShare = apiObj.user.totalshares;

  useEffect(() => {
    if (!isLogged) {
      setShow(false);
    }
    if (userShare >= 1) {
      setShow(false);
    }
    if (isLogged) {
      popup =
        sessionStorage.getItem("popup") ||
        sessionStorage.setItem("popup", "true");
      if (popup === "true") {
        setShow(false);
      }
    }

    console.log(show);
  }, []);

  function handleShare() {
    setShow(!show);
    setShowWarning(!showWarning);
  }

  return (
    <>
      {show ? (
        <Wrapper>
          <Mask />

          <Container>
            <Flex>
              <button onClick={() => handleShare()}>
                <img src="/assets/icons/cross.svg" alt="x" />
              </button>
              <StyledButton
                target="_blank"
                href="https://t.me/+pEK7PkRa0QAxMDc0"
                transparent
              >
                <img src="/assets/icons/telegram.svg" alt="x" />

                {t("telegram.button")}
              </StyledButton>
            </Flex>
          </Container>
          {showWarning && <PersonalCheckPopup />}
        </Wrapper>
      ) : (
        <PersonalCheckPopup />
      )}
    </>
  );
}
