import React, { Component } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import Navbar from "./dashboard/Navbar";

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  padding: 0px 20px;
  text-align: center;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
    });
    // Log the error to your desired error tracking service
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="body">
          <Navbar />

          <ErrorWrapper>
            <Alert
              message="An error has occurred"
              description={`An unexpected error occurred in this part of the application. Please try again later or contact support if the issue persists. Error details: ${
                this.state.error && this.state.error.toString()
              }`}
              type="error"
              showIcon
            />
          </ErrorWrapper>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
