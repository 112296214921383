import React, { useContext, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { SButton, StyledInput } from "components/styled";
import { DirectionContext } from "context/direction";
import config from "config";
import { NewApiContext } from "context/newapi";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import KycSuccess from "components/KidKycSuccess";
const StyledModal = styled(Modal)`
  text-align: center;
  color: #fff;

  div:first-child {
    font-size: 26px !important;
  }
  .ant-modal-content {
    padding: 40px !important;
  }

  button {
    width: 100%;
    margin-top: 16px;
  }

  @media (max-width: 400px) {
    .ant-modal-content {
      padding: 20px !important;
    }
    input {
      font-size: 13px !important;
      padding: 8px 4px;
    }
  }
`;
const Dragzone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 2px dashed #34384c;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }

  input {
    display: flex;
    z-index: 2;
    color: transparent;
    width: 100%;
    opacity: 0;
    height: 130px;
  }
`;
const Drag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
`;

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 14px !important;
  z-index: 1;
  cursor: pointer;
  margin-top: 12px;
  img {
    width: 56px;
    height: 56px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  text-align: ${(props) => props.Align};
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #949ab2;
  svg {
    fill: #949ab2 !important;
  }
`;
const InputTitle = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  align-self: ${(props) => props.alignSelf};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 12px;
  width: 100%;
  margin: 16px 0px;

  div:last-child {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  fonw-weight: 500;
  img {
    width: 80%;
  }
`;
const Error = styled.div`
  border: 1px solid #d91b60;
  border-radius: 14px;
  color: #fff;
  padding: 6px 0px;
  font-size: 14px;
  position: relative;
  botttom: 0;
`;
export default function AddFamily() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { align } = useContext(DirectionContext);
  const [error, setError] = useState(false);
  let count = useRef(false);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function registerKid(childName, childBirthday) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("id");

    return new Promise((resolve, reject) => {
      $.ajax({
        url: config.KID_REGISTER,
        method: "POST",
        data: {
          childName: childName,
          childBirthday: childBirthday,
          userId: userId,
          token: token,
        },
        success: function (data) {
          const resp = JSON.parse(data);
          console.log(resp.id);
          if (count === true) {
            setError(true);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          return resolve(resp.id);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("Error:", textStatus, errorThrown);
          reject(errorThrown);
        },
      });
    });
  }
  const [setter, setSetter] = useState(false);
  useEffect(() => {
    const fileToUploadElement = document.getElementById("fileToUpload");

    if (fileToUploadElement) {
      fileToUploadElement.addEventListener("drag", function (event) {
        event.preventDefault();
        setSetter(true);
      });

      fileToUploadElement.addEventListener("change", function (event) {
        event.preventDefault();
        setSetter(true);
      });
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];
    var formData = new FormData();
    const childName = document.getElementById("childName").value;
    const childBirthday = document.getElementById("childBirthday").value;

    file === undefined ? (count = true) : setError(false);

    registerKid(childName, childBirthday)
      .then((kidId) => {
        formData.append("fileToUpload", file);
        formData.append(
          "fileName",
          localStorage.getItem("id") + "_" + kidId + ".png"
        );
        formData.append("id", localStorage.getItem("id"));
        formData.append("token", localStorage.getItem("token"));
        formData.append("submit", "submit");

        fetch(config.AVATAR_UPLOAD, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            $.ajax({
              url: config.INC_IMGVERSION + "?id=" + localStorage.getItem("id"),
              type: "get",

              success: function (response) {
                setTimeout(() => {
                  window.location.reload();
                }, 1100);
              },
              error: function (error) {
                console.error("Error fetching data:", error);
              },
            });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error("Error registering kid:", error);
      });
  }

  return (
    <div>
      <ButtonWrapper>
        <button
          style={{ background: "transparent", border: "none" }}
          type="primary"
          onClick={showModal}
        >
          <img src="/assets/icons/addfamily.svg" alt="kid-bundle" />
        </button>
        {t("addfamilymember")}
      </ButtonWrapper>
      <StyledModal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <form id="myForm" onSubmit={handleSubmit}>
          <div> {t("addfamilymember")}</div>
          <Grid>
            <InputWrapper>
              <InputTitle alignSelf={align}>{t("childname")}</InputTitle>
              <StyledInput
                name="childName"
                type="text"
                placeholder="Child Name"
                id="childName"
              />
            </InputWrapper>

            <InputWrapper>
              <InputTitle alignSelf={align}> {t("childbirthday")} </InputTitle>

              <StyledInput
                name="childBirthday"
                type="date"
                placeholder="Child Birthday"
                id="childBirthday"
              />
            </InputWrapper>
          </Grid>
          <KycSuccess />

          <Dragzone id="dropzone">
            <input
              ref={fileInputRef}
              type="file"
              name="fileToUpload"
              id="fileToUpload"
              onDrag={(event) => event.preventDefault()}
              onChange={() =>
                (document.getElementById("kidkycsuccess").style.display =
                  "block")
              }
            />

            <Comp>
              <img src="/assets/icons/kid.png" alt="dragdrop" />

              <Drag>{t("uploadthephoto")}</Drag>
            </Comp>
          </Dragzone>

          {error === true ? <Error> {t("imageuploadfailed")}</Error> : ""}

          <SButton type="submit">{t("addfamilyupload")}</SButton>
        </form>
      </StyledModal>
    </div>
  );
}
