import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../../../langcheck";
import FiatModal from "./FiatModal";
import CryptoModal from "./CryptoModal";
import { ModalContext } from "context/modals";
import WindowSize from "WindowSizer";
import NoMobileWarn from "components/NoMobileWarn";
import detectEthereumProvider from "@metamask/detect-provider";

const StyledModal = styled(Modal)`
  @media (max-width: 1024px) {
    .ant-modal-content {
      padding: 0px 24px !important;
    }
  }
`;
const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  direction: ${(props) => props.direction};
  padding: 6px 12px;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
  gap: 4px;

  @media (max-width: 1024px) {
    padding: 8px 20px;
    font-weight: 800;
    font-size: 18px;
  }
  @media (max-width: 630px) {
    width: 90%;
  }
`;
const StyledButtonDisabled = styled(StyledButton)`
  border: 1px solid #999; // Light gray border for disabled state
  background-color: #555; // Dark gray background for disabled state
  color: #999; // Light gray text for disabled state
  cursor: not-allowed; // Cursor indicates the button is not clickable

  &:hover {
    background-color: #555; // Dark gray background remains the same on hover
    color: #999; // Light gray text remains the same on hover
    opacity: 1; // No change in opacity on hover
  }
`;
export default function CryptoButton({ kyc }) {
  const { modal2Open, setModal2Open } = useContext(ModalContext);
  // const [modal2Open, setModal2Open] = useState(false);
  const [amount, setAmount] = useState();
  const [meeting, setMeeting] = useState(false);
  const { t } = useTranslation();
  const direction = LanguageCheck() === "ar" ? "ltr" : "rtl";
  const Language = LanguageCheck();
  const [align, setAlign] = useState("");
  const [cryptoMod, setCryptoMod] = useState(false);
  const placeself = LanguageCheck() === "ar" ? "flex-end" : "flex-start";
  const isMobile = WindowSize();
  const [metamask, setMetamask] = useState(false);
  const [meetReq, setMeetReq] = useState({
    amount: 0,
    id: "",
    name: "",
    email: "",
    phone: "",
    lastname: "",
  });

  // checkForMetaMask()

  useEffect(() => {
    const checkForMetaMask = async () => {
      // Check if MetaMask is installed
      const provider = await detectEthereumProvider();
      if (provider) {
        console.log("MetaMask is installed!");
        setMetamask(true);
        // MetaMask is installed, you can interact with the Ethereum network here
      } else {
        console.log("MetaMask is not installed");
        setMetamask(false);
        // MetaMask is not installed, you can prompt the user to install it
      }
    };
    checkForMetaMask();
  }, []);

  useEffect(() => {
    setMeetReq({
      amount: amount,
      id: localStorage.getItem("id"),
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      phone: localStorage.getItem("phone"),
      lastname: localStorage.getItem("lastname"),
    });
  }, [amount]);

  useEffect(() => {
    if (amount > 5000) {
      setMeeting(true);
    } else {
      setMeeting(false);
    }

    Language === "ar" ? setAlign("right") : setAlign("left");
  }, [amount]);

  const isDisabled = true; // Replace with actual logic to determine disabled state

  return (
    <>
      <StyledButtonDisabled
        direction={direction}
        align={placeself}
        type="primary"
        onClick={() => {
          if (!isDisabled) {
            setModal2Open(true);
          }
        }}
      >
        <img src="/assets/icons/token/busd-button.svg" alt="usdt" />
        {t("buywithcrypto")}
      </StyledButtonDisabled>
      <StyledModal
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false) || setCryptoMod(false)}
        onCancel={() => setModal2Open(false) || setCryptoMod(false)}
      >
        <CryptoModal setModal2Open={setModal2Open} kyc={kyc} />
      </StyledModal>
    </>
  );
}
