import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../../../langcheck";
import SupportModal from "./SupportModal";

const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: #d71159;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  white-space: nowrap;
  direction: ${(props) => props.direction};
  padding: 4px 12px;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
  gap: 8px;
  img {
    width: 33px !important;
    height: 26px !important;
  }
  img:first-child {
    margin-top: 0 !important;
  }
  transition: 0.2s;

  @media (max-width: 1024px) {
    padding: 8px 40px;
    margin: 16px 0px 0px 0px;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    margin-top: 16px;
  }
  @media (max-width: 630px) {
    width: 90%;
  }
`;
const StyledButtonDisabled = styled(StyledButton)`
  background-color: #757575; // A mid-tone gray for disabled background
  border: 1px solid #757575; // A mid-tone gray for disabled border
  color: #bdbdbd; // A lighter gray for disabled text to simulate being disabled
  cursor: not-allowed; // Cursor to indicate the button is not clickable

  &:hover {
    opacity: 1; // Disabled button should not have hover effects
  }

  // If you want to make sure images inside the button also appear "disabled"
  img {
    filter: grayscale(100%); // Make images grayscale to look "disabled"
  }
`;

export default function Support({ kyc }) {
  const [modal2Open, setModal2Open] = useState(false);
  // const [modal2Open, setModal2Open] = useState(false);
  const [amount, setAmount] = useState();
  const [meeting, setMeeting] = useState(false);
  const { t } = useTranslation();
  const direction = LanguageCheck() === "ar" ? "ltr" : "rtl";
  const Language = LanguageCheck();
  const [align, setAlign] = useState("");
  const [cryptoMod, setCryptoMod] = useState(false);
  const placeself = LanguageCheck() === "ar" ? "flex-end" : "flex-start";
  const [meetReq, setMeetReq] = useState({
    amount: 0,
    id: "",
    name: "",
    email: "",
    phone: "",
    lastname: "",
  });

  useEffect(() => {
    setMeetReq({
      amount: amount,
      id: localStorage.getItem("id"),
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      phone: localStorage.getItem("phone"),
      lastname: localStorage.getItem("lastname"),
    });
  }, [amount]);

  useEffect(() => {
    if (amount > 5000) {
      setMeeting(true);
    } else {
      setMeeting(false);
    }

    Language === "ar" ? setAlign("right") : setAlign("left");
  }, [amount]);

  return (
    <>
      <StyledButtonDisabled
        direction={direction}
        align={placeself}
        type="primary"
        onClick={(e) => e.preventDefault()}
      >
        <img src="/assets/icons/support.svg" alt="usdt" />

        {t("guide")}
      </StyledButtonDisabled>
      <Modal
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false) || setCryptoMod(false)}
        onCancel={() => setModal2Open(false) || setCryptoMod(false)}
      >
        <SupportModal setModal2Open={setModal2Open} kyc={kyc} />
      </Modal>
    </>
  );
}
