import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";
import { ModalContext } from "index";
import { SettingContext } from "context/modals";
const NotifWrapper = styled.div`
  display: flex;
  position: relative;
  color: #ef890f;
  background: rgba(239, 137, 15, 0.21);
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 16px;
  border: 1px solid #ffaa06;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 auto;
  direction: ${(props) => props.direction};
  button {
    display: flex;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    align-items: center;
  }
`;

export default function Emailnotif({ isEmailNotif }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { direction } = useContext(DirectionContext);
  const { isSettingOpen, setSettingIsOpen } = useContext(SettingContext);
  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return !isOpen && !isEmailNotif ? (
    <>
      <NotifWrapper
        onClick={() => setSettingIsOpen(!isSettingOpen)}
        direction={direction}
      >
        {t("emailnotification")}
        <button onClick={handleButtonClick}>
          <img src="/assets/icons/yellowcancel.svg" alt="cancel" />
        </button>
      </NotifWrapper>
    </>
  ) : null;
}
