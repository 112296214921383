import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../langcheck";
import { DirectionContext } from "context/direction";
import { Flex } from "./Layouts";

const CarrouselWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #fff;
  place-items: center;
  font-size: 20px;
  max-width: 100%;
  justify-content: center;
  gap: 140px;
  img {
    max-height: 586px !important;
    max-width: 100%;
    margin-top: 44px;
  }

  @media (max-width: 1424px) {
    grid-template-columns: 1fr;
    place-items: center;
    img {
      display: none;
    }
    margin: 0 15px;
  }
`;
const TextCard = styled.div`
  display: inline;
  flex-direction: column;
  background-color: #1d1f25;
  padding: 40px 46px 50px 46px;
  color: #fff;
  max-width: 340px;
  font-weight: 500;
  text-align: ${({ align }) => align};
  font-size: 18px;
  border-radius: 12px;
  span {
    font-weight: 700;
    font-size: 19px;
    line-height: 160%;
    color: #fa2256;
  }
  @media (max-width: 1024px) {
    opacity: 0.8;
    font-size: 16px;
    background-color: rgba(29, 31, 37, 0.8);
    padding: 30px 20px;
    height: max-content;
    line-height: 32px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 1.4;
  text-align: ${({ align }) => align};
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

export default function ColoredCarrousel() {
  const { i18n } = useTranslation();
  const direction = LanguageCheck() === "ar" ? "right" : "left";
  const { rightLeft } = useContext(DirectionContext);

  return (
    <>
      {i18n.language === "ar" && (
        <CarrouselWrapper>
          <img src="/assets/carrousel/carrousel1.png" alt="c1" />
          <Flex gap="24px" column>
            <Title align={rightLeft}>كن شريكا في البنك</Title>

            <TextCard align={rightLeft}>
              المستثمر هو شريك رسمي في جميع الارباح السنوية التي يحققها البنك
              فكما ان الشركة المطورة لهذا المشروع تملك <span>%51</span> من
              الارباح السنوية فان <span>%49</span> من ارباح البنك السنوية توزع
              على المستثمرين لكل مستثمر حسب نسبته. يمكن للمستثمر ان يستثمر باي
              عدد من الاسهم ويحصل بها على شهادة استثمار كما يحصل على صك ملكية
              للاسهم و حساب مستثمر و يدون اسمه في لائحة المستثمرين على الموقع
              الرسمي لبنك الخليج الرقمي.
            </TextCard>
          </Flex>
        </CarrouselWrapper>
      )}

      {i18n.language === "eng" && (
        <CarrouselWrapper>
          <img src="/assets/carrousel/carrousel1.png" alt="c1" />
          <Flex gap="24px" column>
            <Title>Become a Bank Partner</Title>

            <TextCard direciton={direction}>
              The investor is an official partner in all the annual profits
              achieved by the bank, just as the company developing this project
              owns <span> 51%</span> of the annual profits, <span> 49%</span> of
              the bank's annual profits are distributed to investors for each
              investor according to his percentage. An investor can invest in
              any number of shares and obtain an investment certificate, a title
              deed for shares and an investor account, and his name is recorded
              in the list of investors on Gulf Bank's official website.
            </TextCard>
          </Flex>
        </CarrouselWrapper>
      )}
    </>
  );
}
