import React from 'react'
import styled from 'styled-components'
import { Flex } from 'components/Layouts'
import Navbar from 'components/Navbar'
import { SButton } from 'components/styled'
import { useTranslation } from 'react-i18next'
import LanguageCheck from 'langcheck'

const Wrapper = styled.div`
display: flex;
flex-direction: column;
gap: 18px;
justify-content: center;
align-items: center;
background: url("assets/dashbg.png");
background-repeat: no-repeat;
background-size: cover;
background-position: top center;
height: calc(100vh - 100px);
height: 100vh;

@media (max-width: 1024px) {
    justify-content: flex-start;
    height: 160vh;
}
`

const Card = styled.div`
display: flex;
flex-direction: column;
text-align: center;
background: #1E1F25;
color: #fff;
border: 1px solid #5D6588;
border-radius: 14px;
gap: 40px;
font-weight: 500;
padding: 80px 300px 90px 300px; 
line-height: 1.4;
width: 40%;
@media (max-width: 1524px) {
    padding: 24px 14px;   
    width: 70%;
    margin-top: 40px;
}
@media (max-width: 1024px) {
    padding: 24px 14px;   
    width: 85%;
    margin-top: 100px;
}
`
const LicenceContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 18px;
img{
    max-width: 250px;
}
@media (max-width: 1524px) {
    img{
        max-width: 200px;
    }
}
@media (max-width: 1024px) {
    img{
        max-width: 130px;
    }
    a{
        width: 100%;
    }  
}
@media (max-width: 568px) {
    ${lang => lang.lang === 'ar' && ` 
    width: 100%;
    `}
    }
`

const Textwrapper = styled.div`
display: flex;
flex-direction: column;
gap: 18px;
font-weight: 500;
line-height: 1.4;
div:first-child  {
    font-weight: 800;
    font-size: 32px;
}
`
const Button = styled(SButton)`
  border: 1px solid #d71159;
  position: relative;
  background-color: ${(props) =>
        props.transparent ? "transparent" : "#D71159"};
  color: #fff;
  padding: 4px 60px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 2px 12px;
    width: 100%;
  }
`;
const Flexbox = styled(Flex)`



@media (max-width: 1024px) {
    gap: 24px;
    width: 100%;
    flex-wrap: wrap;
}
`
export default function Licences() {
    const { t } = useTranslation()
    const Language = LanguageCheck()
    return (
        <>
            <Navbar></Navbar>
            <Wrapper>
                <Card>
                    <Textwrapper>

                        <div>
                            {t('license.title')}
                        </div>
                        <div>
                            {t('license.text')}
                        </div>
                    </Textwrapper>

                    <Flexbox justify="space-around">

                        <LicenceContainer lang={Language}>
                            <img src='/assets/USA.png' alt='uk' />
                            <a target="_blank" href="https://lei.bloomberg.com/leis/view/25490012O3HR53A8IC59">

                                <Button >
                                    {t('license.usa')}

                                </Button>
                            </a>

                        </LicenceContainer>
                        <LicenceContainer lang={Language}>
                            <img src='/assets/UK.png' alt='uk' />
                            <a target="_blank" href="https://find-and-update.company-information.service.gov.uk/company/14734197">

                                <Button >
                                    {t('license.uk')}

                                </Button>
                            </a>
                        </LicenceContainer>
                    </Flexbox>


                </Card>
            </Wrapper>

        </>
    )
}
