import React, { useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import config from "../config";
const Avatar = styled.div`
  position: relative;
  bottom: 13px;
  height: 100%;
  -webkit-mask-image: url("/assets/mask.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: center;
  mask-image: url("/assets/mask.svg");
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  mask-position: center;

  img {
    width: 52px;
    height: 52px;
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);

    -webkit-mask-image: inherit;
    mask-image: inherit;

    -webkit-mask-repeat: inherit;
    mask-repeat: inherit;

    -webkit-mask-size: inherit;
    mask-size: inherit;

    -webkit-mask-position: inherit;
    mask-position: inherit;
  }
`;
const AvatarWrapper = styled.div`
  min-width: 100%;
  height: 100px;
  max-height: 101px;
  min-width: 50px;
  background-image: ${(props) => props.background};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #584631;
  ::before {
    content: "${(props) => props.UpperCased}";
    top: 18%;
    position: relative;
    font-size: 24px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
  }
`;
const WordWrapper = styled.div`
  min-width: 100%;
  height: 100px;
  max-height: 101px;
  min-width: 50px;
  background-image: ${(props) => props.background};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #584631;
  ::before {
    content: "";
    top: 18%;
    position: relative;
    font-size: 24px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
  }
`;
export default function Avatarmaker({ name, background, id, imgVersion }) {
  let firstLetter = name.charAt(0);
  let UpperCased = firstLetter.toUpperCase();
  const [userAvatar, setUserAvatar] = useState();
  const [isImage, setIsImage] = useState();

  useEffect(() => {
    setUserAvatar(
      config.AVATAR_COMPRESSED + id + ".png?imgVersion=" + imgVersion
    );
  }, []);

  const handleError = () => {
    setIsImage(false);
  };

  return (
    <>
      {isImage === false ? (
        <AvatarWrapper UpperCased={UpperCased} background={background} />
      ) : (
        <WordWrapper background={background}>
          <Avatar>
            <img src={userAvatar} onError={handleError} alt="avatar" />
          </Avatar>
        </WordWrapper>
      )}
    </>
  );
}
