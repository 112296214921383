import React, { useState, useEffect, memo, useContext } from "react";
import styled from "styled-components";
import { DirectionContext } from "context/direction";
import { useTranslation } from "react-i18next";
import { PriceContext } from "context/prices";

const PriceWrapper = memo(styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  grid-column: 1/3;

  img {
    width: 1rem !important;
    height: 1rem !important;
  }
`);
export default function GulfvsDolar() {
  const [gulfcoinPrice, setGulfcoinPrice] = useState(0);
  const { gulfPrice } = useContext(PriceContext);
  const { align } = useContext(DirectionContext);
  // console.log("gulfPrice: " +gulfPrice , "shareprice"+ sharePrice)
  useEffect(() => {
    if (gulfPrice !== null) {
      setGulfcoinPrice(gulfPrice.toFixed(4));
    } else {
      setGulfcoinPrice(0);
    }
  }, [gulfPrice]);

  return (
    <PriceWrapper align={align}>
      {" "}
      <span>$ {gulfcoinPrice} &nbsp;</span> ={" "}
      <img src="../assets/gulf-inner.svg" alt="gulf" /> 1{" "}
    </PriceWrapper>
  );
}
