import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Buttons from "../components/Buttons";
import { StyledLink } from "../components/styled";
import { DirectionContext } from "context/direction";
import $ from "jquery";
import config from "../config";

const Frame = styled.iframe`
  aspect-ratio: 24 / 9;
  border-radius: 16px;
  border: none;
  @media (max-width: 768px) {
    aspect-ratio: 16 / 9;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  margin: 0px;
  line-height: 140%;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;
const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};

  @media (max-width: 768px) {
    margin: 120px 0px 220px 0px;
  }
`;
const StyledLinkTemp = styled(StyledLink)`
display: ${(props) => props.display};
@media (max-width: 768px) {
    display:none;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: ${(props) => props.direction};
`;

export default function Video({ margin, display }) {
  const { t } = useTranslation();
  const { direction, rightLeft } = useContext(DirectionContext);

  const [route, setRoute] = useState();
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setRoute("/dashboard");
    } else {
      setRoute("/login");
    }
  }, []);
  let done = false;
  useEffect(() => {
    var settings = {
      url: config.SECTION_VIEWS,
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        section: "Video",
      },
    };
    if (done === false) {
      $.ajax(settings).done(function (response) {});
    }
    done = true;
  }, []);
  return (
    <VideoWrapper textAlign={rightLeft} margin={margin}>
      <Flex direction={direction}>
        <Title> {t("videoTitle")}</Title>

        <StyledLinkTemp display={display} to={route}>
          <Buttons padding={true} text={t("pButton")} />
        </StyledLinkTemp>
      </Flex>
      <iframe
      
        height="502"
        src="https://www.youtube.com/embed/waxJ32zfkn4"
        title="الحدث التاريخي العظيم - افتتاح بنك الخليج الرقمي 🔥🔥 GULF CRYPTO BANK"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </VideoWrapper>
  );
}
