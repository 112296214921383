import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { StyledLink } from '../components/styled';
export default function PwChangedSucces() {
  const { t } = useTranslation();
  const PwWrapper = styled.div`
        display:flex;
        flex-direction:column;
        color: #FFFFFF;
        text-align:center;
        justify-content:center;
        margin-top:120px;
        align-items:center;
        gap: 20px;
        div:nth-child(2){
            font-weight: 400;
            font-size: 30px;
              line-height: 125%;
        }


        div:nth-child(3){
            font-weight: 500 !important;
            font-size: 20px;
                line-height: 125%;
        }

        img {
            max-width: 200px;
        }

        @media (max-width: 1024px) {
            margin-top: 0px;
        }
    `

  const ButtonBox = styled.div`
  border:1px solid #D71159;
  display:flex;
    justify-content:center;
    align-items:center;
  position:relative;
  background-color: #D71159;
  color: #fff;
  font-size:18px;
  padding:10px 54px;
  font-weight:800;
  border-radius:30px;
  cursor:pointer;
  &:hover {
    opacity:0.8;
  }
  
  @media (max-width: 1024px) {
    height: 50px;
    font-weight: 800;
    font-size: 18px;
        line-height: 160%;
      }
        
        `



  return (
    <PwWrapper>
      <img src="/assets/pwchange.svg" alt='success' />
      <div>{t('passwordchanged')}</div>
      <div>{t('passwordchangedsuccess')}</div>
      <StyledLink to="/login">
        <ButtonBox> {t('backtologin')} </ButtonBox>
      </StyledLink>
    </PwWrapper>
  )
}
