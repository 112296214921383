import React from "react";
import styled from "styled-components";
import "../App.css";
import $ from "jquery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SignLeft from "../components/SignLeft";
import config from "../config";
import LanguageCheck from "../langcheck";
import LogPageMobilIcon from "../components/LogPageMobilIcon";
import BackHome from "../components/BackHome";
import Navbar from "components/Navbar";
import { Flex } from "components/Layouts";
import countries from "../countrycodes.json";
import { Divider } from "antd";
import useBubbleRemover from "hooks";
const Form = styled.form`
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media (max-width: 1024px) {
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  gap: ${(props) => props.gap};
  margin-bottom: 25px;
  @media (max-width: 1024px) {
  }
`;
const HalfGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;
  @media (max-width: 1024px) {
    gap: 10px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
  label {
    display: block;
    font-size: 14px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  white-space: nowrap;
  width: 100%;
  gap: 15px;

  button {
    border: 1px solid #d71159;
    position: relative;
    background-color: #d71159;
    color: #fff;
    font-size: 18px;
    padding: 10px 54px;
    font-weight: 800;
    border-radius: 30px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 1024px) {
      padding: 12px 42px;
      height: 50px;
      font-weight: 800;
      font-size: 18px;
      line-height: 160%;
    }
  }
`;

const Selector = styled.select`
  background: #1d1f25;
  border: 1.5px solid #34384c;
  border-radius: 8px;
  height: 50px;
  color: #5d6588;
  padding: 0 8px;
  width: 100%;
  :focus {
    outline: none;
  }
  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }
  ::placeholder {
    color: #5d6588;
  }
  @media (max-width: 1024px) {
  }
`;

const Countrycodes = styled(Selector)`
  border: none;
  border-radius: 8px 0px 0px 8px;

  cursor: pointer;
  padding: 0px;
  ::-webkit-scrollbar {
    width: 1px;
  }
`;

const Title = styled.div`
  color: #fff;
  font-weight: 800;
  font-size: 28px;
  line-height: 1;
`;

const Sect = styled.section`
  position: relative;
  @media (max-width: 1024px) {
    top: 150px;
  }
`;
const Error = styled.div`
  color: #d91b60;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  botttom: 0;
`;
const Page = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  background-color: #141518;
  height: 100vh;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    height: 100%;
  }
  @media (max-width: 600px) {
    height: 100%;
  }
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  text-align: ${(props) => props.direction};
  padding: 0 14%;
  @media (max-width: 1424px) {
    padding: 10vh 10%;
    place-content: flex-start;
    margin-top: 4vh;
  }
  @media (max-width: 1024px) {
    height: 100vh;
  }
  @media (max-width: 550px) {
  }
`;

const Input = styled.input`
  background: #1d1f25;
  border: 1.5px solid #34384c;
  border-radius: 8px;
  height: 50px;
  color: white;
  text-align: ${(props) => props.direction};
  padding: 0 12px;
  :focus {
    outline: none;
  }
  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }

  ::placeholder {
    color: #5d6588;
    text-align: ${(props) => props.direction};
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const PhoneInput = styled(Input)`
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-radius: 0px 8px 8px 0px;
  :hover {
    transition: 0.2s;
    border-color: #34384c;
  }
`;
const PhoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  border: 1.5px solid #34384c;
  place-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  gap: 10px;
  background: #1d1f25;
  div:first-child {
    margin-left: 10px;
  }
  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }
  input {
    width: 100%;
  }
  @media (max-width: 1024px) {
    div:first-child {
      margin-left: 10px;
    }
  }
`;
export default function Signup() {
  const [confirmPw, setConfirmPw] = useState("");
  const { t } = useTranslation();
  const direction = LanguageCheck() === "ar" ? "right" : "left";
  const align = LanguageCheck() === "ar" ? "flex-end" : "flex-start";
  const [used, setUsed] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("Bahrain");
  const [dialCode, setDialCode] = useState("+973");
  useBubbleRemover(true);

  const [user, setUser] = useState({
    name: "",
    surname: "",
    amount: "",
    email: "",
    password: "",
    phone: "",
    country: selectedCountry,
    nameError: "",
    surnameError: "",
    amountError: "",
    emailError: "",
    phoneError: "",
    countryError: "",
    checkedError: "",
  });

  const [errorGap, setErrorGap] = useState("20px");

  useEffect(() => {
    if (user.nameError === "") {
      setErrorGap("10px");
    } else {
      setErrorGap("0px");
    }
  }, [
    user.amountError,
    user.nameError,
    user.surnameError,
    user.emailError,
    user.phoneError,
    user.countryError,
    user.passwordError,
    errorGap,
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const validate = () => {
    let isError = false;
    const errors = {
      nameError: "",
      surnameError: "",
      passwordError: "",
      emailError: "",
      phoneError: "",
      confirmPwError: "",
      checkedError: "",
    };

    if (user.name === "") {
      isError = true;
      errors.nameError = t("requiredfield");
    }
    if (user.surname === "") {
      isError = true;
      errors.surnameError = t("requiredfield");
    }
    if (user.country === "") {
      isError = true;
      errors.countryError = t("requiredfield");
    }
    if (isChecked === false) {
      isError = true;
      errors.checkedError = t("requiredfield");
    }
    if (user.password === "") {
      isError = true;
      errors.passwordError = t("requiredfield");
    }
    if (user.password !== confirmPw) {
      isError = true;
      errors.confirmPwError = t("requiredfield");
    }
    if (user.email === "") {
      isError = true;
      errors.emailError = t("requiredfield");
    } else {
      // Check for a valid email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(user.email)) {
        isError = true;
        errors.emailError = t("invalidemail");
      }
    }
    if (user.phone === "") {
      isError = true;
      errors.phoneError = t("requiredfield");
    } else {
      // Check for a valid phone number format
      const phoneRegex = /^(\+)?\d+$/;
      if (!phoneRegex.test(user.phone)) {
        isError = true;
        errors.phoneError = t("invalidphone");
      }
    }

    setUser({ ...user, ...errors });
    return isError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    document.forms[0].reset();
    const err = validate();
    if (err === false && confirmPw === user.password) {
      $.ajax({
        type: "POST",
        url: config.API_URL,
        data: {
          action: "signup_user",
          name: user.name,
          lastname: user.surname,
          email: user.email.toLowerCase(),
          phone: user.phone,
          country: user.country,
          password: user.password,
          inviter: localStorage.getItem("inviter"),
        },
        success: function (response) {
          console.log(response); // Inspect the response object
          let res = JSON.parse(response);
          const message = res.message; // Access the value using user.email as the key

          if (message.includes("is Used")) {
            setUsed(true);
          } else {
            setUsed(false);
          }
          try {
            let status = res.status;
            console.log("status= " + status);
            if (status === "success") {
              console.log("should go to login");
              setTimeout(() => {
                window.location.href = "/login";
              }, 1000);
            }
          } catch (e) {
            console.log(e);
          }
        },
      });
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setUser((prevUser) => ({
      ...prevUser,
      country: event.target.value,
    }));
    setDialCode(countries[event.target.value].dial_code);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Page>
      <Navbar />
      <SignLeft />
      <FormWrapper direction={direction}>
        <Sect id="register" />

        <Title>{t("signup")}</Title>

        <Form onSubmit={handleSubmit}>
          <Grid gap={errorGap}>
            <HalfGrid>
              <Item>
                <label htmlFor="name">{t("name")}</label>
                <Input
                  direction={direction}
                  name="name"
                  type="text"
                  value={user.name}
                  onChange={handleChange}
                  placeholder={t("placeholderName")}
                />
                {user.nameError && <Error>{user.nameError}</Error>}
              </Item>

              <Item>
                <label>{t("surname")}</label>
                <Input
                  name="surname"
                  type="text"
                  value={user.surname}
                  onChange={handleChange}
                  placeholder={t("placeholderSurname")}
                  direction={direction}
                />
                {user.surnameError && <Error>{user.surnameError}</Error>}
              </Item>
            </HalfGrid>

            <Item>
              <label>{t("email")}</label>
              <Input
                direction={direction}
                name="email"
                textAlign="left"
                value={user.email}
                type="text"
                onChange={handleChange}
                placeholder={t("placeholderEmail")}
              />
              {user.emailError && <Error>{user.emailError}</Error>}
              {used && <Error>{t("emailused")}</Error>}
            </Item>
            <Item>
              <label>{t("password")}</label>
              <Input
                direction={direction}
                name="password"
                value={user.password}
                type="password"
                onChange={handleChange}
                placeholder={t("password")}
              />
              {user.passwordError && <Error>{user.passwordError}</Error>}
            </Item>
            <Item>
              <label>{t("cPassword")}</label>
              <Input
                type="password"
                value={confirmPw}
                onChange={(e) => setConfirmPw(e.target.value)}
                placeholder={t("cPassword")}
                direction={direction}
              />
              {user.confirmPwError && <Error>{user.confirmPwError}</Error>}
            </Item>

            <HalfGrid>
              <Item>
                <label>{t("phone")}</label>
                <PhoneWrapper>
                  <div>{dialCode}</div>
                  <PhoneInput
                    name="phone"
                    type="number"
                    value={user.phone}
                    onChange={handleChange}
                    placeholder={t("placeholderPhone")}
                    direction={direction}
                  />
                </PhoneWrapper>

                {user.phoneError && <Error>{user.phoneError}</Error>}
              </Item>
              <Item>
                <label>{t("country")}</label>
                <Selector
                  id="country"
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  placeholder="المغرب"
                >
                  {Object.keys(countries).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Selector>
                {user.countryError && <Error>{user.countryError}</Error>}
              </Item>
            </HalfGrid>
          </Grid>

          <div
            style={{
              marginBottom: "24px",
              direction: direction,
              fontWeight: "600",
              fontSize: "14px",
              placeSelf: align,
            }}
          >
            <label className="container-check" htmlFor="auto">
              <input
                type="checkbox"
                id="auto"
                name="auto"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
              {t("checkboxvalidate")}
            </label>
            {user.checkedError && <Error>{user.checkedError}</Error>}
          </div>

          <ButtonBox>
            <button type="submit">{t("signup")}</button>
          </ButtonBox>
        </Form>
      </FormWrapper>
    </Page>
  );
}
