import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ErrorAnimation from "../components/ErrorAnimation";
import Navbar from "../components/Navbar";
import Buttons from "../components/Buttons";
import { StyledLink } from "../components/styled";
const NotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #1d1f25;
  @media (max-width: 768px) {
    padding: 50px 0px;
  }
`;

const NotifMessage = styled.div`
  font-weight: 700;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  color: #fff;
  width: 60%;
  text-align: center;
  div:first-child {

    font-weight: 900;
  }
  @media (max-width: 768px) {
    width: 300px;
  }
`;
export default function PaymentFailed() {
  const { t } = useTranslation();

  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const errorCode = params.get("errorCode")
    console.log(errorCode);
    setError(errorCode);
    if (errorCode === '"MF001"') {
      setErrorMessage("3DS authentication failed, possible reasona (user inserted wrong password, user cardholder/card issuer are not enrolled with 3DS, on the issuer bank has technical issues)");
    } else if (errorCode === '"MF002"') {
      setErrorMessage("The issuer bank has declined the transaction , possible reasons (invalid card details, insufficient funds, denied by risk, the card is expired/held , or card is not enabled for online purchase). ");
    } else if (errorCode === '"MF003"') {
      setErrorMessage("The transaction has been blocked from the gateway , possible reasons (unsupported card BIN, fraud detection, or security blocking rules). ");
    } else if (errorCode === '"MF004"') {
      setErrorMessage("Insufficient funds");
    } else if (errorCode === '"MF005"') {
      setErrorMessage("Session timeout");
    } else if (errorCode === '"MF006"') {
      setErrorMessage("Transaction canceled");
    } else if (errorCode === '"MF007"') {
      setErrorMessage("Card expired");
    } else if (errorCode === '"MF008"') {
      setErrorMessage("The card issuer doesn't respond");
    } else if (errorCode === '"MF009"') {
      setErrorMessage("Denied by Risk");
    } else if (errorCode === '"MF010"') {
      setErrorMessage("Wrong Security Code");
    } else if (errorCode === '"MF020"') {
      setErrorMessage("Unspecified Failure");
    }


  }, []);

  return (
    <>
      <Navbar />
      <NotificationWrapper>
        <ErrorAnimation />
        <NotifMessage>
          <div>
          {t("paymentfailed")}
          </div>  
          <br />

          <div>{errorMessage}</div>
          <div>{error}</div>
        </NotifMessage>
        <StyledLink to="/dashboard">
          <Buttons text={t("gotodashboard")} />
        </StyledLink>
      </NotificationWrapper>
    </>
  );
}
