import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import "../App.css"
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import SignLeft from '../components/SignLeft';
import config from '../config';
import LanguageCheck from '../langcheck';
import { Link, Navigate } from 'react-router-dom';
import Notification from '../components/Notification';
import LogPageMobilIcon from '../components/LogPageMobilIcon';
import CheckMail from '../components/CheckMail';
import BackHome from '../components/BackHome';

const Form = styled.form`
color: #fff;
display: flex;
flex-direction: column;
gap:70px;


@media (max-width: 1024px) {
    gap: 40px;
}
`

const Grid = styled.div`
    display:grid;
    grid-template-rows: 1fr ;
    gap: 20px;
    padding-top:28px;

@media (max-width: 1024px) {
}
`

const Item = styled.div`
display: flex;
flex-direction: column;
gap:4px;
label{
    display:block;
    font-size:14px;
}
`

const ButtonBox = styled.div`
display: flex;
flex-direction: column;
text-align: center;
font-size: 18px;
font-weight: 800;
white-space: nowrap;
gap:15px;
img {
margin-top:60px;
}
@media (max-width: 1024px) {
img {
margin-top:100px;
margin-bottom:50px;
}
}

button {
  border:1px solid #D71159;
  position:relative;
  background-color: #D71159;
  color: #fff;
  font-size:18px;
  padding:10px 54px;
  font-weight:800;
  border-radius:30px;
  cursor:pointer;
  &:hover {
    opacity:0.8;
  }
  
  @media (max-width: 1024px) {
    padding:12px 42px;
    height: 50px;
    font-weight: 800;
    font-size: 18px;
        line-height: 160%;
      }
    }
    
    `


const Title = styled.text`

color:#fff;
font-weight: 800;
font-size: 28px;
line-height: 160%;
`



const Error = styled.div`
color:#D91B60;
font-size: 14px;
position: relative;
botttom:0;
font-weight: 500;
`


const Page = styled.div`
display:grid;
grid-template-columns: 1.5fr 1fr;
height:100vh;
background-color: #141518;
text-align: ${props => props.direction};
@media (max-width: 1024px) {
grid-template-columns: 1fr;
height:auto;
}
    `

const FormWrapper = styled.div`
    display:flex;
    flex-direction:column;
    padding:320px 150px 150px 150px;
    background-color: #141518;
    @media (max-width: 1470px) {
        padding: 320px 80px 80px 80px;
    }

    @media (max-width: 1024px) {
        padding:220px 70px 0px 70px;
        min-height:100vh;
    }
    @media (max-width: 452px) {
        padding:220px 40px 0px 40px;

    }
`


const Input = styled.input`
background: #1D1F25;
border: 1.5px solid #34384C;
border-radius: 8px;
height: 50px;
color:white;
text-align: ${props => props.direction};
padding: 0 20px;
:focus{
outline: none;
}
:hover{
transition: 0.2s;
border-color: #D71159;
}

::placeholder {
color: #5D6588;
text-align: ${props => props.direction};
}

@media (max-width: 1024px) {
    width:auto;
max-width:100%;
}
`


export default function ResetPassword() {
    const [loading, setLoading] = useState(true);
    const Language = LanguageCheck()
    const [existEmail, setExistEmail] = useState(false)
    const [user, setUser] = useState({
        email: ''
    });
    const direction = LanguageCheck() === 'ar' ? 'right' : 'left';
    const { t } = useTranslation();




    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
    }

    const validate = () => {
        let isError = false;
        const errors = {
            passwordError: '',
            emailError: '',
        };
        if (user.email === '') {
            isError = true;
            Language === 'ar' ? errors.emailError = 'هذه الخانه اجباريه' : errors.emailError = 'This field is required'
        }


        else isError = false;

        setUser({ ...user, ...errors });
        return isError;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        document.forms[0].reset();
        const err = validate();

        if (err === false)
            $.ajax({
                type: 'POST',
                url: config.RESET_PW,
                data: {
                    email: user.email,
                    submit: 'submit'
                },
                success: function (response) {

                    if (response.includes('success')) {
                        setLoading(false)
                    }
                    // its working 
                    if (response.includes('Email address not found')) {
                        setExistEmail(true)
                    }


                    // its not working ask to brinis


                    let resp = JSON.parse(response);
                    console.log(loading);
                },
                error: function (error) {
                    console.error('Error:', error);
                }
            })

    }

    return (

        <Page direction={direction}>
            <SignLeft />


            <FormWrapper>

                <LogPageMobilIcon />
                <BackHome />
                {
                    loading
                        ?
                        <>
                            <Title>
                                {t('resetpw')}
                            </Title>


                            <Form onSubmit={handleSubmit} >
                                <Grid>

                                    <Item>
                                        <label>{t('email')}</label>
                                        <Input
                                            direction={direction}
                                            name="email"
                                            textAlign="left"
                                            value={user.email} type="text" onChange={handleChange} placeholder={t('placeholderEmail')} />
                                        {user.emailError && <Error >{user.emailError}</Error>}
                                        {existEmail && <Error > {t('emailnotfound')} </Error>}
                                    </Item>


                                </Grid>

                                <ButtonBox >
                                    <button type='submit'>
                                        {t('sendemaill')}
                                    </button>
                                </ButtonBox>

                            </Form>
                        </>
                        :
                        <CheckMail />
                }

            </FormWrapper>


        </Page>

    )
}
