import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../langcheck";
import { DirectionContext } from "context/direction";
import { Flex } from "./Layouts";

const CarrouselWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #fff;
  place-items: center;
  font-size: 20px;
  max-width: 100%;
  justify-content: center;
  gap: 140px;
  img {
    max-height: 586px !important;
    max-width: 100%;
    margin-top: 44px;
  }

  @media (max-width: 1424px) {
    grid-template-columns: 1fr;
    place-items: center;
    img {
      display: none;
    }
    margin: 0 15px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 1.4;
  text-align: ${({ align }) => align};
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;
const TextCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1d1f25;
  padding: 40px 46px 50px 46px;
  color: #fff;
  max-width: 340px;
  max-height: max-content !important;
  text-align: ${({ align }) => align};
  font-size: 18px;
  border-radius: 12px;
  font-weight: 500;
  @media (max-width: 1024px) {
    opacity: 0.8;
    font-size: 16px;
    background-color: rgba(29, 31, 37, 0.8);
    padding: 30px 20px;
    height: max-content;
    line-height: 32px;
  }
`;

export default function Carrousel1({ text, image, title }) {
  const { rightLeft } = useContext(DirectionContext);

  return (
    <CarrouselWrapper>
      <img src={image} alt="c1" />
      <Flex gap="24px" column>
        <Title align={rightLeft}>{title}</Title>
        <TextCard align={rightLeft}>{text}</TextCard>
      </Flex>
    </CarrouselWrapper>
  );
}
