import React, { useState, useEffect, memo, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GulfvsDolar from "../GulfvsDolar";
import DragDrop from "components/dashboard/DragDrop";
import KycWarning from "components/dashboard/KycWarning";
import $ from "jquery";
import config from "../../config";
import KycSuccess from "../KycSuccess";
import { DirectionContext } from "context/direction";
import Shareprice from "components/Shareprice";
import { PriceContext } from "context/prices";

const ModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 800;
  font-size: 28px;
  color: #ffffff;
  line-height: 160%;
  padding: 30px 0px 30px 0px;
  animation-name: ${(props) => props.animationName};
  animation-duration: 2s;
`;
const InputTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  align-self: ${(props) => props.alignSelf};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0px 12px;
  margin: 16px 0px;

  div:last-child {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

const Inp = styled.input`
  background: transparent;
  border: none;
  outline: none;
  font-weight: 700;
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 12px !important;

  ::focus {
    outline: none;
  }
  @media (max-width: 472px) {
    padding: 10px 12px !important;
  }
`;
const BorderedInput = styled.input`
  background: transparent;
  outline: none;
  color: #ffffff;
  border: 2px solid #34384c;
  border-radius: 8px;
  width: 95%;

  font-weight: 600;
  grid-column: 1/4;
  font-size: 18px;
  padding: 10px 0px 10px 12px !important;

  ::focus {
    outline: none;
  }
  @media (max-width: 472px) {
    width: 95%;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  text-align: ${(props) => props.Align};
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #949ab2;
  svg {
    fill: #949ab2 !important;
  }
`;

const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  padding: 4px 0px;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    white-space: nowrap;
    padding: 12px 0px;
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
  }
`;
const Error = styled.span`
  color: #d91b60;
  font-size: 16px;
  position: relative;
  botttom: 0;
`;

export default function CardPayment({
  numberofShare,
  bundleId,
  totalPackage,
  remaining,
  totalPrice,
}) {
  const [disabled, setDisabled] = useState(true);

  const BookButton = styled(StyledButton)`
    background-color: #d71159;
    margin-bottom: 18px;

    ${disabled === true
      ? `
            background-color: #1a1b20;
            color: #34384c;
            width: 100%;
            border: none;
            border-radius: 14px;
            font-weight: 800;
            font-size: 18px;
            cursor: not-allowed;
            &:hover{
                opacity:1;
            }
    
      `
      : `
            background-color:#D71159;
        `}
  `;
  const [amount, setAmount] = useState(1);
  const { t } = useTranslation();
  const { sharePrice } = useContext(PriceContext);
  const { align } = useContext(DirectionContext);
  const [err, setErr] = useState(true);
  const [validate, setValidate] = useState(false);
  // const [gulfcoinPrice, setGulfcoinPrice] = useState(0);
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [calculatedPackage, setCalculatedPackage] = useState(numberofShare);
  const [remainAmount, setRemainAmount] = useState("");
  const [kyc, setKyc] = useState(false);
  const [calculatedInDollar, setCalculatedInDollar] = useState(0);
  const [meetReq, setMeetReq] = useState({
    amount: 0,
    id: "",
    name: "",
    email: "",
    lastname: "",
  });
  useEffect(() => {
    if (align === "flex-end") {
      // ar
      setRemainAmount("يوجد فقط " + remaining + " حزمة متبقية");
    } else {
      setRemainAmount("There is only " + remaining + " packages left");
    }
  }, []);

  useEffect(() => {
    let fullName =
      localStorage.getItem("name") + " " + localStorage.getItem("lastname");
    setMeetReq({
      amount: amount,
      id: localStorage.getItem("id"),
      name: fullName,
      email: localStorage.getItem("email"),
    });
  }, [amount, calculatedAmount]);

  useEffect(() => {
    setCalculatedPackage(amount * numberofShare);
    setCalculatedInDollar(amount * totalPrice);
    console.log(totalPrice);
  }, [amount, sharePrice, totalPrice, numberofShare]);

  useEffect(() => {
    if (kyc === true && amount > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [amount, setKyc, kyc]);

  useEffect(() => {
    let id = localStorage.getItem("id");

    $.ajax({
      type: "post",
      url: config.FILE_EXIST + "?fileName=" + id + ".jpg",
      data: {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
      },
      success: function (response) {
        // console.log(response);
        if (id !== null) {
          if (response.message === "File exists") {
            setKyc(true);
          } else {
            setKyc(false);
          }
        }
      },
      error: function (error) {
        console.error("Error:", error);
      },
    });
  }, []);

  function validateForm() {
    let x = document.forms["form"]["quantity"].value;

    if (x === "") {
      setErr(true);
      setValidate(true);
    } else if (kyc === false) {
      setErr(true);
    } else if (x > remaining) {
      setErr(true);
      setValidate(true);
      setDisabled(true);
      setAmount(remaining);
    } else {
      setErr(false);
      document.getElementById("form").submit();
    }
  }

  const HandleSubmit = (e) => {
    e.preventDefault();
    validateForm();

    if (err === false) {
      document.getElementById("form").submit();
    }
  };

  function MathFloorTop(number) {
    if (number) return Math.floor(number);
    else return null;
  }

  return (
    <ModalContainer
      id="form"
      action={config.BUNDLE_CARD}
      onSubmit={HandleSubmit}
      method="post"
    >
      {t("paywithcreditcard")}

      <Grid>
        <InputWrapper>
          <InputTitle alignSelf={align}>{t("numberofpackages")}</InputTitle>
          <BorderedInput
            name="quantity"
            type="number"
            value={amount}
            step={1}
            placeholder="$"
            id="quantity"
            onChange={(e) => setAmount(MathFloorTop(e.target.value))}
          />
          {/* <DropdownMenu selected={selected} setSelected={setSelected} /> */}
          <Shareprice />
        </InputWrapper>

        <InputWrapper>
          <InputWrapper>
            <InputTitle alignSelf={align}>{t("shares")}</InputTitle>

            <BorderedInput readOnly={true} value={calculatedPackage} />
          </InputWrapper>
          <GulfvsDolar />
        </InputWrapper>

        <BorderedInput readOnly={true} value={calculatedInDollar} />
      </Grid>

      <Inp
        name="id"
        value={meetReq.id}
        id="id"
        hidden
        type="text"
        readOnly={true}
      />
      <Inp
        name="bundleId"
        value={bundleId}
        id="bundleId"
        hidden
        type="text"
        readOnly={true}
      />
      <Inp
        name="name"
        value={meetReq.name}
        id="name"
        type="text"
        hidden
        readOnly={true}
      />
      <Inp
        name="email"
        value={meetReq.email}
        id="email"
        type="email"
        hidden
        readOnly={true}
      />
      {validate && <Error> {remainAmount}</Error>}

      {kyc === false ? (
        <>
          <DragDrop setKycc={setKyc} />
        </>
      ) : null}
      <KycSuccess />
      <KycWarning />

      <BookButton type="submit" id="reqbutton" disabled={disabled}>
        {t("buynow")}
      </BookButton>
    </ModalContainer>
  );
}
