import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CardpayButton from "./modals/CardpayButton";
import ProfileContainer from "./ProfileContainer";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import config from "../../config";
import CryptoButton from "./modals/CryptoButton";
import SettingsModal from "../SettingsModal";
import { UserContext } from "../../context/user";
import { DirectionContext } from "../../context/direction";
import Support from "./modals/SupportButton";
import CertificateModal from "./modals/CertificateModal";
import KidsProfile from "components/package/KidProfile";
import { NewApiContext } from "context/newapi";
import AddFamily from "components/package/AddFamily";

const PanelContainer = styled.div`
  display: grid;
  grid-template-areas:
    "container flex"
    "kids kids";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  border-radius: 14px;
  background-color: #1e1f25;
  direction: ${(props) => props.direction};
  grid-gap: 60px;
  padding: 40px;

  @media (max-width: 630px) {
    grid-template-areas:
      "container"
      "flex"
      "kids";
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  grid-area: flex;
  gap: 14px;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  color: #fff;
  padding: 0px 62px;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    place-items: center !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: container;
  gap: 28px;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #fff;
  place-items: right;
  text-align: ${(props) => props.rightLeft};

  @media (max-width: 1024px) {
    font-size: 26px;
    gap: 24px;
  }
`;
const Divider = styled.div`
  position: relative !important;
  width: 100%;
  height: 1px;

  ${(props) =>
    props.rightLeft === "right" ? "margin-top: 0px" : "margin-top: 48px;"};

  background-color: #454752;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 36px;
  align-items: ${(props) => props.align};
  line-height: 120%;
  color: #ffffff;
  margin-bottom: 24px;
  div:first-child {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    color: #646d75;
  }

  @media (max-width: 1024px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const KidsHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 14px;
  margin-bottom: 12px;
`;
const KidsCards = styled(KidsHeader)`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
  @media (max-width: 630px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    justify-content: center;
  }
`;
const KidsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  font-size: 24px;
  color: #fff;
  white-space: nowrap;
  gap: 10px;
  grid-area: kids;
  width: 100%;
  @media (max-width: 630px) {
    justify-content: center;
    align-items: center;
    place-items: center;
  }
`;
const Flexer = styled.div`
  display: flex;
  @media (max-width: 630px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;
export default function ControlPanel({
  isEmailNotif,
  isKycVerified,
  isPhoneVerified,
}) {
  const { t } = useTranslation();
  const [kyc, setKyc] = useState(false);
  const newApiUser = useContext(NewApiContext);
  const { direction, align, rightLeft } = useContext(DirectionContext);
  const user = useContext(UserContext);
  const kids = newApiUser.children;

  useEffect(() => {
    let id = localStorage.getItem("id");

    $.ajax({
      type: "post",
      url: config.FILE_EXIST + "?fileName=" + id + ".jpg",
      data: {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
      },
      success: function (response) {
        // console.log(response);
        if (id !== null) {
          if (response.message === "File exists") {
            setKyc(true);
          } else {
            setKyc(false);
          }
        }
      },
      error: function (error) {
        console.error("Error:", error);
      },
    });
  }, []);

  return (
    <div>
      <TitleContainer align={align}>
        <div>{t("welcome")}</div>
        <SettingsModal
          isPhoneVerified={isPhoneVerified}
          isKycVerified={isKycVerified}
          isEmailNotif={isEmailNotif}
          userInfo={newApiUser}
          fileExist={kyc}
        />
      </TitleContainer>

      <PanelContainer direction={direction}>
        <Container rightLeft={rightLeft}>
          <div>{t("mydashboard")}</div>
          <ProfileContainer user={user} kyc={kyc} />
          <CertificateModal />
        </Container>
        <Flex>
          <CardpayButton kyc={kyc} setKyc={setKyc} />
          <CryptoButton kyc={kyc} setKyc={setKyc} />
          {t("or")}
          <Support />
        </Flex>
        <KidsWrapper>
          <KidsHeader>
            <img src="/assets/icons/family.svg" />
            <div>{t("familymembers")}</div>
            <Divider rightLeft={"right"} />
          </KidsHeader>

          <Flexer>
            <AddFamily />

            <KidsCards>
              {kids?.map((kid) => {
                return <KidsProfile kid={kid} father={newApiUser} />;
              })}
            </KidsCards>
          </Flexer>
        </KidsWrapper>
      </PanelContainer>

      <Divider rightLeft={"left"} />
    </div>
  );
}
