import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../../../langcheck";
import FiatModal from "./FiatModal";
import CryptoModal from "./CryptoModal";

const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  direction: ${(props) => props.direction};
  padding: 6px 12px;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
  gap: 8px;

  @media (max-width: 1024px) {
    padding: 8px 32px;

    font-weight: 800;
    font-size: 18px;
  }

  @media (max-width: 630px) {
    width: 90%;
  }
`;
const StyledButtonDisabled = styled.button`
  border: 1px solid ${(props) => (props.disabled ? "#999" : "#d71159")}; // Border is a lighter gray when disabled
  background-color: ${(props) =>
    props.disabled
      ? "#555"
      : "transparent"}; // Dark gray background for disabled state
  color: ${(props) =>
    props.disabled
      ? "#999"
      : "#fff"}; // Text color is a lighter gray when disabled
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  direction: ${(props) => props.direction};
  padding: 6px 12px;
  border-radius: 30px;
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : "pointer"}; // Cursor shows not-allowed icon when disabled
  transition: 0.2s;

  // No hover effect when the button is disabled
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "#555"
        : "rgba(215, 17, 89, 0.8)"}; // Hover effect for enabled state only
    color: ${(props) =>
      props.disabled
        ? "#999"
        : "#fff"}; // Text color remains the same when hovered
  }

  gap: 8px;

  @media (max-width: 1024px) {
    padding: 8px 32px;
    font-weight: 800;
    font-size: 18px;
  }

  @media (max-width: 630px) {
    width: 90%;
  }
`;

export default function CardpayButton({ kyc, setKyc }) {
  const [modal2Open, setModal2Open] = useState(false);
  const [amount, setAmount] = useState();
  const [meeting, setMeeting] = useState(false);
  const { t } = useTranslation();
  const Language = LanguageCheck();
  const [align, setAlign] = useState("");
  const [cryptoMod, setCryptoMod] = useState(false);
  const placeself = LanguageCheck() === "ar" ? "flex-end" : "flex-start";
  const direction = LanguageCheck() === "ar" ? "ltr" : "rtl";
  const [meetReq, setMeetReq] = useState({
    amount: 0,
    id: "",
    name: "",
    email: "",
    phone: "",
    lastname: "",
  });

  useEffect(() => {
    setMeetReq({
      amount: amount,
      id: localStorage.getItem("id"),
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      phone: localStorage.getItem("phone"),
      lastname: localStorage.getItem("lastname"),
    });
  }, [amount]);

  useEffect(() => {
    if (amount > 5000) {
      setMeeting(true);
    } else {
      setMeeting(false);
    }

    Language === "ar" ? setAlign("right") : setAlign("left");
  }, [amount]);

  const disabled = true;

  return (
    <>
      <StyledButtonDisabled
        direction={direction}
        align={placeself}
        type="primary"
         disabled={disabled}
        onClick={() => {
          if (!disabled) {
            setModal2Open(true);
          }
        }}
      >
        <img src="/assets/icons/mastercard.svg" alt="usdt" />
        {t("investnow")}
      </StyledButtonDisabled>
      <Modal
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false) || setCryptoMod(false)}
        onCancel={() => setModal2Open(false) || setCryptoMod(false)}
      >
        <FiatModal kyc={kyc} setKyc={setKyc} />
      </Modal>
    </>
  );
}
