import React, { useContext } from "react";
import styled from "styled-components";
import { useState } from "react";
import { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";
const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #fff;
  align-items: center;
  width: 100%;
  gap: 20px;
  height: 100px;
  margin-bottom: 140px;
  div:nth-child(1) {
    text-align: ${(props) => props.align};
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;
const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  padding: 10px 54px;
  font-weight: 800;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    padding: 12px 42px;
    height: 50px;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
  }
`;

const breatheAnimation = keyframes`
0% { opacity: 0;  }
99% { opacity: 1;  }
`;
const DownloadButton = styled.button`
  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  padding: 0px 24px;
  font-weight: 800;
  border-radius: 30px;
  animation-name: ${breatheAnimation};
  animation-duration: 2s;
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 24px;
  position: relative;
  top: 0px;
`;
export default function Download() {
  const { t } = useTranslation();
  const [isOpen, setIsopen] = useState(false);
  const { rightLeft } = useContext(DirectionContext);

  return (
    <section id="entrance">
      <Flexbox align={rightLeft}>
        <div>{t("downloadT")}</div>

        <StyledButton onClick={() => setIsopen(!isOpen)}>
          {t("downloadB")}
        </StyledButton>
        {isOpen && (
          <ButtonWrapper>
            <a href="https://drive.google.com/uc?id=1x1K7ngiryLA6ZU-npERroxH9bX7EaZle&export=download">
              <DownloadButton>
                <p> {t("downloadENG")}</p>
              </DownloadButton>
            </a>
            <a href="https://drive.google.com/uc?id=1ffPJw1ZZ_0aHkweDdupFyL8ixpS-qLXd&export=download">
              <DownloadButton>
                <p> {t("downloadAR")}</p>
              </DownloadButton>
            </a>
          </ButtonWrapper>
        )}
      </Flexbox>
    </section>
  );
}
