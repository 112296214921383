import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../langcheck";

const KycSuccesss = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #11cabe;
  border-radius: 14px;
  gap: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  padding: 8px 24px;
  font-size: 14px;
  direction: ${({ directon }) => directon};
  line-height: 26px;
`;

export default function KycSuccess() {
  const { t } = useTranslation();

  const direction = LanguageCheck() === "ar" ? "ltr" : "rtl";

  return (
    <KycSuccesss id="kidkycsuccess" directon={direction}>
      <img src="/assets/icons/tick.svg" alt="kyc" />
      {t("imageuploadedsuccessfully")}
    </KycSuccesss>
  );
}
