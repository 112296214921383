import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'


const Wrapper = styled.div`
display:flex;
width:100%;
justify-content:center;
text-align:center;
color:#ffad32;
${props => props.mt && `
    margin-top:${props.mt};
    `
    }
font-size:20px;
background-color: #1e1f25;
padding:.5rem 0rem;

@media (max-width: 1024px) {
    font-size:16px;
    line-height:1.2;
    padding:0.5rem 0rem;
}
`

export default function OperationNotif({ mt }) {
    const { t } = useTranslation();
    return (
        <Wrapper mt={mt}>
            <div style={{
                padding: "0rem 1rem"
            }}>
                {t('operate')}
            </div>
        </Wrapper>
    )
}
