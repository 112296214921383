import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'antd'
import styled from 'styled-components';
import $ from 'jquery';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { DirectionContext } from 'context/direction';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    padding: 14px 18px 0px 18px;
    color:#fff;
    direction: ${({ direction }) => direction};
    border-radius: 18px;
    background: #1E1F25;
    font-size: 24px;

    @media (max-width: 768px) {
        padding: 14px 14px;
        white-space: nowrap;
        margin-bottom:100px;
        }

`
const StyledTable = styled(Table)`
    
.ant-table-thead > tr > th {
    padding: 10px  !important;
    color: #5D6588 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.ant-table-tbody>tr>td{
    padding: 10px  !important;
    font-size: 14px !important;
    color: #fff !important;
}

.ant-table-tbody>tr>td:first-child {
    min-width: 180px !important;
    max-width: 181px !important;
    width: 180px !important;
}

.ant-table-tbody>tr>td:nth-child(2) {
    min-width: 140px !important;
    max-width: 141px !important;
    width: 180px !important;
}

.ant-table-tbody>tr>td:nth-child(3) {
    min-width: 160px !important;
    max-width: 161px !important;
    width: 160px !important;
}

.ant-table-tbody>tr>td:nth-child(4) {
    min-width: 220px !important;
    max-width: 221px !important;
    width: 160px !important;
}


@media (max-width: 768px) {


    .ant-table-tbody>tr>td:first-child {
        min-width: 140px !important;
        max-width: 141px !important;
        width: 140px !important;
    }
    
    .ant-table-tbody>tr>td:nth-child(2) {
        min-width: 80px !important;
        max-width: 81px !important;
        width: 80px !important;
    }
    
    .ant-table-tbody>tr>td:nth-child(3) {
        min-width: 160px !important;
        max-width: 161px !important;
        width: 160px !important;
    }
    
    .ant-table-tbody>tr>td:nth-child(4) {
        min-width: 220px !important;
        max-width: 221px !important;
        width: 160px !important;
    }
    
  
}

`

export default function ReferalTable() {
    const { t } = useTranslation();
    const { direction } = useContext(DirectionContext);
    const [reffered, setReffered] = useState([
        {
            name: "",
            lastname: "",
            country: "",
            phone: "",
            shares: "",
            id: ""
        }
    ]);
    useEffect(() => {
        $.ajax({
            type: 'POST',
            url: config.API_URL,
            data: {
                action: 'invited_user_fetch',
                page: 1,
                length: 1000,
                token: localStorage.getItem('token'),
                inviter: localStorage.getItem('id'),
                search: ""
            },
            success: function (response) {
                let resp = JSON.parse(response);
                setReffered(
                    resp.map((user, index) => {
                        return ({
                            name: user.name,
                            lastname: user.lastname,
                            //country: user.country,
                            phone: user.phone,
                            email: user.email,
                            shares: user.shares,
                            id: user.id
                        })
                    })
                )
            },
            error: function (error) {

                console.error('Error:', error);
            }
        })


    }, [])
    const dataSource = [
        reffered.slice().map((user, index) => {

            return ({
                key: index,
                name: user.name + " " + user.lastname,
                id: user.id,
                phone: user.phone,
                email: user.email,
                shares: user.shares,
            })
        })
    ]


    const columns = [
        {
            title: t('table1'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('accountid'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('contact'),
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: t('email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('table3'),
            dataIndex: 'shares',
            key: 'shares',
        },
    ];

    console.log(dataSource)


    return (
        <Container direction={direction}>

            <div>
                {(t('listofrefferal'))}
            </div>
            <StyledTable pagination={{ pageSize: 5 }}

                columns={columns}
                dataSource={dataSource[0]}

            />
        </Container>
    )
}
