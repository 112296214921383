let host = {
  host: process.env.REACT_APP_HOST,
  storage: process.env.REACT_APP_STORAGE,
};

export const config = {
  API_URL: host.host + process.env.REACT_APP_API_URL,
  API_URL2: host.host + process.env.REACT_APP_API_URL2,
  TABLE: host.host + process.env.REACT_APP_TABLE,
  RESET_PW: host.host + process.env.REACT_APP_RESET_PW,
  TOKEN_PW: host.host + process.env.REACT_APP_TOKEN_PW,
  GET_SSHARES: host.host + process.env.REACT_APP_GET_SSHARES,
  GET_1GULF: host.host + process.env.REACT_APP_GET_1GULF,
  USER_UPDATE: host.host + process.env.REACT_APP_USER_UPDATE,
  FIAT_PAY: host.host + process.env.REACT_APP_FIAT_PAY,
  WALLET_REG: host.host + process.env.REACT_APP_WALLET_REG,
  CRYPTO_PAY: host.host + process.env.REACT_APP_CRYPTO_PAY,
  progress: host.host + process.env.REACT_APP_progress,
  SECTION_VIEWS: host.host + process.env.REACT_APP_SECTION_VIEWS,
  EMAIL_VERIFY: host.host + process.env.REACT_APP_EMAIL_VERIFY,
  SEND_EMAIL: host.host + process.env.REACT_APP_SEND_EMAIL,
  BUNDLE_CARD: host.host + process.env.REACT_APP_BUNDLE_CARD,
  GET_BUNDLES: host.host + process.env.REACT_APP_GET_BUNDLES,
  BUNDLE_CRYPTO: host.host + process.env.REACT_APP_BUNDLE_CRYPTO,
  SUPPORT_CHECK: host.host + process.env.REACT_APP_SUPPORT_CHECK,
  KID_CRYPTO: host.host + process.env.REACT_APP_KID_CRYPTO,
  KID_CARD: host.host + process.env.REACT_APP_KID_CARD,
  USER_INFO: host.host + process.env.REACT_APP_USER_INFO,
  KID_REGISTER: host.host + process.env.REACT_APP_KID_REGISTER,
  KID_SHARES: host.host + process.env.REACT_APP_KID_SHARES,
  INC_IMGVERSION: host.host + process.env.REACT_APP_INC_IMGVERSION,
  TX_REFRESH: process.env.REACT_APP_TX_REFRESH,
  AVATAR_EXIST: host.storage + process.env.REACT_APP_AVATAR_EXIST,
  AVATAR_UPLOAD: host.storage + process.env.REACT_APP_AVATAR_UPLOAD,
  KYC_UPLOAD: host.storage + process.env.REACT_APP_KYC_UPLOAD,
  FILE_EXIST: host.storage + process.env.REACT_APP_FILE_EXIST,
  USER_AVATAR: host.storage + process.env.REACT_APP_USER_AVATAR,
  USER_AVATAR2: host.storage + process.env.REACT_APP_USER_AVATAR2 ,
  AVATAR_COMPRESSED: host.storage + process.env.REACT_APP_AVATAR_COMPRESSED,
  AVATAR_COMPRESSED2: host.storage + process.env.REACT_APP_AVATAR_COMPRESSED2,
  CANDIDATE_LIST: host.host + process.env.REACT_APP_CANDIDATE_LIST,
  GET_CANDIDATE: host.host + process.env.REACT_APP_GET_CANDIDATE,
  BE_CANDIDATE: host.host + process.env.REACT_APP_BE_CANDIDATE,
  CANDIDATE_IMG: host.storage + process.env.REACT_APP_CANDIDATE_IMG,
  CANDIDATE_FILES: host.storage + process.env.REACT_APP_CANDIDATE_FILES,
  CANDIDATE_VOTE: host.host + process.env.REACT_APP_CANDIDATE_VOTE,
  CANDIDATE_CERT_DOWN: host.storage + process.env.REACT_APP_CANDIDATE_CERT_DOWN,
  SHOULD_VOTE: host.host + process.env.REACT_APP_SHOULD_VOTE,
};
export default config;
