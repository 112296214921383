import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/dashboard/Navbar";

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #141518;

  background-image: url("/assets/dashbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 40px 190px 40px 250px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 900px) {
    padding: 40px 90px;
    background-position: unset;
  }
  @media (max-width: 500px) {
    padding: 40px 30px;
  }
`;

const Title = styled.text`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  direction: rtl;
  gap: 20px;
  p {
    margin: 6px 0px;
  }

  h2 {
    margin: 8px 0px;
  }

  img {
    aspect-ratio: 11/5;
    border-radius: 16px;
    max-width: 80%;
  }
  @media (max-width: 900px) {
    img {
      max-width: 100%;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function FirstCryptoBank() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <Navbar />
      <NewsWrapper>
        <Wrapper>
          <Header>
            <Title> البحرين تمنح بنك الخليج الرقمي جائزة أول بنك رقمي عربي</Title>
            {/* <Persona>By Globe Newswire</Persona> */}
          </Header>
          <figure class="wp-block-image aligncenter size-large">
            <img
              src="https://i0.wp.com/rowadeleqtsad.com/wp-content/uploads/2023/02/IMG_20230218_233804.jpg?resize=780%2C470&amp;ssl=1"
              alt=""
            />
          </figure>

          <p class="has-text-align-right">
            اختتمت فعاليات مؤتمر قمة التكنولوجيا المالية والعملات المشفرة والذي
            احتضنته العاصمة البحرينية المنامة خلال الفترة من 15-16 فبراير
          </p>

          <p class="has-text-align-right">
            وقد حصد <strong>بنك الخليج الرقمي</strong> لقب جائزة أول بنك رقمي
            عربي خلال مشاركته الفعالة في الموتمر
          </p>

          <p class="has-text-align-right">
            كان المعرض قد انطلق في الخامس عشر من فبراير الجاري بالبحرين
          </p>

          <p class="has-text-align-right">
            وشهد حضورً جماهيريًا كبيرًا منذ الساعات الأولى لإنطلاقه رغم حداثة
            هذا المجال لدى الكثيرين
          </p>

          <p class="has-text-align-right">
            سعى بنك الخليج الرقمي خلال مشاركته في المؤتمر إلى أهمية نشر ثقافة
            التكنولوجيا المالية حول العالم العربي وزيادة الاهتمام بالعملات
            المشفرة وتكنولوجيا التداول المالي في الشرق الأوسط
          </p>

          <p class="has-text-align-right">
            <strong>بنك الخليج الرقمي</strong> هو مشروع طوّرته شركة “سوفت وير
            اند تكنولوجي كمشروع بنكي متكامل
          </p>

          <p class="has-text-align-right">
            ذو خدمات رقمية متطورة ، تربط ما بين خدمات واحتياجات المستخدم على
            شبكة الإنترنت وأرض الواقع
          </p>

          <p class="has-text-align-right">
            ‏وأكّد هاني الأصفر – المدير التنفيذي للمشروع – في كلمته التي ألقاها
            على الحضور في المؤتمر أن العالم الرقمي وعالم العُملات المشفرة بات
            أمراً واقعاً لا يمكن تجاهله
          </p>

          <p class="has-text-align-right">
            كما أصبح سوق مالي ضخم مؤثر في المعادلة المالية والإقتصادية الدولية،
            وبالتالي أهمية الحاجة لمثل هذه المشاريع
          </p>

          <p class="has-text-align-right">
            وأوضح أن البنك سيكون جاهزاً لإطلاق خدماته الكاملة بداية النصف الثاني
            من هذا العام ، بعد انتهاء واكتمال اكتتاب طرح الأسهم
          </p>

          <p class="has-text-align-right">
            وأنه سيكون من حق المستخدمين استخدام المنصة الخاصة للبنك لإجراء
            المعاملات التجارية والبنكية المختلفة
          </p>

          <p class="has-text-align-right">
            الجدير بالذكر أن الشركة المطورة للمشروع أعلنت أنها تسعى لتطوير كافة
            الخدمات المصرفية المتاحة للجمهور من خلال منصة تقنية قوية
          </p>

          <p class="has-text-align-right">
            تسمح للمستخدمين بـ إيداع الأموال بجميع فئاتها النقدية المختلفة
            “الدولار ، اليورو ، الباوند، إلخ
          </p>

          <p class="has-text-align-right">
            ومعها الكثير من العملات العربية والعالمية. وكذلك فهو يتيح بـتحويلها
            من حساب لآخر أو إلى أي مكان في العالم ، بلا أي حدود ، وبِحُريّة
            مُطلقة ، ومن خلال بيئة مالية رقمية آمنة
          </p>

          <p class="has-text-align-right">
            كما يمكن للمستخدم استخراج بطاقة صراف آلي صادرة من{" "}
            <strong>
              <a href="https://gulfcryptobankshares.com/">بنك الخليج الرقمي</a>
            </strong>{" "}
            يستطيع من خلالها المُستخدم سحب الأموال النقدية من رصيده من أي صرّاف
            آلي موجود في العالم
          </p>

          <p class="has-text-align-right">
            علاوةً على إجراء كافة المشتريات من أي محل أو مركز تجاري، والشراء
            أونلاين على كافة المواقع والمتاجر الإلكترونية
          </p>

          <p class="has-text-align-right">
            ويؤكد الخبراء أن هذا المشروع يعد واحداً من أقوى 10 مشاريع رقمية في
            الشرق الأوسط في المرحلة القادمة
          </p>

          <p class="has-text-align-right">
            وخاصةً مع تطور العالم في مجال التكنولوجيا المالية وما يتبعها من
            احتياجات وخدمات رقمية وربطها بأرض الواقع
          </p>

          <p class="has-text-align-right">
            <a href="https://rowadeleqtsad.com/%D8%A7%D9%84%D8%A8%D8%AD%D8%B1%D9%8A%D9%86-%D8%AA%D9%85%D9%86%D8%AD-%D8%A7%D9%84%D8%AE%D9%84%D9%8A%D8%AC-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A-%D8%AC%D8%A7%D8%A6%D8%B2%D8%A9-%D8%A3%D9%88%D9%84/">
              {" "}
              rowadeleqtsad.com المصدر
            </a>
          </p>
        </Wrapper>
      </NewsWrapper>
    </>
  );
}
