import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";

const Signoutt = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  img {
    max-width: 40px;
    max-height: 40px;
  }
`;
const SignoutButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  direction: ${(props) => props.direction};
  line-height: 30px;
  cursor: pointer;

  ${(props) =>
    props.direction === "rtl" &&
    `
    img{
        transform: rotate(180deg);
    }
    `}

  @media (max-width: 768px) {
    gap: 2px;
    img {
      margin: 0px !important;
    }
  }
`;
export default function Signout() {
  const { t } = useTranslation();
  const { direction } = useContext(DirectionContext);

  function signout() {
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <Signoutt>
      <SignoutButton direction={direction} onClick={() => signout()}>
        <div> {t("signout")}</div>
        <img src="/assets/icons/signout.svg" alt="signout" />
      </SignoutButton>
    </Signoutt>
  );
}
