import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Calculator from "../components/Calculator";
import { StyledLink } from "../components/styled";
import { DirectionContext } from "context/direction";

const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 110%;
  @media (max-width: 1024px) {
    font-size: 40px;
    padding: 0px 24px;
    margin-top: 24px;
  }
`;
const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 22px;
  padding: 4px 48px;
  font-weight: 800;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    padding: 6px 30px;
    height: 50px;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
  }
`;
const EntranceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: ${(props) => props.direction};
  background-image: url("../assets/entrance.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: auto;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  place-items: center;
  align-items: center;
  min-height: 94vh;
  @media (max-width: 1100px) {
    display: flex;

    flex-direction: column-reverse;
    padding: 34px 0px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.rightLeft};
  text-align: ${(props) => props.rightLeft};
  width: 68%;
  color: #fff;
  font-size: 20px;
  align-items: right;
  font-weight: 400;
  line-height: 32px;
  direction: ${(props) => props.direction};
  gap: 24px;
  button {
    height: 64px;
    width: 320px;
    font-size: 22px;
  }

  h4 {
    font-weight: 500;
    margin: 0px;
  }

  @media (max-width: 1100px) {
    gap:6px;
    width: 80%;
    font-size: 18px;
    align-items: center;
    text-align: center !important;
    button {
      width: 240px;
      height: 64px;
      font-size: 22px;
    }
  }
`;
export default function Entrance() {
  const [route, setRoute] = useState();
  const { t } = useTranslation();
  const { direction, rightLeft } = useContext(DirectionContext);

  const [buttonName, setButtonName] = useState();
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setRoute("/dashboard");
      setButtonName(t("dashboard"));
    } else {
      setRoute("/login");
      setButtonName(t("nButton"));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setRoute("/dashboard");
    } else {
      setRoute("/login");
    }
  }, []);


  return (
    <EntranceWrapper direction={direction}>
      <Content rightLeft={rightLeft} direction={direction}>
        <Title>{t("eTitle")}</Title>
        <h4>{t("eText")}</h4>

        <StyledLink to={route}>
          <StyledButton>{buttonName}</StyledButton>
        </StyledLink>
      </Content>
      <Calculator />
    </EntranceWrapper>
  );
}
