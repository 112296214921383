import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const params = new URLSearchParams(window.location.search);
  let langParam = params.get("lang");

  const changeLanguage = (lang) => {
    if (langParam) {
      params.set("lang", lang); // Set the new value for "lang"
      window.location.search = params.toString();
    }
    
    i18n.changeLanguage(lang);
    localStorage.setItem("selectedLanguage", lang);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const checkParam = (lang) => {
    i18n.changeLanguage(lang);
    if (selectedLanguage !== langParam) {
      localStorage.setItem("selectedLanguage", lang);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (langParam) {
      checkParam(langParam);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ changeLanguage, language }}>
      {children}
    </LanguageContext.Provider>
  );
};
