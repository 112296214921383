import Navbar from "../components/Navbar";
import styled from "styled-components";
import React, { useEffect, useContext, lazy, Suspense, useState } from "react";
import Entrance from "../sections/Entrance";
import Progressbar from "../sections/Progressbar";
import CardSection from "../sections/CardSection";
import Carrousel from "../sections/Carrousel";
import Download from "../sections/Download";
import News from "../sections/News";
import Video from "../sections/Video";
import Table from "../components/Table";
import LazyLoad from "react-lazy-load";
import VideoCard from "components/dashboard/VideoCard";
import SocialSection from "components/dashboard/SocialSection";
// import License from "components/License";
import Telegrampopup from "components/Telegrampopup";
import PersonalCheckPopup from "components/PersonelCheckPopup";
import useBubbleRemover from "hooks";
import Votepopup from "components/Noshareepopup";
import Nosharepopup from "components/Votepopup";
import Notification from "components/Notification";
import OperationNotif from "components/OperationNotif";
const PageWrapper = styled.div`
  padding: 0px 260px;

  @media (max-width: 1324px) {
    padding: 0px 140px;
  }
  @media (max-width: 1024px) {
    padding: 0px 25px;
  }
`;
const Logo = styled.button`
  display: flex;
  justify-content: center;
  margin: 120px auto 40px auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const Effect = styled.div`
  position: absolute;
  background-image: url("/assets/effect.svg");
  background-repeat: no-repeat;
  overflow: hidden !important;
  width: 50vw;
  right: 0px;
  height: 100vw;
  z-index: -1;
`;
const Effect2 = styled.div`
  position: absolute;
  background-image: url("/assets/effect.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: contain;
  width: 80vw;
  height: 90vw;
  left: -40vw;
  right: 0px;
  z-index: -1;
  @media (max-width: 1024px) {
    left: 0px;
    height: 180vw;
  }
`;
const ProgressSect = styled.section`
  position: relative;
  top: -20px;
  @media (max-width: 1024px) {
    top: -100px;
  }
`;
const CarrouselSect = styled.section`
  position: relative;
  top: -140px;
  @media (max-width: 1024px) {
    top: -80px;
  }
`;

const Sect = styled.section`
  position: relative;
  top: -90px;
  @media (max-width: 1024px) {
    bottom: 40px;
  }
`;
const Licenssect = styled.section`
  position: relative;
  top: -90px;
  @media (max-width: 1024px) {
    bottom: 40px;
  }
`;
/* In your CSS file */
const MobileSpacing = styled.div`
  display: none; /* Hide by default */

  @media (max-width: 1024px) {
    display: block; /* Show on mobile */
    margin-top: 10px;
  }
`;


function LandingPage() {
  const [hasSharePopup, setHasSharePopup] = useState(true);
  useBubbleRemover(true);
  const [viewPorts, setViewPorts] = React.useState({
    progressbar: "calc(100vh - 80px)",
    socialsection: "70vh",
    video: "90vh",
    table: "120vh",
    cardsection: "130vh",
    // license: "",
    carrousel: "100vh",
    download: "",
    news: "",
    social: "",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1100) {
        //mobile
        setViewPorts({
          progressbar: "calc(100vh - 80px)",
          socialsection: "70vh",
          video: "70vh",
          table: "120vh",
          cardsection: "90vh",
          carrousel: "90vh",
          download: "",
          news: "",
          social: "",
          // license: "100vh",
        });
      } else {
        setViewPorts({
          progressbar: "calc(100vh - 80px)",
          socialsection: "70vh",
          video: "90vh",
          table: "130vh",
          cardsection: "130vh",
          carrousel: "90vh",
          download: "",
          news: "",
          social: "",
          // license: "70vh",
        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let hash = window.location.hash;
    if (!hash) return;
    const section = document.querySelector(hash);

    if (section) {
      section.scrollIntoView();

      setTimeout(() => {
        section.scrollIntoView();
      }, 800);

      setTimeout(() => {
        section.scrollIntoView();
      }, 1300);
    }
  }, []);


  return (
    <>
      <Navbar />
      <OperationNotif mt={"80px"} />

      <Entrance />
      {/* <Votepopup setHasSharePopup={setHasSharePopup} /> */}
      {/* <PersonalCheckPopup /> */}
      <PageWrapper>
        <ProgressSect id="progressbar"></ProgressSect>
        <LazyLoad
          height={viewPorts.progressbar}
          width={"100%"}
          threshold={0.35}
          className="component_animation"
        >
          <Progressbar />
        </LazyLoad>

        <LazyLoad
          height={viewPorts.socialsection}
          width={"100%"}
          threshold={0.25}
          className="component_animation"
        >
          <SocialSection />
        </LazyLoad>

        <Effect />

        <LazyLoad  
       
          height={viewPorts.video}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <Video />
       
        </LazyLoad>
<MobileSpacing>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
</MobileSpacing>

        <LazyLoad 
        
          height={viewPorts.table}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <Table />
        </LazyLoad>

        {/* <Licenssect id="license"></Licenssect> */}
        {/* <LazyLoad
          height={viewPorts.license}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <License />
        </LazyLoad> */}

        <Sect id="cards"></Sect>
        <LazyLoad
          height={viewPorts.cardsection}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <CardSection />
        </LazyLoad>

        <Effect2 />
        <CarrouselSect id="carrousel" />
        <LazyLoad
          height={viewPorts.carrousel}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <Carrousel />
        </LazyLoad>

        <LazyLoad
          height={"max-content"}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <Download />
        </LazyLoad>

        <LazyLoad
          height={"max-content"}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <VideoCard />
        </LazyLoad>

        <LazyLoad
          height={"max-content"}
          width={"100%"}
          threshold={0.05}
          className="component_animation"
        >
          <News />
        </LazyLoad>

        <Logo onClick={() => window.scrollTo(0, 0)}>
          <img src="/assets/rightlogo.svg" alt="logo" />
        </Logo>
      </PageWrapper>
    </>
  );
}

export default LandingPage;
