import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: ${props => props.column && "column"};
    justify-content: ${props => props.justify};
    margin: 0 auto;
    gap: ${props => props.gap};
    `

export const Flex = styled.div`
    display: flex;
    align-items: ${props => props.alignItem};
    justify-content: ${props => props.justify};
    flex-direction: ${props => props.column && "column"};
    gap: ${props => props.gap};
    direction: ${props => props.direction};
    text-align: ${props => props.align};
    flex-wrap: ${props => props.wrap};
    width: ${props => props.width};
    `
export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${props => props.gap};
    direction: ${props => props.direction};
    text-align: ${props => props.align};
    `