import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Progress } from "antd";
import Buttons from "../components/Buttons";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import GulfvsDolar from "../components/GulfvsDolar";
import { StyledLink } from "../components/styled";
import { DirectionContext } from "context/direction";
import config from "config";
import Shareprice from "components/Shareprice";
import { formatNumber } from "FormatNumber";
import { PriceContext } from "context/prices";

const Effect = styled.div`
  position: absolute;
  background-image: url("/assets/effect.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  width: 90vw;
  height: 80vw;
  overflow: hidden !important;
  left: -540px;
  z-index: -1;
  @media (max-width: 1024px) {
    left: 0px;
    height: 180vw;
  }
`;

const EntranceWrapper = styled.div`
  display: flex;
  background-repeat: no-repeat;
  background-position: 600% 50%;
  height: calc(100vh - 80px);
  width: 100%;
  gap: 40px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  .ant-progress-bg {
    background-color: #d71159;
    height: 14px !important;
  }
  .ant-progress-inner {
    background-color: #dbd8d0;
  }
  .ant-progress-outer {
    margin-inline-end: 0px !important;
    padding-inline-end: 0px !important;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 200px;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
width: 100%;
  div {
    color: #fff;
    align-self: flex-end;
    line-height: 1.5;
  }
  .ant-progress-line {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    span {
      color: #fff;
      font-size: 32px;
    }
    @media (max-width: 1024px) {
      width: 100% !important;
      span {
        color: #fff;
        font-size: 24px;
      }
    }
  }
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  ${(props) => props.row && "flex-direction: row; width: 100%;"}
  color: #fff;
  gap: 12px;
  div {
    justify-content: flex-start;
  }
  p {
    img {
      width: 1rem;
      height: 1rem;
    }
  }

  font-weight: 700;

  @media (max-width: 480px) {
    gap: 8px;
  }
  @media (max-width: 380px) {
  }
`;
const Box2 = styled(Box)`
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: flex-end;
  color: #fff;
  top: 40px;

  &:first-child {
    font-size: 24px;
  }
  @media (max-width: 1024px) {
    &:first-child {
      font-size: 20px;
    }
  }
`;

const Sect = styled.section`
  position: relative;
  top: -420px;
  @media (max-width: 1024px) {
    top: -100px;
  }
`;
const PriceContainer = styled.div`
display: flex;
align-items: center;
font-size: 54px;
color: #fff;
direction: ${(props) => props.direction}
font-weight: 800;
@media (max-width: 1024px) {
  font-size: 36px;
  img {
    width: 32px;
    height: 40px;
  }
}
`;

export default function Second() {
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [route, setRoute] = useState();
  const { direction } = useContext(DirectionContext);
  const { sharePrice } = useContext(PriceContext);
  const totalShares = total - 510;
  const million = 1000000;

  const alreadyInvested = formatNumber(totalShares * sharePrice * million);
  useEffect(() => {
    $.ajax({
      type: "GET",
      url: config.progress,

      success: function (response) {
        setProgress(response * 100);
        setTotal(response * 1000);
      },
      error: function (error) {
        console.error("Error:", error);
      },
    });
  }, []);
  let done = false;
  useEffect(() => {
    var settings = {
      url: config.SECTION_VIEWS,
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        section: "Progressbar",
      },
    };
    if (done === false) {
      $.ajax(settings).done(function (response) { });
    }
    done = true;
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setRoute("/dashboard");
    } else {
      setRoute("/login");
    }
  }, []);

  return (
    <>
      <Effect></Effect>

      <EntranceWrapper>
        <Sect id="progressbar"></Sect>

        <ProgressWrapper>
          <Container>
            <div>{t("pTitle")}</div>
            <PriceContainer direction={direction}>
              <img src="../assets/gulf-inner.svg" alt="gulf" />{" "}
              <span> {t("pText")} </span>
              <span> &nbsp; </span> {parseInt(total)}
            </PriceContainer>
          </Container>

          <Progress percent={parseInt(progress)} />

          <Box row={true}>
            <Shareprice />
            <GulfvsDolar />


          </Box>
        </ProgressWrapper>

        <Box2>
          <a
            href="https://gulfofficial.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="../assets/gulf.svg" alt="gulf" />
          </a>
          <StyledLink to={route}>
            <Buttons transparent={true} text={t("pButton")} />
          </StyledLink>
        </Box2>
      </EntranceWrapper>
    </>
  );
}
