import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CryptoPayModal from "./CryptoPayModal";
import { AnimationContext } from "context/animation";
import { SuccessAnimation } from "components/SuccessAnimation";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 0rem;
  color: #fff;
  text-align: center;
  gap: 40px;
  transition: 0.2s;
`;
export default function CryptoModal() {
  const { t } = useTranslation();
  const { phase, setPhase } = useContext(AnimationContext);

  const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return (
    <>
      {phase === "pay" ? (
        <CryptoPayModal />
      ) : phase === "loading" ? (
        <MessageWrapper>
          <Spin indicator={antIcon} />
          <h1>{t("processing")}</h1>
        </MessageWrapper>
      ) : phase === "success" ? (
        <MessageWrapper>
          <SuccessAnimation />
          <h1>{t("paymentsuccess")}</h1>
        </MessageWrapper>
      ) : null}
    </>
  );
}
