import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #141518;

  background-image: url("/assets/dashbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 40px 190px 40px 250px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 900px) {
    padding: 40px 90px;
    background-position: unset;
  }
  @media (max-width: 500px) {
    padding: 40px 30px;
  }
`;

const Title = styled.text`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  direction: rtl;
  gap: 20px;
  figure {
    margin: 0px;
  }
  p {
    margin: 6px 0px;
  }

  h2 {
    margin: 8px 0px;
  }

  img {
    aspect-ratio: 11/5;
    border-radius: 16px;
    max-width: 80%;
  }
  @media (max-width: 900px) {
    img {
      max-width: 100%;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:80px;
`;

export default function Article3() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <Navbar />
      <NewsWrapper>
        <Wrapper>
          <Header>
            <Title> بنك الخليج الرقمي يعلن عن مفاجأة للعملات الرقمية</Title>
            {/* <Persona>By Globe Newswire</Persona> */}
          </Header>
          <h2 class="wp-block-heading has-text-align-right">
            <strong>
              بنك الخليج الرقمي يرفع الستار عن أول بطاقة (جي سي بي)
            </strong>
          </h2>

          <figure class="wp-block-image aligncenter size-large">
            <img
              src="https://i0.wp.com/rowadeleqtsad.com/wp-content/uploads/2023/02/IMG_20230218_233804.jpg?resize=780%2C470&amp;ssl=1"
              alt="بنك الخليج الرقمي"
            />
          </figure>

          <p class="has-text-align-right">
            يأتي{" "}
            <em>
              <strong>
                <a href="https://gulfcryptobankshares.com/">بنك الخليج</a>{" "}
                الرقمي
              </strong>
            </em>{" "}
            كمثال نموذجي على الابتكار والتقدم في مجال الخدمات البنكية الرقمية ;
            حيث يعد أحد أبرز المشاريع الرقمية في العالم العربي، وتم تصنيفه من
            أقوى عشر مشاريع في العالم العربي لعام 2023 وفقًا لتقييم أهم المجلات
            الاقتصادية.
          </p>

          <p class="has-text-align-right">
            بعد طول انتظار، أعلن المستشار هاني الأصفر المدير التنفيذي لشركة جالف
            سوفتوير اند تيكنولوجي عن إصدار اول بطاقة (جي سي بي) وذلك من أجل قبول
            عمليات الدفع عبر أجهزة نقاط البيع والتجارة الإلكترونية بشكل رسمي.
          </p>

          <p class="has-text-align-right">
            تحتوي هذه البطاقات على تصميم فريد يجسد روح العالم الرقمي ويعكس مفهوم
            التكنولوجيا والابتكار وتم تصميم غلاف خاص لهذه البطاقة، ليتم توزيعها
            على عملاء بنك الخليج الرقمي بالطرق المناسبة.
          </p>

          <p class="has-text-align-right">
            يمكن الاعتماد على التصميم المبتكر للبطاقة كوسيلة لتعزيز هوية البنك
            الرقمية وإيصال رسالة بأن البنك يسعى دائمًا لتقديم الابتكار والتميز
            في خدماته المصرفية الرقمية.
          </p>

          <p class="has-text-align-right">
            ومن المؤكد أن هذا الإعلان الرسمي هو بداية لمرحلة جديدة من النمو
            والتفوق لبنك الخليج. ومع التزامهم المتواصل بتقديم الخدمات المبتكرة
            والتطورات الجديدة، فإن البنك يستعد للمستقبل بثقة وتصميم.
          </p>

          <p class="has-text-align-right">
            كما تم الإعلان عن عقد بث مباشر في المستقبل القريب،<strong> </strong>
            حيث سيتم استضافة السيد جورج زهاريف، المدير التنفيذي ل
            <a
              href="https://gulfcryptobankshares.com/%D8%A8%D9%86%D9%83-%D8%A7%D9%84%D8%AE%D9%84%D9%8A%D8%AC-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A"
              target="_blank"
              rel="noreferrer noopener"
            >
              مشروع بنك الخليج
            </a>
            ، سيتم من خلال هذا اللقاء مناقشة أحدث التطورات التكنولوجية والخدمات
            المبتكرة لبنك الخليج.
          </p>

          <p class="has-text-align-right">المصدر </p>

          <p class="has-text-align-right">
            <a
              href="https://sa.investing.com/news/cryptocurrency-news/article-2414409"
              target="_blank"
              rel="noreferrer noopener"
            >
              investing
            </a>
          </p>
        </Wrapper>
      </NewsWrapper>
    </>
  );
}
