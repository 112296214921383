export const MockUsers = [
  {
    id: "11",
    name: "Gokhan",
    lastname: "1",
    shares: "1515155",
  },
  {
    id: "22",
    name: "Brinis",
    lastname: "2",
    shares: "1515155",
  },
  {
    id: "33",
    name: "Mehdi",
    lastname: "3",
    shares: "1515155",
  },
  {
    id: "44",
    name: "Gokhan",
    lastname: "4",
    shares: "1515155",
  },
  {
    id: "55",
    name: "Brinis",
    lastname: "5",
    shares: "1515155",
  },
  {
    id: "66",
    name: "Mehdi",
    lastname: "6",
    shares: "1515155",
  },
  {
    id: "77",
    name: "Gokhan",
    lastname: "7",
    shares: "1515155",
  },
  {
    id: "88",
    name: "Brinis",
    lastname: "8",
    shares: "1515155",
  },
  {
    id: "99",
    name: "Mehdi",
    lastname: "9",
    shares: "1515155",
  },
  {
    id: "101",
    name: "Brinis",
    lastname: "10",
    shares: "1515155",
  },
  {
    id: "101",
    name: "Brinis",
    lastname: "11",
    shares: "1515155",
  },
  {
    id: "102",
    name: "Brinis",
    lastname: "12",
    shares: "1515155",
  },
  {
    id: "103",
    name: "Brinis",
    lastname: "13",
    shares: "1515155",
  },
  {
    id: "104",
    name: "Brinis",
    lastname: "14",
    shares: "1515155",
  },
  {
    id: "105",
    name: "Mehdi",
    lastname: "15",
    shares: "1515155",
  },
  {
    id: "101",
    name: "Brinis",
    lastname: "16",
    shares: "1515155",
  },
  {
    id: "121",
    name: "Mehdi",
    lastname: "17",
    shares: "1515155",
  },
  {
    id: "101",
    name: "Brinis",
    lastname: "Abderrahman",
    shares: "1515155",
  },
  {
    id: "106",
    name: "Mehdi",
    lastname: "19",
    shares: "1515155",
  },
  {
    id: "107",
    name: "Brinis",
    lastname: "20",
    shares: "1515155",
  },
  {
    id: "108",
    name: "Mehdi",
    lastname: "21",
    shares: "1515155",
  },
  {
    id: "109",
    name: "Brinis",
    lastname: "22",
    shares: "1515155",
  },
  {
    id: "110",
    name: "Mehdi",
    lastname: "23",
    shares: "1515155",
  },
];
