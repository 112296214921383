import React, { useContext, useEffect, useState } from "react";
import { erc20ABI } from "../../../ABIs/ABI";
import $ from "jquery";
import styled from "styled-components";
import { StyledInput } from "../../styled";
import config from "../../../config";
import { ethers } from "ethers";
import { ModalContext } from "context/modals";
import { AnimationContext } from "context/animation";
import LanguageCheck from "langcheck";
import { useTranslation } from "react-i18next";
import { useAccount, useSigner } from "wagmi";
import { Web3Button } from "@web3modal/react";
import { Flex } from "components/Layouts";
import { PriceContext } from "context/prices";

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 24px;
  text-align: center;
  color: #fff;
`;

const Wrapper = styled.div`
  display: grid;
  font-family: "Cairo", sans-serif;
  grid-template-rows: 1fr 0.5fr 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
  text-align: center;
  grid-template-columns: 1fr;
  padding: 36px 24px;
  button {
    margin-top: 24px;
  }
  @media (max-width: 1024px) {
    padding: 24px 12px;
    grid-gap: 4px;
  }
`;
const SOption = styled.option`
  &:hover {
    color: #000;
    background-color: #fff !important;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${(props) => props.align};
  gap: 4px;

  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  select {
    background: #1d1f25;
    border: 1.5px solid #34384c;
    border-radius: 8px;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: ${(props) => props.align};
    padding: 8px 10px;
    option:hover {
      background: #fff !important;
      color: black !important;
    }

    :focus {
      outline: none;
    }
  }
`;
const SButton = styled.button`
  border: 1px solid #d71159;
  position: relative;
  background-color: ${(props) =>
    props.transparent ? "transparent" : "#D71159"};
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    tr
  }
`;
const FlexBox = styled(Flex)`
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;
var first = true;
export default function CryptoPayModal() {
  const { t } = useTranslation();
  const { gulfPrice, sharePrice } = useContext(PriceContext);
  const { modal2Open, setModal2Open } = useContext(ModalContext);
  const { phase, setPhase } = useContext(AnimationContext);
  const abi = erc20ABI; // replace with your contract ABI
  var userAddress;
  const [contract, setContract] = useState();
  var balance;
  var coeff = 1;
  const [isMobile, setIsMobile] = useState(false);
  const [changed, setChanged] = useState(false);
  var ethereum = useSigner();
  const { data: signer, isError, isLoading } = useSigner();
  var account = useAccount();
  let uAddress = account.address;

  useEffect(() => {
    document.getElementById("buy").className = "disabled-button";

    if (uAddress != undefined) {
      console.log("uAddress = " + uAddress);
      document.getElementById("buy").className = "enabled-button";

      updateInputs(uAddress);
      OnInputChenged(uAddress);
      $.ajax({
        type: "POST",
        url: config.WALLET_REG,
        data: {
          userAddress: uAddress,
          userId: localStorage.getItem("id"),
          token: localStorage.getItem("token"),
        },
        dataType: "json",
        success: function (response) {
          // console.log(response);
          localStorage.setItem("address", userAddress);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("AJAX call failed: " + textStatus + ", " + errorThrown);
        },
      });
    }
  }, [signer]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(/iphone|ipad|ipod|android/.test(userAgent));
  }, []);

  function onTransactionComplete(receipt) {
    $.ajax({
      type: "POST",
      url: config.CRYPTO_PAY + "?userId=" + localStorage.getItem("id"),
      data: {
        hash: receipt.transactionHash,
        userId: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
      },
    }).done(function (msg) {
      // console.log(msg);
      setPhase("success");
      // console.log(phase);
      setTimeout(() => {
        window.location.reload();
      }, 700);
    });
  }

  var tokenSelect = document.getElementById("token");
  var balanceInput = document.getElementById("balance");
  var amountInput = document.getElementById("amount");
  var itemsInput = document.getElementById("items");
  var buyButton = document.getElementById("buy");
  var provider;

  async function Main(ethereum, account) {
    if (ethereum == null) return;
    //alert("main");
    await sleep(100);
    // await InitMetaMask();
    let uAddress = account.address;

    await updateInputs(uAddress);

    // await ethereum.enable();
    provider = ethereum.data.provider;

    // setSigner(gokhan);

    //signer = useSigner();

    // userAddress = await signer.getAddress();

    await updateInputs(uAddress);
    // console.log(`Address: ${userAddress}`);
    let userId = localStorage.getItem("id");
    let addresinLocal = localStorage.getItem("address");
    // Make the AJAX call
    let currentNetworkId;
    currentNetworkId = await ethereum.request({ method: "eth_chainId" });

    // Check if the current network ID matches the ID of the network we want to switch to
    if (currentNetworkId !== "0x38") {
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x38" }],
        });

        setModal2Open(true);
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set("openModal", "true");
        window.location.href = currentUrl.toString();
      } catch (error) {
        // Handle the error if the user did not switch networks
        console.error("error = " + error);
      }
    }

    if (addresinLocal === null) {
      $.ajax({
        type: "POST",
        url: config.WALLET_REG,
        data: {
          userAddress: uAddress,
          userId: userId,
          token: localStorage.getItem("token"),
        },
        dataType: "json",
        success: function (response) {
          // console.log(response);
          localStorage.setItem("address", userAddress);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("AJAX call failed: " + textStatus + ", " + errorThrown);
        },
      });
    }

    tokenSelect.selectedIndex = 2;
    await sleep(100);
    await updateInputs(uAddress);
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function updateInputs(uAddress) {
    if (tokenSelect.value === "0x55d398326f99059fF775485246999027B3197955")
      coeff = 1 / sharePrice;
    else if (tokenSelect.value === "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56")
      coeff = 1 / sharePrice;
    else if (tokenSelect.value === "0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a")
      coeff = gulfPrice / sharePrice;
    let cont = new ethers.Contract(tokenSelect.value, abi, signer);
    // console.log(signer);
    setContract(cont);
    balance = await cont.balanceOf(uAddress);
    balanceInput.value = ethers.utils.formatUnits(balance, 18);
    // console.log(balance);
  }

  //updateInputs();
  async function OnInputChenged(uAddress) {
    balance = 999999;
    let cont = new ethers.Contract(tokenSelect.value, abi, signer);
    balance = await cont.balanceOf(uAddress);
    setChanged(!changed);
    // console.log(balance);

    try {
      //   console.log("userAddress = " + userAddress)
      if (uAddress === undefined) {
        await Main(ethereum, account);
      }
    } catch {
      console.log("error");
    }
    const amount = ethers.utils.parseUnits(amountInput.value, 18);
    const rate = coeff; // replace with your rate

    const items = amountInput.value * coeff; // ethers.utils.formatUnits(amount.mul(rate), 0);
    // console.log("amount= " + amount + " balance= " + balance);
    if (amount.gt(balance)) {
      itemsInput.value = "Insufficient balance";
      buyButton.disabled = true;
      document.getElementById("buy").className = "disabled-button";
    } else {
      itemsInput.value = items;
      buyButton.disabled = false;
      document.getElementById("buy").className = "enabled-button";
    }
  }

  useEffect(() => {
    tokenSelect = document.getElementById("token");
    balanceInput = document.getElementById("balance");
    amountInput = document.getElementById("amount");
    itemsInput = document.getElementById("items");
    buyButton = document.getElementById("buy");
    updateInputs(uAddress);

    OnInputChenged(uAddress);
    // This code will be executed once when the component is mounted
    first = false;
    // console.log("first");

    Main(ethereum, account);
    amountInput.addEventListener("input", async () => {
      await OnInputChenged(uAddress);
    });

    tokenSelect.addEventListener("change", async () => {
      if (uAddress === undefined) await Main(ethereum, account);
      await updateInputs(uAddress);
      return await OnInputChenged(uAddress);
    });

    buyButton.addEventListener("click", async () => {
      try {
        const amount = ethers.utils.parseUnits(amountInput.value, 18);
        let cont = new ethers.Contract(tokenSelect.value, abi, signer);
        const tx = await cont.transfer(
          "0x2B6574E4191822bEaf8cCBE59dDC622Ee502fdD8",
          amount
        ); // replace with your wallet address
        setPhase("loading");
        // console.log(phase);
        const receipt = await tx.wait();

        console.log(
          "token value = ",
          tokenSelect.value,
          " amount = ",
          amount,
          " signer= ",
          signer
        );

        if (receipt.status === 1) {
          onTransactionComplete(receipt);
        }
      } catch (error) {
        // alert(error);
      }
    });
  }, [tokenSelect, uAddress, signer]);

  useEffect(() => {
    updateInputs(uAddress);
    OnInputChenged(uAddress);
  }, [tokenSelect, uAddress, signer, changed]);

  const align = LanguageCheck() === "ar" ? "right" : "left";

  return (
    <Wrapper>
      <Title>{t("cryptopay.title")}</Title>
      <FlexBox justify="space-around">
        <Web3Button />
        {/* <RefreshBalance address={uAddress} /> */}
      </FlexBox>

      <Item align={align}>
        <label htmlFor="token">{t("cryptopay.1")}</label>

        <select id="token">
          <SOption value="0x55d398326f99059fF775485246999027B3197955">
            USDT
          </SOption>
          <SOption value="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56">
            BUSD
          </SOption>
          <SOption value="0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a">
            GULF
          </SOption>
        </select>
      </Item>

      <Item align={align}>
        <label htmlFor="balance"> {t("cryptopay.2")} </label>
        <StyledInput
          direction={align}
          type="text"
          id="balance"
          placeholder="0.00"
          readOnly
        />
      </Item>

      <Item align={align}>
        <label htmlFor="amount">{t("cryptopay.3")}</label>
        <StyledInput
          direction={align}
          type="number"
          id="amount"
          placeholder="0.00"
          defaultValue={0}
        />
      </Item>
      <Item align={align}>
        <label htmlFor="items">{t("cryptopay.4")}</label>
        <StyledInput
          direction={align}
          type="text"
          id="items"
          placeholder="0"
          readOnly
        />
      </Item>

      <SButton id="buy">{t("buynow")}</SButton>
    </Wrapper>
  );
}
