import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { keyframes } from 'styled-components'

const breatheAnimation = keyframes`
0% { opacity: 0;  }
99% { opacity: 1;  }
`

const WarningWrapper = styled.div`
    display:flex;
    flex-direction:column;
    background: rgba(239, 137, 15, 0.21);
    border: 1px solid #FFAA06;
    color: #EF890F;
    border-radius: 20px;
    gap:8px;
    text-align:center;
    padding:12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    animation-name: ${breatheAnimation};
    animation-duration: 2s;
    display: flex;
    place-items:center;
    margin-bottom:40px;
    margin-top:10px;

    img {
        width:90px;
        heg: 90px;
    }

    svg{
        max-width: 24px;
    }
   
`

const Icon = styled.img`
position: absolute;
max-width: 24px;
right: 66px;
`


export default function KycWarning() {
    const { t } = useTranslation();
    return (
        <WarningWrapper>

            <Icon src='/assets/icons/yellowarn.svg' alt='book-meeting' />
            <img src='/assets/icons/kycwarn.png' alt='book-meeting' />

            {t('kycwarning')}
        </WarningWrapper>
    )
}
