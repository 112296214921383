import React, { useState, useEffect, memo, useContext, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GulfvsDolar from "../GulfvsDolar";
import DragDrop from "components/dashboard/DragDrop";
import KycWarning from "components/dashboard/KycWarning";
import $ from "jquery";
import config from "../../config";
import KycSuccess from "../KidKycSuccess";
import { DirectionContext } from "context/direction";
import Shareprice from "components/Shareprice";
import { NewApiContext } from "context/newapi";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { StyledInput } from "components/styled";
import { local } from "web3modal";
import { PriceContext } from "context/prices";

const Dragzone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 2px dashed #34384c;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }

  input {
    display: flex;
    z-index: 2;
    color: transparent;
    opacity: 0;
    width: 100%;
    height: 130px;
  }
`;
const Drag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
`;

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 14px !important;
  z-index: 1;
  cursor: pointer;
  margin-top: 12px;
  img {
    width: 56px;
    height: 56px;
  }
`;
const ModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 800;
  font-size: 28px;
  color: #ffffff;
  line-height: 150%;
  padding: 30px 0px 30px 0px;
  animation-name: ${(props) => props.animationName};
  animation-duration: 2s;
`;
const InputTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  align-self: ${(props) => props.alignSelf};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0px 12px;
  width: 100%;
  margin: 16px 0px;

  div:last-child {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  @media (max-width: 400px) {
    input {
      font-size: 13px !important;
      padding: 8px 4px;
    }
  }
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: 2px solid #34384c;
  border-radius: 8px;
  font-weight: 600;

  font-size: 18px;
  line-height: 160%;
`;
const Inp = styled.input`
  background: transparent;
  border: none;
  outline: none;
  font-weight: 700;
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 12px !important;

  ::focus {
    outline: none;
  }
  @media (max-width: 472px) {
    padding: 10px 12px !important;
  }
`;
const BorderedInput = styled.input`
  background: transparent;
  outline: none;
  color: #ffffff;
  border: 2px solid #34384c;
  border-radius: 8px;
  width: 95%;

  font-weight: 600;
  grid-column: 1/4;
  font-size: 18px;
  padding: 6px 0px 6px 12px !important;
  ${(props) => props.readOnly && "color: #5d6588 !important;font-weight: 700;"}

  ::focus {
    outline: none;
  }
  @media (max-width: 472px) {
    width: 95%;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  text-align: ${(props) => props.Align};
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #949ab2;
  svg {
    fill: #949ab2 !important;
  }
`;

const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  padding: 4px 0px;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    white-space: nowrap;
    padding: 12px 0px;
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
  }
`;
const Error = styled.span`
  color: #d91b60;
  font-size: 16px;
  position: relative;
  botttom: 0;
`;

export default function ChildCardPay({
  numberofShare,
  bundleId,
  totalPackage,
  remaining,
  totalPrice,
}) {
  const [disabled, setDisabled] = useState(true);
  const childerens = useContext(NewApiContext);
  const kids = childerens.children;

  const BookButton = styled(StyledButton)`
    background-color: #d71159;
    margin-bottom: 18px;

    ${disabled === true
      ? `
            background-color: #1a1b20;
            color: #34384c;
            width: 100%;
            border: none;
            border-radius: 14px;
            font-weight: 800;
            font-size: 18px;
            cursor: not-allowed;
            &:hover{
                opacity:1;
            }
    
      `
      : `
            background-color:#D71159;
        `}
  `;
  const [amount, setAmount] = useState(1);
  const { t } = useTranslation();
  const { sharePrice } = useContext(PriceContext);
  const { align } = useContext(DirectionContext);
  const [err, setErr] = useState(true);
  const [validate, setValidate] = useState(false);
  // const [gulfcoinPrice, setGulfcoinPrice] = useState(0);
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [calculatedPackage, setCalculatedPackage] = useState(numberofShare);
  const [remainAmount, setRemainAmount] = useState("");
  const [selectedKid, setSelectedKid] = useState({
    name: "",
    childBirthday: "",
  });

  const [kyc, setKyc] = useState(false);
  const [calculatedInDollar, setCalculatedInDollar] = useState(0);
  const fileInputRef = useRef(null);
  const [meetReq, setMeetReq] = useState({
    amount: 0,
    id: "",
    name: "",
    email: "",
    lastname: "",
  });
  useEffect(() => {
    if (align === "flex-end") {
      // ar
      setRemainAmount("يوجد فقط " + remaining + " حزمة متبقية");
    } else {
      setRemainAmount("There is only " + remaining + " packages left");
    }
  }, []);

  useEffect(() => {
    let fullName =
      localStorage.getItem("name") + " " + localStorage.getItem("lastname");
    setMeetReq({
      amount: amount,
      id: localStorage.getItem("id"),
      name: fullName,
      email: localStorage.getItem("email"),
    });
  }, [amount, calculatedAmount]);

  useEffect(() => {
    setCalculatedPackage(amount * numberofShare);
    setCalculatedInDollar(amount * totalPrice);
    console.log(totalPrice);
  }, [amount, sharePrice, totalPrice, numberofShare]);

  useEffect(() => {
    if (kyc === true && amount > 0 && selectedKid.name !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [amount, setKyc, kyc, selectedKid]);

  useEffect(() => {
    let id = localStorage.getItem("id");

    $.ajax({
      type: "post",
      url: config.FILE_EXIST + "?fileName=" + id + ".jpg",
      data: {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
      },
      success: function (response) {
        // console.log(response);
        if (id !== null) {
          if (response.message === "File exists") {
            setKyc(true);
          } else {
            setKyc(false);
          }
        }
      },
      error: function (error) {
        console.error("Error:", error);
      },
    });
  }, []);

  function validateForm() {
    let x = document.forms["form"]["quantity"].value;

    if (x === "") {
      setErr(true);
      setValidate(true);
    } else if (kyc === false) {
      setErr(true);
    } else if (x > remaining) {
      setErr(true);
      setValidate(true);
      setDisabled(true);
      setAmount(remaining);
    } else {
      setErr(false);
      document.getElementById("form").submit();
    }
  }

  const HandleSubmit = (e) => {
    e.preventDefault();
    validateForm();

    if (err === false) {
      document.getElementById("form").submit();
    }
  };

  function MathFloorTop(number) {
    if (number) return Math.floor(number);
    else return null;
  }

  function registerKid(childName, childBirthday) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("id");

    return new Promise((resolve, reject) => {
      $.ajax({
        url: config.KID_REGISTER,
        method: "POST",
        data: {
          childName: childName,
          childBirthday: childBirthday,
          userId: userId,
          token: token,
        },
        success: function (data) {
          const resp = JSON.parse(data);
          console.log(resp.id);
          return resolve(resp.id);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("Error:", textStatus, errorThrown);
          reject(errorThrown);
        },
      });
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];
    var formData = new FormData();
    const childName = document.getElementById("childName").value;
    const childBirthday = document.getElementById("childBirthday").value;
    const kidSuccess = document.getElementById("kidkycsuccess");

    registerKid(childName, childBirthday)
      .then((kidId) => {
        formData.append("fileToUpload", file);
        formData.append(
          "fileName",
          localStorage.getItem("id") + "_" + kidId + ".png"
        );
        formData.append("id", localStorage.getItem("id"));
        formData.append("token", localStorage.getItem("token"));
        formData.append("email", local.getItem("email"));
        formData.append("submit", "submit");

        fetch(config.AVATAR_UPLOAD, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            response.json();
            document.getElementById("kidkycsuccess").style.display = "block";
          })
          .then((data) => {
            let KidimgVersion = localStorage.getItem("imgVersion");
            if (KidimgVersion === null) {
              KidimgVersion = 0;
            } else {
              KidimgVersion = parseInt(KidimgVersion, 10) + 1;
            }
            localStorage.setItem("KidimgVersion", KidimgVersion);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error("Error registering kid:", error);
      });
  }
  return (
    <ModalContainer
      id="form"
      action={config.KID_CARD}
      onSubmit={HandleSubmit}
      method="post"
    >
      {t("paywithcreditcard")}
      <Drop
        kids={kids}
        selectedKid={selectedKid}
        setSelectedKid={setSelectedKid}
      />

      {selectedKid.id === "add" ? (
        <>
          <Grid>
            <InputWrapper>
              <InputTitle alignSelf={align}> {t("childname")} </InputTitle>
              <StyledInput
                name="childName"
                type="text"
                placeholder={t("childname")}
                id="childName"
              />
            </InputWrapper>

            <InputWrapper>
              <InputTitle alignSelf={align}>{t("childbirthday")}</InputTitle>

              <StyledInput
                name="childBirthday"
                type="date"
                placeholder="Child Birthday"
                id="childBirthday"
              />
            </InputWrapper>
          </Grid>
          <Dragzone id="dropzone">
            <input
              ref={fileInputRef}
              type="file"
              name="fileToUpload"
              id="fileToUpload"
              onChange={handleSubmit}
            />

            <Comp>
              <img src="/assets/icons/kid.png" alt="dragdrop" />

              <Drag>{t("uploadthephoto")}</Drag>
            </Comp>
          </Dragzone>
          <KycSuccess />
        </>
      ) : (
        <>
          <Inp
            name="childName"
            value={selectedKid.name}
            id="childName"
            type="text"
            hidden
            readOnly={true}
          />
          <Inp
            name="childBirthday"
            value={selectedKid.childBirthday}
            id="childBirthday"
            type="date"
            hidden
            readOnly={true}
          />
        </>
      )}
      <Inp
        name="email"
        value={localStorage.getItem("email")}
        id="email"
        type="text"
        hidden
        readOnly={true}
      />
      <Grid>
        <InputWrapper>
          <InputTitle alignSelf={align}>{t("numberofpackages")}</InputTitle>
          <BorderedInput
            name="quantity"
            type="number"
            value={amount}
            step={1}
            placeholder="$"
            id="quantity"
            onChange={(e) => setAmount(MathFloorTop(e.target.value))}
          />
          {/* <DropdownMenu selected={selected} setSelected={setSelected} /> */}
          <Shareprice />
        </InputWrapper>

        <InputWrapper>
          <InputWrapper>
            <InputTitle alignSelf={align}>{t("shares")}</InputTitle>

            <BorderedInput readOnly={true} value={calculatedPackage} />
          </InputWrapper>
          <GulfvsDolar />
        </InputWrapper>

        <BorderedInput readOnly={true} value={calculatedInDollar} />
      </Grid>

      <Inp
        name="id"
        value={meetReq.id}
        id="id"
        hidden
        type="text"
        readOnly={true}
      />
      <Inp
        name="bundleId"
        value={bundleId}
        id="bundleId"
        hidden
        type="text"
        readOnly={true}
      />
      <Inp
        name="name"
        value={meetReq.name}
        id="name"
        type="text"
        hidden
        readOnly={true}
      />

      <Inp
        name=""
        value={meetReq.email}
        id="email"
        type="email"
        hidden
        readOnly={true}
      />

      {validate && <Error> {remainAmount}</Error>}

      {kyc === false ? (
        <>
          <DragDrop setKycc={setKyc} />
        </>
      ) : null}
      <KycSuccess />
      <KycWarning />

      <BookButton type="submit" id="reqbutton" disabled={disabled}>
        {t("buynow")}
      </BookButton>
    </ModalContainer>
  );
}
const StyledDropDown = styled.div`
  width: 100%;
  .ant-dropdown-trigger {
    background: #1d1f25;
    border: 2px solid #34384c;
    border-radius: 8px;
    color: #fff !important;
    font-weight: 600;
    display: flex;
    padding: 0 10px;
  }
`;
const StyledSpace = styled(Space)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  direction: ${(props) => props.direction};
`;
function Drop({ kids, setSelectedKid, selectedKid, setAddMember }) {
  const { t } = useTranslation();
  const { direction } = useContext(DirectionContext);
  const items = kids?.map((kid) => {
    return {
      key: kid.id,
      label: (
        <a
          onClick={() => {
            setSelectedKid({
              id: kid.id,
              name: kid.name,
              childBirthday: kid.birthday,
            });
          }}
        >
          {kid.name}
        </a>
      ),
    };
  });
  items?.unshift({
    key: "add",
    label: (
      <a
        onClick={() => {
          setSelectedKid({
            id: "add",
            name: t("addfamilymember"),
            childBirthday: "",
          });
        }}
      >
        {t("addfamilymember")}
      </a>
    ),
  });
  return (
    <StyledDropDown>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <StyledSpace direction={direction}>
            {selectedKid.name === "" ? t("selectkid") : selectedKid.name}
            <DownOutlined />
          </StyledSpace>
        </a>
      </Dropdown>
    </StyledDropDown>
  );
}
