
var queryString = window.location.search;

var params = new URLSearchParams(queryString);

// Get the value of the "id" parameter
var id = params.get("inviter");

// Display the value of the "id" parameter
//console.log("ID: " + id);

var myValue = localStorage.getItem('inviter');
console.log("inviter: " + myValue);
    if(!(id === null))
    {
    console.log("ID: " + id);
    localStorage.setItem('inviter', id);
    }
  //setModal2Open(true);