import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Buttons from "./Buttons";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import config from "../config";
import PersonalCheckPopup from "./PersonelCheckPopup";
import { NewApiContext } from "context/newapi";
import { StyledLink } from "./styled";
import Cantvote from "./Cantvote";

const Container = styled.div`
  display: flex;
  position: fixed;
  color: #fff;
  top: 180px;
  justify-content: center;
  background-image: url("/assets/noshare.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-width: 500px;
  height: 436px;
  justify-content: center;
  padding: 40px;
  z-index: 99;
  @media (max-width: 820px) {
    background-image: url("/assets/mobilevotepopup.png");
    background-size: cover;
    min-width: 300px;
    max-width: 310px;
    border-radius: 20px;
    height: 526px;
    padding: 100px 20px 0px 20px;
    top: 120px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  img {
    width: 16px;
  }

  width: 100%;
  button:first-child {
    background: transparent;
    border: none;
    align-self: flex-end;
    cursor: pointer;
  }

  @media (max-width: 820px) {
    img {
      width: 14px;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
  }
`;
const StyledButton = styled.a`
  border: 1px solid #d71159;
  background-color: #d71159;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 86px;
  font-weight: 800;
  margin-bottom: 30px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  gap: 8px;
  img {
    width: 30px;
  }
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    font-weight: 800;
    line-height: 160%;
    padding: 4px 48px;
    margin-bottom: 70px;
  }
`;
const Mask = styled.div`
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(14px);
  z-index: 99;
  width: 99vw;
  height: 100vh;
  top: 0px;
  left: 0px;
`;
export default function Votepopup() {
  const [show, setShow] = useState(false);
  const apiObj = useContext(NewApiContext);
  const [userShare, setUserShare] = useState(undefined);
  const isVoteStarted = localStorage.getItem("shouldVote");
  const usershareTest = parseInt(localStorage.getItem("sharesWithoutKids"));
  const isLogged = localStorage.getItem("token") ? true : false;

  function handleShare() {
    if (!localStorage.getItem("token")) {
      window.location.href = "/login";
    } else {
      window.location.href = "/packages";
    }
  }
  useEffect(() => {
    setUserShare(apiObj.sharesWithoutKids);
    // localStorage.setItem("sharesWithoutKids", apiObj.sharesWithoutKids);
  }, [apiObj]);

  useEffect(() => {
    setUserShare(apiObj.sharesWithoutKids);
  }, [apiObj]);

  useEffect(() => {
    if ((userShare !== undefined) & (userShare <= 4999)) {
      setShow(true);
    } else if (!isLogged) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [userShare]);

  return  <Cantvote />
}
