import { Dropdown, Menu, Space } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import React, { useContext, useEffect } from "react";
import { LanguageContext } from "context/language";
import { useNavigate } from "react-router-dom";

const Drop = styled(Dropdown)`
  cursor: pointer;
  white-space: nowrap !important;
  :hover {
    opacity: 0.8;
  }
  .ant-dropdown-menu-item {
    color: #5d6588 !important;
    :hover {
      opacity: 0.5 !important;
    }
  }
`;

export function DropdownMenu() {
  const { i18n } = useTranslation();
  const { changeLanguage } = useContext(LanguageContext);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => changeLanguage("eng")}>EN</Menu.Item>

      <Menu.Item onClick={() => changeLanguage("ar")}>AR</Menu.Item>
    </Menu>
  );

  return (
    <Drop overlay={menu} trigger={["hover"]}>
      <a>
        {i18n.language.toUpperCase()}

        <Space style={{ margin: "0px 4px" }}>
          <DownOutlined />
        </Space>
      </a>
    </Drop>
  );
}

export default DropdownMenu;
