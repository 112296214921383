
 export function formatNumber(num , isAr=false) {

if(isAr==false)
{
  
  
  if (num >= 1000000000) {
      return ((num) / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
     else {
      return Math.round(num * 1000) / 1000;
      return num;
    }
  }else
  {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'بْ';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'م';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'ك';
    }else if (num >= 10) {
      return (num).toFixed(1).replace(/\.0$/, '');
    }
     else {
      return Math.round(num * 1000) / 1000;
      return num;
    }
  }
  }
  /*
  export function formatNumber(num) {
    num=Math.round(num * 1000) / 1000;
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '').slice(0, -3) + 'B';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '').slice(0, -3) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '').slice(0, -3) + 'K';
    } else {
      return  num;
    }
  }*/
