import Navbar from "components/Navbar";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import "../styleMehdi.css";
import config from "../config";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";

const PageWrapper = styled.div`
  background-image: url("/assets/bubblebg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 80px);
  max-width: 100vw;
  margin-top: 80px;
  color: #333;
  overflow: hidden;
  position: relative;
`;

const Popup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px 20px;
  border-radius: 9px;
  border: 1px solid #d71159;
  background: rgba(29, 31, 37, 0.9);
  border-radius: 9px;
  width: max-content;
  min-width: 280px;
  margin-left: 20px;
  margin-top: 20px;
  direction: ${(props) => props.direction};
  h2 {
    color: #fff;
    margin: 0px;
  }
  p {
    color: #5d6588;
    margin: 0px;
  }

  @media (max-width: 768px) {
    max-width: 240px;

    h2 {
      font-size: 20px;
    }
  }
`;

export default function Bubble() {
  // Fetch the data from the API
  const { t } = useTranslation();
  const { direction } = useContext(DirectionContext);
  useEffect(() => {
    // Fetch the data from the API
    // Fetch the data from the API
    // Fetch the data from the API
    fetch(config.KID_SHARES)
      .then((response) => response.json())
      .then((data) => {
        // Process the data
        data.forEach((child) => {
          if (child.shares != 0) {
            // Scale the shares to a size between 1 and 10
            let minShares = 2; // minimum shares
            let maxShares = 30000; // maximum shares, adjust this to your needs
            let minSize = 2; // minimum size
            let maxSize = 10; // maximum size

            // This will map the share count (from minShares to maxShares) to a size (from minSize to maxSize)
            let size = child.shares / 3000 + 2;
            // ((child.shares - minShares) / (maxShares - minShares)) *
            //   (maxSize - minSize) +
            // minSize;
            size = Math.round(size); // round to the nearest whole number

            // ... rest of your code

            let bubble = document.createElement("div");
            bubble.className = "bubble x" + size;
            bubble.style.backgroundImage =
              "url('" +
              config.AVATAR_COMPRESSED2 +
              "?filename=" +
              child.parentId +
              "_" +
              child.id +
              ".png&imgVersion=" +
              child.imgVersion +
              ")";
            bubble.style.animationDelay = Math.random() * 100 + "s";
            bubble.title = child.name; // Adding child name as tooltip

            // Append the bubble to the body of the document
            document.body.appendChild(bubble);

            // Add event listener
            bubble.addEventListener("click", function (event) {
              document.querySelectorAll(".bubble").forEach((el) => {
                el.style.border = "";
              });

              // Add border to the clicked bubble
              event.target.style.border = "6px solid #d71159";
              event.stopPropagation(); // Prevent the event from bubbling up to the document
              document.getElementById("childName").innerText = child.name;
              document.getElementById("childBirthday").innerText =
                child.birthday; // You may need to adjust this line based on your actual data structure
              document.getElementById("childShares").innerText = child.shares;
              // document.getElementById("size").innerText = size;
              document.getElementById("popup").style.display = "block";
              document.getElementById("popup").style.zIndex = "10";
            });
          }
          // Add event listener

          // Add an event listener to the document that hides the popup when clicked
          document.addEventListener("click", function () {
            // Clear the border of all bubbles
            document.querySelectorAll(".bubble").forEach((el) => {
              el.style.border = "";
            });

            document.getElementById("popup").style.display = "none";
          });

          // Prevent clicks inside the popup from propagating to the document
          document
            .getElementById("popup")
            .addEventListener("click", function (event) {
              event.stopPropagation();
            });
        });
        var bubbles = Array.from(document.getElementsByClassName("bubble")).map(
          (bubble) => {
            return {
              element: bubble,
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              dx: (Math.random() - 0.5) * 2, // Reduce the speed factor here
              dy: (Math.random() - 0.5) * 2, // And here
            };
          }
        );

        function update() {
          // Update each bubble
          bubbles.forEach((bubble) => {
            // Update position
            bubble.x += bubble.dx;
            bubble.y += bubble.dy;

            // Check for collision with the edges of the window
            let radius = bubble.element.offsetWidth / 2;
            let offset = 100; // Amount of additional space for the bubbles to move

            if (
              (bubble.x <= -offset && bubble.dx < 0) || // hitting the left wall and moving left
              (bubble.x + radius * 2 >=
                document.documentElement.clientWidth + offset &&
                bubble.dx > 0) // hitting the right wall and moving right
            ) {
              bubble.dx = -bubble.dx;
            }
            if (
              (bubble.y <= -offset && bubble.dy < 0) || // hitting the top wall and moving up
              (bubble.y + radius * 2 >=
                document.documentElement.clientHeight + offset &&
                bubble.dy > 0) // hitting the bottom wall and moving down
            ) {
              bubble.dy = -bubble.dy;
            }

            // Update the position of the HTML element
            bubble.element.style.left = bubble.x + "px";
            bubble.element.style.top = bubble.y + "px";
          });

          // Schedule the next update
          requestAnimationFrame(update);
        }

        // Start the animation
        update();
      });
  }, []);

  document.body.style.overflow = "hidden";
  document.body.addEventListener(
    "touchmove",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );

  return (
    <>
      <Navbar />
      <PageWrapper>
        <Popup
          direction={direction}
          id="popup"
          style={{ display: "none", zIndex: "10" }}
        >
          <h2 id="childName"></h2>
          <p id="childBirthday"></p>
          <div id="size"></div>
          <div>
            <p style={{ color: "#A5ADCF" }}> {t("numberofshares")} </p>
            <p
              style={{ color: "#FF8B00", fontSize: "20px", lineHeight: "20px" }}
              id="childShares"
            ></p>
          </div>
        </Popup>

        <div id="background-wrap" />
      </PageWrapper>
    </>
  );
}
