import { MockUsers } from "mockuser";
import React, { useState, useEffect } from "react";
import $ from "jquery";
import config from "../../config";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../sections/slider-dots.css";
import {
  Circle,
  ExtendedProfilesWrapper,
  Flex,
  Label,
  SButton,
  UserWrapper,
  MobileUserWrapper,
  ShowButton,
  ExtendedUserWrapper,
} from "./styleds";

function CircleProfile({
  name,
  lastname,
  id,
  isSelected,
  setSelectedProfile,
  setShowAll,
  imgVersion,
}) {
  const HandleShowAll = () => {
    setSelectedProfile(id);
    setShowAll(false);
  };

  const [userImage, setUserImage] = useState(config.AVATAR_COMPRESSED + id);
  const [validImage, setValidImage] = useState(true);

  useEffect(() => {
    setUserImage(
      config.AVATAR_COMPRESSED2 +
        "?filename=" +
        id +
        ".png" +
        "&imgVersion=" +
        imgVersion
    );
  }, [userImage, validImage, id]);


  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setValidImage(true);
    };

    img.src = userImage;
  }, [userImage]);

  return (
    <SButton onClick={HandleShowAll}>
      <img
        src={userImage}
        onError={() => setValidImage(false)}
        style={{ display: "none" }}
        alt="user"
      />
      <Circle
        clicked={isSelected}
        image={validImage ? userImage : "/assets/icons/avatar.svg"}
      >
        {/* <Circle clicked={isSelected} image={"/assets/avatar.png"}> */}
        <Label clicked={isSelected}>
          {name} {lastname}
        </Label>
      </Circle>
    </SButton>
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1524,
      settings: {
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 3,
        infinite: false,
        arrows: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 2.8,
        centerMode: false,
        initialSlide: 0,
        infinite: false,
        autoplay: true,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2.2,
        centerMode: false,
        infinite: false,
        slidesToScroll: 1.1,
        arrows: false,
        autoplay: false,
      },
    },
  ],
};

export default function UserCards({
  showAll,
  setShowAll,
  selectedProfile,
  setSelectedProfile,
  searchFilter,
}) {
  const [randomUsers, setRandomUsers] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    $.ajax({
      type: "GET",
      url: config.CANDIDATE_LIST,
      data: {
        token: localStorage.getItem("token"),
      },
      success: function (resp) {
        const respe = JSON.parse(resp);
        // console.log(respe);
        setUsers(respe);
      },
    });
  }, []);

  useEffect(() => {
    searchFilter !== null
      ? setRandomUsers(
          users
            .filter(
              (user) =>
                (user.name + " " + user.lastname)
                  .toLowerCase()
                  .includes(searchFilter.toLowerCase()) ||
                (user.lastname + " " + user.name)
                  .toLowerCase()
                  .includes(searchFilter.toLowerCase())
            )
            .slice(0, 6)
        )
      : setRandomUsers(getRandomUniqueUsers(users, 6));
  }, [users, searchFilter]);

  const handleShowAll = () => {
    if (users.length > 6) {
      setShowAll(!showAll);
    }
    setSelectedProfile(null);
  };

  function Sliderr() {
    return (
      <SliderWrapper>
        <Slider {...settings}>
          {randomUsers.map((user, index) => (
            <MobileUserWrapper key={index}>
              <CircleProfile
                name={user.name}
                lastname={user.lastname}
                setSelectedProfile={setSelectedProfile}
                setShowAll={setShowAll}
                id={user.id}
                imgVersion={user.imgVersion}
                isSelected={selectedProfile === user.id}
              />
            </MobileUserWrapper>
          ))}
        </Slider>
      </SliderWrapper>
    );
  }

  return (
    <>
      <Hide>
        {showAll !== true || searchFilter !== null ? (
          (showAll !== true) & (searchFilter !== null) ||
          searchFilter !== "" ? (
            <Sliderr />
          ) : null
        ) : null}
      </Hide>

      <Grid>
        <Flex column>
          <Flex>
            {randomUsers.map((user, index) => (
              <UserWrapper key={index}>
                <CircleProfile
                  name={user.name}
                  lastname={user.lastname}
                  setSelectedProfile={setSelectedProfile}
                  setShowAll={setShowAll}
                  imgVersion={user.imgVersion}
                  id={user.id}
                  isSelected={selectedProfile === user.id}
                />
              </UserWrapper>
            ))}
          </Flex>

          <Flex>
            {showAll && (
              <ExtendedProfiles
                setSelectedProfile={setSelectedProfile}
                selectedProfile={selectedProfile}
                excludedUsers={randomUsers}
                users={users}
                setShowAll={setShowAll}
              />
            )}
          </Flex>
        </Flex>
        {/* <ShowButton onClick={handleShowAll}>مجلس هيئة العموم</ShowButton> */}
      </Grid>
      <Divider />
    </>
  );
}

function ExtendedProfiles({
  excludedUsers,
  selectedProfile,
  setSelectedProfile,
  users,
  setShowAll,
}) {
  const remainingUsers = users.filter(
    (user) => !excludedUsers.some((excludedUser) => excludedUser.id === user.id)
  );

  const [isSmallViewport, setIsSmallViewport] = useState(
    window.innerWidth <= 600
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallViewport(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array so this effect only runs on mount and unmount

  const usersToRender = isSmallViewport ? users : remainingUsers;

  return (
    <ExtendedProfilesWrapper>
      {usersToRender.map((user) => (
        <ExtendedUserWrapper key={user.id}>
          <CircleProfile
            name={user.name}
            lastname={user.lastname}
            setSelectedProfile={setSelectedProfile}
            setShowAll={setShowAll}
            id={user.id}
            isSelected={selectedProfile === user.id}
          />
        </ExtendedUserWrapper>
      ))}
    </ExtendedProfilesWrapper>
  );
}
const Divider = styled.div`
  position: relative !important;
  align-self: center;
  height: 1px;
  width: 70%;
  max-height: 34px !important;
  background-color: #9397ab;
  @media (max-width: 600px) {
    display: none;
  }
`;
function getRandomUniqueUsers(users, count) {
  let shuffled = [...users];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled.slice(0, count);
}
const Hide = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;
const SliderWrapper = styled.div`
  .slick-slide {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .slick-track {
    max-height: 164px !important;
    display: flex;
    justify-content: center;
  }
  .slick-track::after {
    display: none !important;
  }

  @media (max-width: 600px) {
    .slick-track {
      min-height: 184px !important;
      max-height: 194px !important;
    }
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  width: max-content;
  align-self: center;

  @media (max-width: 1300px) {
    width: 100%;
    button {
      justify-self: center;
    }
  }

  @media (max-width: 600px) {
    gap: 0px;
  }
`;
