import { useEffect } from "react";

function useBubbleRemover(isRemove) {
  useEffect(() => {
    if (isRemove) {
      let elements = document.getElementsByClassName("bubble");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  }, [isRemove]);
}

export default useBubbleRemover;
