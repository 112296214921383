import React, { useContext , useEffect } from "react";
import NewsCard from "../components/NewsCard";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../components/styled";
import { DirectionContext } from "context/direction";
import $ from "jquery";
import config from "../config";

const NewsWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr;
  gap: 24px 36px;
  place-items: center;

  a{
    text-decoration: none;
color: #fff;    
  }

  @media (max-width: 1130px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;
const Sect = styled.section`
  position: relative;
  top: -20px;
  @media (max-width: 1024px) {
    top: -100px;
  }
`;
const Title = styled.div`
font-weight: 700;
font-size: 40px;
line-height: 24px;
margin-bottom: 16px;
grid-column: 1 / 3;
grid-row: 1 / 3;
place-self: ${(props) => props.align};

@media (max-width: 1130px) {
  grid-column: 1;
  grid-row: 1 / 3;
}

color: #FFFFFF;
@media (max-width: 1024px) {
  font-size: 28px;
}
`;
export default function News() {
  const { t } = useTranslation();
  const { align } = useContext(DirectionContext);
  const { i18n } = useTranslation();

  return;
  let done = false;
  useEffect(() => {
    var settings = {
      "url": config.SECTION_VIEWS,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "section": "News"
      }
    };
    if (done === false) {
      $.ajax(settings).done(function (response) {
      });
    }
    done = true;
  }, [])
  

  return (
    <NewsWrapper>
      <Sect id="news"></Sect>

      <Cards>
        <Title align={align} >{t("newsTitle")}</Title>

        <a  href={"https://www.youtube.com/watch?v=Ho_JSkwjPtw"}  target="_blank">
          <NewsCard
            image={"b2.png"}
            title={t("video.title")}
           
          />
        </a>

        <StyledLink >
          <NewsCard
            image={"b1.png"}
            title={t("comingsoon")}
            text={
              "Opening an account will be easy and accessible with a friendly user dashboard, from which users can withdraw, deposit, swap, do external and internal transfers immediately, securely and with minimal fees. F"
            }
          />
        </StyledLink>
      </Cards>
    </NewsWrapper>
  );
}
