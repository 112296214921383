// GlobalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
  font-family: 'Cairo', sans-serif;
  }

  body {
   
  }

a{
  text-decoration: none;
  color: inherit;
}
.ant-progress.ant-progress-status-success {
  .ant-progress-bg{
    background-color: #d71159 !important;
  }
}


.ant-dropdown{
  span{
  color:#fff !important;
}
}
.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.ant-dropdown-menu {
  background-color: #1d1f25 !important;
  color: #fff !important;
  a{
    color: #fff !important;
    width: 100%;
    display:flex;
    direction: ${({ direction }) => (direction === "rtl" ? "rtl" : "ltr")};

  }

  li:hover {
    background-color: #292B35 !important;
  }
}



}

button {
  cursor: pointer;
  transition: 0.4s;
}
  @media (max-width: 1024px) {
  
    }

    @media (max-width: 768px) {
    .ant-radio-button-wrapper{
  padding-inline:4px;
}
    }

`;

export default GlobalStyles;
