import styled from "styled-components";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { DirectionContext } from "context/direction";

// INPUTS
export const SInput = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  max-height: 34px !important;

  div {
    margin-bottom: 10px;
  }
`;
export const StyledInput = styled.input`
  background: #1d1f25;
  border: 1.5px solid #34384c;
  border-radius: 8px;
  color: #fff !important;
  text-align: ${(props) => props.direction};
  padding: 8px 10px;
  direction: ${(props) => props.dir};
  font-weight: 500;
  ${(props) => props.readOnly && "color: #5d6588 !important;font-weight: 700;"}
  :focus {
    outline: none;
  }
  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }

  ::placeholder {
    color: #5d6588;
    text-align: ${(props) => props.direction};
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
export const StyledTextArea = styled.textarea`
  background: #1d1f25;
  border: 1.5px solid #34384c;
  border-radius: 8px;
  font-family: "Cairo", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: white;
  height: 100%;
  resize: none;
  text-align: ${(props) => props.direction};
  padding: 12px 10px;
  :focus {
    outline: none;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }

  ::placeholder {
    color: #5d6588;
    text-align: ${(props) => props.direction};
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
export const SButton = styled.button`
  border: 1px solid #d71159;
  position: relative;
  background-color: ${(props) =>
    props.transparent ? "transparent" : "#D71159"};
  color: #fff;
  padding: ${(props) => props.padding};
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    opacity: 0.8;
  }
`;
export const BrownButton = styled.button`
  border: 1px solid #48383E;
  position: relative;
  background-color: ${(props) =>
    props.transparent ? "transparent" : "#48383E"};
  color: #fff;
  padding: ${(props) => props.padding};
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.4s;
 
  &:hover {
    opacity: 0.8;
  }
  .button-logo {
    position: absolute;
    left: 10px; // Adjust this value to place the logo as needed
    top: 50%;
    transform: translateY(-75%); // Center the logo vertically
    height: 20px; // Adjust height as needed
    width: 18%;
  }
  > span {
    text-align: right;
    margin-left: 30px; // Adjust this value so the text starts after the logo
    // Add some padding to the right of the text, if needed
  
  }
`;

export const PdfButton = styled.button`
  display: flex;
  gap: 2px;
  font-size: 14px;
  width: max-content;
  align-items: center;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  direction: ${(props) => props.direction};
  justify-content: center;
  border: none;
  background-color: transparent;
  color: #d91b60;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }

  @media (max-width: 640px) {
    align-self: center;
  }
`;

export const RevertedFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  gap: ${(props) => props.gap};
  width: ${(props) => props.width};

  :not(input) {
    direction: ${(props) => props.direction};
  }
`;
