import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import { SButton, StyledInput, StyledTextArea } from "components/styled";
import styled from "styled-components";
import $ from "jquery";
import config from "../../config";
import { DirectionContext } from "context/direction";
import { NewApiContext } from "context/newapi";
import Votepopup from "components/Votepopup";

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: 34px 0px;
  color: #fff;
  text-align: right;

  h2 {
    font-size: 28px;
    place-self: center;
  }
  button {
    margin-top: 24px;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    display: block;
  }
`;
const HalfGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 12px;
`;
const ModalButton = styled(SButton)`
  width: 100%;
  padding: 8px 24px;
  font-size: 24px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function FormModal({ setHasSharePopup }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null); // new state to hold your file
  const apiObj = useContext(NewApiContext);
  const userShare = apiObj.sharesWithoutKids;
  const [showPopup, setShowPopup] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    slogan: "",
    about: "",
    program: "",
    supporters: "",
    tiktok: "",
    telegram: "",
    contact: "",
    whatsapp: "",
    experience: "",
    token: localStorage.getItem("token"),
    id: localStorage.getItem("id"),
  });

  useEffect(() => {
    if (userShare <= 125000) {
      setDisabled(true);
    }
  }, []);

  const HandleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile((oldFiles) => [...(oldFiles || []), ...e.target.files]); // Concatenate old files with new ones
  };

  useEffect(() => {
    console.log(file);
  }, [file]);
  const [errorMessage, setErrorMessage] = useState("");
  const HandleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const formPromise = $.ajax({
      url: config.BE_CANDIDATE,
      type: "POST",
      data: form,
      success: function (resp) {
        if (resp.includes("successfully")) {
          setError(false);

          setTimeout(function () {
            var url = window.location.href;
            var urlWithoutParameters = url.split("?")[0];
            window.location.href = urlWithoutParameters;
          }, 1200);
        } else if (resp.includes("Duplicate")) {
          setError(true);
          setErrorMessage(
            "لقد قمت بتقديم طلبك للترشح سابقاً، سيظهر ملفك عندما تتم الموافقة عليه"
          );
        } else if (resp.includes("verified")) {
          setError(true);
          setErrorMessage("يرجى توثيق حسابك");
        } else {
          setError(true);
          console.log(resp);
          setErrorMessage("حدث خطأ ما");
        }
      },
      error: function (error) {
        console.error(error);
      },
    });

    let formData = new FormData();
    file.forEach((file) => {
      formData.append("filesToUpload[]", file);
    });
    const fileUploadPromise = $.ajax({
      url: config.CANDIDATE_IMG + "?id=" + localStorage.getItem("id"),
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function (resp) {},
      error: function (error) {
        console.error(error);
      },
    });

    Promise.all([formPromise, fileUploadPromise])
      .then((responses) => {
        // both requests completed successfully
        console.log(responses);
      })
      .catch((error) => {
        // one or both requests failed
        console.error(error);
      });
  };

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("candidateForm")) {
      setIsModalOpen(true);
    }
  }, []);

  const showModal = () => {
    if (userShare <= 125000) {
      setHasSharePopup(false);
      return;
    }
    if (!localStorage.getItem("token")) {
      window.location.href = "/login";
    }

    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!form.slogan || form.slogan.trim() === "") {
      formIsValid = false;
      errors["slogan"] = "Please enter a slogan.";
      console.log("slogan");
    }

    if (!form.about || form.about.trim() === "") {
      formIsValid = false;
      errors["about"] = "Please enter about information.";
      console.log("about");
    }

    if (!form.program || form.program.trim() === "") {
      formIsValid = false;
      errors["program"] = "Please enter a program.";
      console.log("program");
    }

    if (!form.supporters || form.supporters.trim() === "") {
      formIsValid = false;
      errors["supporters"] = "Please enter supporters information.";
    }

    if (!form.experience || form.experience.trim() === "") {
      formIsValid = false;
      errors["experience"] = "Please enter experience information.";
      console.log("experience");
    }
    setFormErrors(errors);
    return formIsValid;
  };

  return (
    <>
      <ModalButton onClick={showModal}>شارك الآن</ModalButton>

      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <FormWrapper>
          <h2> ملف الانتخابات</h2>
          {error === true && <Error>{errorMessage}</Error>}
          {error === false && (
            <KycSuccesss>
              <>
                لقد قمت بتقديم طلبك للترشح، سيظهر ملفك عندما تتم الموافقة عليه
              </>
              <img src="/assets/icons/tick.svg" alt="kyc" />
            </KycSuccesss>
          )}
          <Item>
            <label>الشعار</label>
            <StyledTextArea
              direction={"right"}
              name="slogan"
              value={form.slogan}
              onChange={HandleChange}
            />
            {formErrors.slogan && (
              <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
            )}
          </Item>
          <Item>
            <label>السيرة الذاتية</label>
            <StyledTextArea
              direction={"right"}
              name="about"
              value={form.about}
              onChange={HandleChange}
            />
            {formErrors.about && (
              <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
            )}
          </Item>
          <Item>
            <label>البرنامج الانتخابي</label>
            <StyledTextArea
              direction={"right"}
              name="program"
              value={form.program}
              onChange={HandleChange}
            />
            {formErrors.program && (
              <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
            )}
          </Item>
          <Item>
            <label>خبرات المرشح</label>
            <StyledTextArea
              name="experience"
              direction={"right"}
              value={form.experience}
              onChange={HandleChange}
            />
            {formErrors.experience && (
              <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
            )}
          </Item>
          <Item>
            <label>الداعمين</label>
            <StyledTextArea
              name="supporters"
              direction={"right"}
              value={form.supporters}
              onChange={HandleChange}
            />
            {formErrors.supporters && (
              <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
            )}
          </Item>
          <HalfGrid>
            <Item>
              <label>رابط لينكد ان ( اختياري )</label>
              <StyledInput
                name="tiktok"
                direction={"right"}
                value={form.tiktok}
                onChange={HandleChange}
              />
            </Item>
            <Item>
              <label>رابط التلغرام ( اختياري)</label>
              <StyledInput
                name="telegram"
                direction={"right"}
                value={form.telegram}
                onChange={HandleChange}
              />
              {formErrors.telegram && (
                <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
              )}
            </Item>
          </HalfGrid>
          <Item>
            <label>رابط الواتساب ( اختياري )</label>
            <StyledInput
              name="whatsapp"
              value={form.whatsapp}
              direction={"right"}
              onChange={HandleChange}
            />
            {formErrors.whatsapp && (
              <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
            )}
          </Item>
          <Item>
            <label>يرجى تحميل السيرة الذاتية و الشهادات الأخرى</label>
            <Flex>
              <FileLabel htmlFor="fileUpload">حمل الملف</FileLabel>
              <FileInput
                id="fileUpload"
                type="file"
                onChange={handleFileChange}
                accept=".pdf, image/*"
                multiple
              />
              {file ? (
                <KycSuccess id="kidkycsuccess">
                  <img src="/assets/icons/tick.svg" alt="kyc" />
                  تم تحميل الملف
                </KycSuccess>
              ) : (
                <div style={{ fontWeight: "500" }}>لم يتم اختيار ملف</div>
              )}
            </Flex>
            {formErrors.file && (
              <div style={{ color: "#FF4A4A" }}>هذه الخانة مطلوبه</div>
            )}
          </Item>
          <SButton onClick={HandleSubmit} padding="2px 24px" type="primary">
            إرسال
          </SButton>
        </FormWrapper>
      </Modal>
    </>
  );
}
const KycSuccess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #11cabe;
  border-radius: 14px;
  gap: 12px;
  font-weight: 500;
  padding: 8px 24px;
  font-size: 14px;
  direction: ${({ directon }) => directon};
  line-height: 26px;
`;

const Error = styled.div`
  color: #d91b60;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  position: relative;
  botttom: 0;
`;
const FileLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 12px;
  background: transparent;
  color: #646d75;
  font-size: 16px;
  border: 1px solid #646d75;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

const KycSuccesss = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #11cabe;
  border-radius: 14px;
  gap: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  padding: 8px 24px;
  font-size: 14px;
  direction: ${({ directon }) => directon};
  line-height: 26px;
`;
