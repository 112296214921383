import React, { useContext, useState, useEffect } from "react";
import Navbar from "components/Navbar";
import styled from "styled-components";
import PackageCard from "components/package/PackageCard";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";
import $ from "jquery";
import config from "config";
import KidBundles from "components/package/KidBundles";
import useBubbleRemover from "hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  background-image: url("/assets/dashbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 80px;
  color: #fff;
  text-align: ${(props) => props.rightLeft};
  h1 {
    font-size: 32px;
    margin-bottom: 0px;
    padding: 0 0 0 50px;
    margin-top: 60px;
  }
  padding-bottom: 80px;
  p {
    font-size: 20px;
    place-self: ${(props) => props.align};
    width: 74%;
    font-weight: 500;
    padding: 0 0 0 50px;
    line-height: 1.3;
    margin: 10px 0px 50px 0px;
  }

  @media (max-width: 1460px) {
    height: auto;
    padding-bottom: 100px;
  }
  @media (max-width: 768px) {
    padding: 0px 20px 30px 20px;
    text-align: center;
    h1 {
      font-size: 26px;
      margin-bottom: 0px;
      padding: 0px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      width: 100%;
      line-height: 1.3;
      padding: 0px;
      margin: 10px 0px 50px 0px;
    }
  }
`;
const PackageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10vw;
  gap: 20px;
  flex-wrap: wrap;
  place-items: center;
  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 920px) {
    grid-template-columns: repeat(1, 1fr);
    padding: unset;
  }
`;

export default function Packages() {
  const { t } = useTranslation();
  const { rightLeft, align } = useContext(DirectionContext);
  var settings = {
    url: config.GET_BUNDLES,
    method: "GET",
    timeout: 0,
  };
  useBubbleRemover(true);
  const [packages, setPackages] = useState([
    {
      index: 0,
      amount: "/assets/packages/houndred.png",
      rank: "linear-gradient(180deg, #986d22 0%, #e8db97 111.11%)",
      percentage: 30,
      mark: "/assets/packages/goldenmark.svg",
      numberofShare: "100000",
      totalPrice: 7500,
      bundleId: "100K",
      totalPackage: "250",
      remaining: "",
    },
    {
      index: 1,
      amount: "/assets/packages/fifty.png",
      rank: "linear-gradient(180deg, #986d22 0%, #e8db97 111.11%)",
      percentage: 30,
      mark: "/assets/packages/goldenmark.svg",
      numberofShare: 50000,
      totalPrice: 4000,
      bundleId: "50K",
      totalPackage: "250",
      remaining: "",
    },
    {
      index: 2,
      amount: "/assets/packages/twenty.png",
      rank: "linear-gradient(180deg, #818386 0%, #FFFFFF 111.11%)",
      percentage: 50,
      mark: "/assets/packages/silvermark.svg",
      numberofShare: 20000,
      totalPrice: 1900,
      bundleId: "20K",
      totalPackage: "250",
      remaining: "",
    },
    {
      index: 3,
      amount: "/assets/packages/ten.png",
      rank: "linear-gradient(180deg, #864C3C 0%, #FFD1C4 111.11%)",
      percentage: 30,
      mark: "/assets/packages/bronzemark.svg",
      numberofShare: 10000,
      totalPrice: 900,
      bundleId: "10K",
      totalPackage: "250",
      remaining: "",
    },
    {
      index: 4,
      amount: "/assets/packages/five.png",
      rank: "linear-gradient(180deg, #864C3C 0%, #FFD1C4 111.11%)",
      percentage: 30,
      mark: "/assets/packages/bronzemark.svg",
      numberofShare: 5000,
      totalPrice: 500,
      bundleId: "5K",
      totalPackage: "250",
      remaining: "",
    },
    {
      index: 5,
      amount: "/assets/packages/five.png",
      rank: "linear-gradient(180deg, #BA22F5 0%, #EEC4FF 111.11%)",
      percentage: 30,
      numberofShare: 3000,
      totalPrice: 250,
      bundleId: "3K",
      totalPackage: "250",
      remaining: "",
    },
  ]);

  useEffect(() => {
    $.ajax(settings).done(function (response) {
      const resp = JSON.parse(response);
      // console.log(resp.data);
      setPackages((prevPackages) =>
        prevPackages.map((packageItem, index) => {
          const apiItem = resp.data.find(
            (item) => item.id === packageItem.bundleId
          );
          if (apiItem) {
            return {
              ...packageItem,
              totalPackage: apiItem.total,
              remaining: apiItem.remaining,
            };
          } else {
            return packageItem;
          }
        })
      );
    });
  }, []);

  return (
    <>
      <Navbar></Navbar>

      <Container align={align} rightLeft={rightLeft}>
        <h1> {t("investmentplan")} </h1>
        <p>{t("packageparagraph")}</p>
        <PackageWrapper>
          {packages.slice(0, 5).map((item, index) => (
            <PackageCard
              key={index}
              amount={item.amount}
              rank={item.rank}
              percentage={item.percentage}
              mark={item.mark}
              totalPackage={parseInt(item.totalPackage)}
              remaining={parseInt(item.remaining)}
              numberofShare={item.numberofShare}
              totalPrice={item.totalPrice}
              bundleId={item.bundleId}
            />
          ))}
          <KidBundles
            key={5}
            amount={packages[5].amount}
            rank={packages[5].rank}
            percentage={packages[5].percentage}
            totalPackage={parseInt(packages[5].totalPackage)}
            remaining={parseInt(packages[5].remaining)}
            numberofShare={packages[5].numberofShare}
            totalPrice={packages[5].totalPrice}
            bundleId={packages[5].bundleId}
          />
        </PackageWrapper>
      </Container>
    </>
  );
}
