import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../App.css";
import $ from "jquery";
import Notification from "../components/Notification";
import { useTranslation } from "react-i18next";
import SignLeft from "../components/SignLeft";
import config from "../config";
import LanguageCheck from "../langcheck";
import { Navigate } from "react-router-dom";
import { StyledLink } from "../components/styled";
import { Flex } from "components/Layouts";
import WindowSize from "WindowSizer";
import detectEthereumProvider from "@metamask/detect-provider";
import Navbar from "components/Navbar";
import useBubbleRemover from "hooks";

const Form = styled.form`
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 70px;

  @media (max-width: 1024px) {
    gap: 40px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 12px;
  padding-top: 10px;

  @media (max-width: 1024px) {
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  label {
    display: block;
    font-size: 14px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  white-space: nowrap;
  gap: 15px;
  img {
    margin-top: 60px;
  }
  @media (max-width: 1024px) {
    img {
      margin-top: 100px;
      margin-bottom: 50px;
    }
  }

  button {
    border: 1px solid #d71159;
    position: relative;
    background-color: #d71159;
    color: #fff;
    font-size: 18px;
    padding: 10px 54px;
    font-weight: 800;
    border-radius: 30px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 1024px) {
      padding: 12px 42px;
      height: 50px;
      font-weight: 800;
      font-size: 18px;
      line-height: 160%;
    }
  }
`;

const Title = styled.div`
  color: #fff;
  font-weight: 800;
  font-size: 28px;
  line-height: 160%;
`;

const Forgot = styled(StyledLink)`
  span {
    color: #5d6588 !important;
  }
`;

const SignupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  margin-top: 24px;
  span {
    text-decoration: none !important;
    underline: none;
    color: #d71159;
    cursor: pointer;
    font-weight: 800;
  }
`;

const Error = styled.div`
  color: #d91b60;
  font-size: 14px;
  position: relative;
  botttom: 0;
`;

const Page = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  background-color: #141518;
  height: 100vh;

  text-align: ${(props) => props.direction};
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    height: 100%;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  padding: 0 20%;

  @media (max-width: 1600px) {
    padding: 0 10%;
  }
  @media (max-width: 1024px) {
    height: 100vh;
  }

  @media (max-width: 550px) {
    padding: 0 30px;
  }
`;

const Input = styled.input`
  background: #1d1f25;
  border: 1.5px solid #34384c;
  border-radius: 8px;
  height: 50px;
  color: white;
  text-align: ${(props) => props.direction};
  padding: 0 20px;
  :focus {
    outline: none;
  }
  :hover {
    transition: 0.2s;
    border-color: #d71159;
  }

  ::placeholder {
    color: #5d6588;
    text-align: ${(props) => props.direction};
  }

  @media (max-width: 1024px) {
    width: auto;
    max-width: 100%;
  }
`;

export default function Login() {
  const [background, setBackground] = useState();
  const [image, setImage] = useState();
  const [tokenize, setTokenize] = useState();
  const [isOpen, setIsopen] = useState(false);
  const [text, setText] = useState();
  const Language = LanguageCheck();
  const [incPw, setIncPw] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const direction = LanguageCheck() === "ar" ? "right" : "left";
  const [isLoged, setIsLoged] = useState();
  const { t } = useTranslation();
  const isMobile = WindowSize();
  const [metamaskLink, setMetamaskLink] = useState("");
  const [meta, setMeta] = useState(false);
  const [banMessage, setBanMessage] = useState("");

  useEffect(() => {
    const checkForMetaMask = async () => {
      // Check if MetaMask is installed
      const provider = await detectEthereumProvider();
      if (provider) {
        console.log("MetaMask is installed!");
        setMeta(true);
        // MetaMask is installed, you can interact with the Ethereum network here
      } else {
        console.log("MetaMask is not installed");
        setMeta(false);
        // MetaMask is not installed, you can prompt the user to install it
      }
    };

    checkForMetaMask();
  }, []);

  useEffect(() => {
    let ref = window.location.href;
    let baseUrl = ref.split("/")[2];
    let meta = "https://metamask.app.link/dapp/" + baseUrl + "/login";

    setMetamaskLink(meta);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setIsLoged(false);
    } else {
      setIsLoged(true);
    }
  }, []);

  if (isLoged === false) {
    return <Navigate to="/" />;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const validate = () => {
    let isError = false;
    const errors = {
      passwordError: "",
      emailError: "",
    };
    if (user.email === "") {
      isError = true;
      Language === "ar"
        ? (errors.emailError = "هذه الخانه اجباريه")
        : (errors.emailError = "This field is required");
    }
    if (user.password === "") {
      isError = true;
      Language === "ar"
        ? (errors.passwordError = "هذه الخانه اجباريه")
        : (errors.passwordError = "This field is required");
    } else isError = false;

    setUser({ ...user, ...errors });
    return isError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    document.forms[0].reset();
    const err = validate();

    if (err === false)
      $.ajax({
        type: "POST",
        url: config.API_URL,
        data: {
          action: "login_user",
          email: user.email,
          password: user.password,
        },
        success: function (response) {
          let resp = JSON.parse(response);
          console.log("Success:", resp);
          if (response.includes("Incorrect")) {
            setIncPw(true);
            setBanMessage("");
            console.log(incPw);
          } else if (response.includes("banned")) {
            setIncPw(false);
            setBanMessage("Your account has been banned");
          } else {
            localStorage.setItem("token", resp.token);
            localStorage.setItem("shares", resp.shares);
            localStorage.setItem("name", resp.name);
            localStorage.setItem("lastname", resp.lastname);
            localStorage.setItem("email", resp.email);
            localStorage.setItem("id", resp.id);
            localStorage.setItem("phone", resp.phone);

            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 1000);
          }
        },
        error: function (error) {
          console.error("Error:", error);
        },
      });
  };

  return (
    <Page direction={direction}>
      <Navbar />
      <SignLeft />
      <FormWrapper>
        <Title>{t("nButton")}</Title>
        <Form onSubmit={handleSubmit}>
          <Grid>
            <Item>
              <label>{t("email")}</label>
              <Input
                direction={direction}
                name="email"
                textAlign="left"
                value={user.email}
                type="text"
                onChange={handleChange}
                placeholder={t("placeholderEmail")}
              />
              {user.emailError && <Error>{user.emailError}</Error>}
            </Item>
            <Item>
              <label>{t("password")}</label>
              <Input
                name="password"
                value={user.password}
                type="password"
                onChange={handleChange}
                placeholder={t("password")}
                direction={direction}
              />
              {incPw && <Error>{t("incorrectPw")}</Error>}
              {user.passwordError && <Error>{user.passwordError}</Error>}
              {banMessage && <Error>{banMessage}</Error>}
            </Item>
          </Grid>

          <Flex align="center" column>
            <ButtonBox>
              <button type="submit">{t("nButton")}</button>
            </ButtonBox>
          </Flex>
          {isOpen && (
            <Notification
              backgroundColor={background}
              image={image}
              text={text}
            />
          )}
        </Form>

        {Language === "eng" ? (
          <SignupWrapper>
            If you dont have an account you can{" "}
            <StyledLink to="/register">
              {" "}
              <span> Register Here!</span>
            </StyledLink>
          </SignupWrapper>
        ) : (
          <SignupWrapper>
            إذا لم يكن لديك حساب يمكنك{" "}
            <StyledLink to="/register">
              {" "}
              <span>التسجيل هنا</span>
            </StyledLink>
          </SignupWrapper>
        )}
        <SignupWrapper>
          <Forgot to="/resetpassword">
            {" "}
            <span>{t("forgotpw")}</span>
          </Forgot>
        </SignupWrapper>
      </FormWrapper>
    </Page>
  );
}
