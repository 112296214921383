import React, { useState, useEffect, memo, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GulfvsDolar from "../../GulfvsDolar";
import DragDrop from "../DragDrop";
import KycWarning from "../KycWarning";
import config from "../../../config";
import KycSuccess from "../../KycSuccess";
import { DirectionContext } from "context/direction";
import Shareprice from "components/Shareprice";
import { PriceContext } from "context/prices";

const ModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 800;
  font-size: 28px;
  color: #ffffff;
  line-height: 160%;
  padding: 30px 0px 30px 0px;
  animation-name: ${(props) => props.animationName};
  animation-duration: 2s;
`;
const InputTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  align-self: ${(props) => props.alignSelf};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 16px;
  margin-top: 16px;

  div:last-child {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: 2px solid #34384c;
  border-radius: 8px;
  font-weight: 600;

  font-size: 18px;
  line-height: 160%;
`;
const Inp = styled.input`
  background: transparent;
  border: none;
  outline: none;
  font-weight: 700;
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 12px !important;

  ::focus {
    outline: none;
  }
  @media (max-width: 472px) {
    padding: 10px 12px !important;
  }
`;
const BorderedInput = styled.input`
  background: transparent;
  outline: none;
  width: 94%;
  color: #ffffff;
  border: 2px solid #34384c;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  padding: 10px 0px 10px 12px !important;

  ::focus {
    outline: none;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  text-align: ${(props) => props.Align};
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #949ab2;
  svg {
    fill: #949ab2 !important;
  }
`;

const StyledButton = styled.button`
  border: 1px solid #d71159;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  padding: 4px 0px;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    white-space: nowrap;
    padding: 12px 0px;
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
  }
`;

export default function FiatModal({ kyc, setKyc }) {
  const [amount, setAmount] = useState("");
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const { sharePrice } = useContext(PriceContext);
  const { align } = useContext(DirectionContext);
  const [selected, setSelected] = useState({
    name: "Visa",
    icon: "/assets/icons/token/bsc.svg",
    value: "2",
  });
  const [err, setErr] = useState(true);
  const [validate, setValidate] = useState(false);
  // const [gulfcoinPrice, setGulfcoinPrice] = useState(0);
  const [calculatedAmount, setCalculatedAmount] = useState(0);

  const [meetReq, setMeetReq] = useState({
    amount: 0,
    id: "",
    name: "",
    email: "",
    lastname: "",
  });

  useEffect(() => {
    let fullName =
      localStorage.getItem("name") + " " + localStorage.getItem("lastname");
    setMeetReq({
      amount: amount,
      id: localStorage.getItem("id"),
      name: fullName,
      email: localStorage.getItem("email"),
    });
  }, [amount, calculatedAmount]);

  const BookButton = styled(StyledButton)`
    background-color: #d71159;
    margin-bottom: 18px;

    ${disabled === true
      ? `
            background-color: #1a1b20;
            color: #34384c;
            width: 100%;
            border: none;
            border-radius: 14px;
            font-weight: 800;
            font-size: 18px;
            cursor: not-allowed;
            &:hover{
                opacity:1;
            }
    
      `
      : `
            background-color:#D71159;
        `}
  `;
  useEffect(() => {
    setCalculatedAmount(amount / sharePrice);
  }, [amount, sharePrice]);

  function validateForm() {
    let x = document.forms["form"]["amount"].value;

    if (x === "") {
      setErr(true);
      setValidate(true);
    } else if (kyc === false) {
      setErr(true);
    } else {
      setErr(false);
      document.getElementById("form").submit();
    }
  }

  useEffect(() => {
    if (kyc === true && amount > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [amount, setKyc, kyc]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    validateForm();

    if (err === false) {
      document.getElementById("form").submit();
    }
  };

  const Error = styled.div`
    color: #d91b60;
    font-size: 14px;
    position: relative;
    botttom: 0;
  `;

  return (
    <ModalContainer
      id="form"
      action={config.FIAT_PAY}
      onSubmit={HandleSubmit}
      method="post"
    >
      {t("investnow")}

      <Grid>
        <InputWrapper>
          <InputTitle alignSelf={align}>{t("modalamount")}</InputTitle>
          <InputContainer>
            <Inp
              name="amount"
              type="number"
              value={amount}
              placeholder="$"
              id="amount"
              onChange={(e) => setAmount(e.target.value)}
            />
            {/* <DropdownMenu selected={selected} setSelected={setSelected} /> */}
          </InputContainer>
          <Shareprice />

          {validate && <Error> {t("validate")}</Error>}
        </InputWrapper>

        <InputWrapper>
          <InputWrapper>
            <InputTitle alignSelf={align}>{t("shares")}</InputTitle>

            <BorderedInput
              readOnly={true}
              name="calculatedAmount"
              value={calculatedAmount.toFixed(3)}
              id={"calculated"}
            />
          </InputWrapper>
          <GulfvsDolar />
        </InputWrapper>
      </Grid>
      <Inp
        name="id"
        value={meetReq.id}
        id="id"
        hidden
        type="text"
        readOnly={true}
      />
      <Inp
        name="name"
        value={meetReq.name}
        id="name"
        type="text"
        hidden
        readOnly={true}
      />
      <Inp
        name="email"
        value={meetReq.email}
        id="email"
        type="email"
        hidden
        readOnly={true}
      />
      {kyc === false ? (
        <>
          <DragDrop setKyc={setKyc} />
        </>
      ) : null}
      <KycSuccess />
      <KycWarning />

      <BookButton type="submit" id="reqbutton" disabled={disabled}>
        {t("buynow")}
      </BookButton>
    </ModalContainer>
  );
}
