import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import config from "../../config";
import SocialMedia from "components/dashboard/SocialMedia";
import { useTranslation } from "react-i18next";
import { formatNumber } from "FormatNumber";
import { SButton } from "components/styled";
import { NewApiContext } from "context/newapi";
import { local } from "web3modal";
import { Button, Modal } from "antd";

export default function CandidateProfile({ selectedProfile }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [about, setAbout] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    $.ajax({
      type: "GET",
      url: config.GET_CANDIDATE,
      data: {
        token: localStorage.getItem("token"),
        id: selectedProfile,
      },
      success: function (resp) {
        const respe = JSON.parse(resp);
        // console.log(respe);
        setUser(respe);
      },
    });
  }, [selectedProfile]);

  useEffect(() => {
    setAbout("حول" + user?.name + " " + user?.lastname);
  }, [user]);
  const [infos, setInfos] = useState({
    about: "",
    program: "",
    experience: "",
    supporters: "",
  });

  useEffect(() => {
    let updatedAbout = user?.about.replace(/\\n/g, "\n");
    let updatedProgram = user?.program.replace(/\\n/g, "\n");
    let updatedExperience = user?.experience.replace(/\\n/g, "\n");
    let updatedSupporters = user?.supporters.replace(/\\n/g, "\n");

    setInfos({
      about: updatedAbout,
      program: updatedProgram,
      experience: updatedExperience,
      supporters: updatedSupporters,
    });
  }, [user]);

  return (
    <SectionWrapper>
      <div></div>
      <ImageSection user={user} />

      <Fields>
        <Header user={user} />
        <Field
          title={"حول المرشح"}
          value={
            infos?.about
              ? infos.about.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
              : null
          }
        />
        <Field
          title={"البرنامج"}
          value={
            infos?.program
              ? infos?.program.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
              : null
          }
        />
        <Field
          title={"الخبرة"}
          value={
            infos?.experience
              ? infos?.experience.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
              : null
          }
        />
        <Field
          title={infos?.supporters ? "الداعمين" : null}
          value={
            infos?.supporters
              ? infos?.supporters.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
              : null
          }
        />
        {/* <Field title={"منصات التواصل الاجتماعي"} value={user?.contact} /> */}
      </Fields>
      <div></div>
    </SectionWrapper>
  );
}

function ImageSection({ user }) {
  const { t } = useTranslation();
  const numberOfShares = formatNumber(user?.shares);
  const api = useContext(NewApiContext);
  const [didVote, setDidVote] = useState(api.user.vote);

  const userImage =
    config.USER_AVATAR2 +
    "?filename=" +
    user?.id +
    ".png&imgVersion=" +
    user?.imgVersion;


  const [validImage, setValidImage] = useState(true);
  const [downloadLinks, setDownloadLinks] = useState([]);
  // console.log(userImage);
  const voter = useContext(NewApiContext);
  const [onceVote, setOnceVote] = useState(false);
  const [gtFiveK, setGtFiveK] = useState(false);


  useEffect(() => {
    if (voter?.sharesWithoutKids > 4999) {
      setGtFiveK(true)
    } else {
      setGtFiveK(false)
    }

  }, [voter])

  // is vote > 1 => already voted for a candidate . if isVote===user.id => voted for this candidate
  useEffect(() => {
    $.ajax({
      type: "GET",
      url: config.CANDIDATE_FILES,
      data: {
        token: localStorage.getItem("token"),
        id: user?.id,
      },
      success: function (resp) {
        const respe = JSON.parse(resp);
        setDownloadLinks(respe);
        // console.log(respe);
      },
    });
  }, [user]);

  const [voteState, setVoteState] = useState("notVoted"); // voteState can be 'notVoted', 'voted', 'alreadyVoted'
  const isVoteEnabled = "true";
  const VoteClick = () => { };

  useEffect(() => {
    // when candidate card has changed button texts are also changes
    setVoteState("notVoted");

  }, [user]);

  const downloadFile = async (url, filename) => {
    try {
      const response = await fetch(url);
      const downloadUrl = response.url;
      const link = document.createElement("a");
      link.setAttribute("href", config.CANDIDATE_CERT_DOWN + "?url=" + url);
      link.setAttribute("download", filename);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Failed to download file", error);
    }
  };
  const [firsVoteCount, setFirstVoteCount] = useState(0)

  let isVote = parseInt(voter.user.vote);

  const handleConfirmClick = () => {
    // Change the vote state to 'voted' when confirm is clicked
    // console.log(isVote);
    setIsModalOpen(false);

    if (isVote > 0) {
      // console.log("you already voted message appear here");
      setVoteState("alreadyVoted");
      return;
    }
    //this shows vote number after he vote
    // setDidVote(10)

    if (isVote <= 0) {
      $.ajax({
        type: "POST",
        url: config.CANDIDATE_VOTE,
        data: {
          token: localStorage.getItem("token"),
          voteForId: user?.id,
          id: localStorage.getItem("id"),
        },
        success: function (resp) {
          if (resp.includes("cast")) {
            setVoteState("voted");
            setOnceVote(true);
            setFirstVoteCount(user?.votes + 1)
          }
        },
      });
    }
  };
  console.log(user?.votes + 1);
  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setValidImage(true);
    };

    img.src = userImage;
  }, [userImage]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (onceVote === true) return;

    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Flex gap="24px" column>

      <img
        src={userImage}
        onError={() => setValidImage(false)}
        style={{ display: "none" }}
        alt="GCB"
      />
      <ImageWrapper image={validImage ? userImage : "/assets/icons/avatar.svg"}>
        <VoteButton disabled>
          انتهت عملية التصويت
        </VoteButton>
      </ImageWrapper>

      <Flex gap="16px" column>
        <Persona>
          <div>
            {user?.name} {user?.lastname}
          </div>
          <div>
            <span>{numberOfShares}</span> عدد الأسهم
          </div>

        </Persona>
        {
          gtFiveK ? (
            <div style={{ fontSize: 24, fontWeight: 700 }}>
              <span style={{ color: "#fa2256" }}>{user?.votes}</span> :عدد الأصوات
            </div>
          ) : null
        }




        <SocialMedia
          whatsapp={user?.whatsapp}
          telegram={user?.telegram}
          linkedin={user?.tiktok}
          justify="flex-end"
        />
        <ButtonWrappers>
          {downloadLinks.map((link, index) => {
            const fileType =
              link.endsWith(".pdf") || link.endsWith(".avif")
                ? "CV"
                : "Certificate";
            return (
              <DownloadButton
                key={index}
                onClick={() => downloadFile(link, fileType)}
              >
                {fileType}
              </DownloadButton>
            );
          })}
        </ButtonWrappers>
      </Flex>
    </Flex>
  );
}

const Animate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  gap: 18px;
  animation: fadeIn 0.3s ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

const DownloadButton = styled(SButton)`
  padding: 4px 16px;
  @media (max-width: 1300px) {
    padding: 4px 8px;
  }
`;
const ButtonWrappers = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    gap: 10px;
    flex-wrap: wrap;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 40px;
  line-height: 1.5;
  margin: 48px 0px 24px 0px;
  gap: 12px;

  div:nth-child(2) {
    font-size: 24px;
    font-weight: 500;
  }
  span {
    color: #fa2256;
  }
  @media (max-width: 1300px) {
    font-size: 34px;
    line-height: 1.2;
    div:nth-child(2) {
      font-size: 20px;
    }
  }
`;

const ImageWrapper = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 16px;
  min-width: 500px;
  width: 100%;
  min-height: 500px;
  max-height: 550px;
  height: 100%;
  border-radius: 36px;
  box-shadow: 16px 16px #fa2256;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 500px) and (max-width: 1300px) {
    place-self: flex-end;
    min-width: 70%;
    min-height: 80%;
    max-width: 100%;
    max-width: 50px;
    aspect-ratio: 1;
  }
  @media (max-width: 500px) {
    place-self: center;
    min-width: 100%;
    min-height: 80%;
    max-width: 100%;
    max-width: 50px;
    aspect-ratio: 1;
  }
`;
const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
  gap: ${(props) => props.gap && props.gap};
  background-color: ${(props) => props.bg && props.bg};
  :last-child {
    place-self: flex-end;
  }
`;
const Persona = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 40px;
  line-height: 1;
  gap: 14px;
  div:last-child {
    color: #a5adcf;
    font-size: 16px;
    fon-weight: 600;
    span {
      color: #fdd65b;
      font-weight: 700;
    }
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  gap: 12px;
  color: #fff;
  font-size: 40px;
  font-weight: 800;
  div:last-child {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
  }
  direction: rtl;
  @media (max-width: 1300px) {
    font-size: 40px;
    font-weight: 800;
    div:last-child {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.7;
    }
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

function Field({ title, value }) {
  return (
    <FieldWrapper>
      <div>{title}</div>
      <div>{value}</div>
    </FieldWrapper>
  );
}
function Header({ user }) {
  const { t } = useTranslation();

  const userSlogan = user?.slogan.replace(/\\n/g, " ");

  return (
    <HeaderWrapper>
      <div style={{ lineHeight: "1.2" }}>
        ملف المرشح <br />
        {user?.name} {user?.lastname}
      </div>

      <div>"{userSlogan}"</div>
    </HeaderWrapper>
  );
}

const VoteButton = styled(SButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #272933;
  border: none;
  padding: 4px 16px;
  gap: 4px;
  cursor: not-allowed;
  margin: 12px 0px 0px 12px;
  position: relative;

  transition: all 0.3s ease-in-out;
  img {
    width: 24px;
  }
  

  &:hover::after {
    visibility: visible;
    opacity: 1;
  }
`;


const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(300px, 600px) minmax(300px, 600px) auto;
  text-align: right;
  gap: 48px;
  animation: fadeIn 0.3s ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    padding: 10px 30px 30px 30px;
    gap: 0px;
  }
`;
const VoteButtonEnabled = styled(VoteButton)`
  cursor: pointer;
  background-color: ${(props) => (props.voted ? "#0F9D58" : "#272933")};
  margin: ${(props) => props.margin && props.margin};
  &:hover::after {
    visibility: hidden;
  }
  @media (max-width: 800px) {
    margin: 8px 0px 0px 8px;
  }
`;
