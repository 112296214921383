import React, { useState, useEffect, useContext } from "react";
import Navbar from "components/Navbar";
import styled from "styled-components";
import { Flex } from "components/Layouts";
import { useTranslation } from "react-i18next";
import ReferalTable from "components/ReferalTable";
import { DirectionContext } from "context/direction";
import useBubbleRemover from "hooks";

const PageWrapper = styled(Flex)`
  background-color: #aeaeae;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/dashbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 20px;
  @media (max-width: 1024px) {
    overflow: scroll;
    align-items: initial;
    height: initial;
    margin-top: 70px;
    padding: 0px 24px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px 44px;
  direction: ${({ direction }) => direction};
  max-width: 800px;
  color: #fff;
  background: #1e1f25;
  border-radius: 2px;
  border-radius: 20px;
  @media (max-width: 1024px) {
    margin: 34px 0px;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 1.7;
  gap: 14px;
  div:first-child {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
  }
`;
const CopyField = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  background: rgba(47, 50, 65, 0.5);
  border: 1px solid #34384c;
  box-sizing: border-box;
  border-radius: 8px;
  color: #fff;
  padding: 0px 0px 0px 10px;
  font-size: 18px;
  direction: ltr;
  font-weight: 500;
  margin-bottom: 20px;
  input {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  input:focus {
    outline: none;
  }
  @media (max-width: 1024px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    padding: 6px;
  }
`;
const CopyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 10px 20px;
  background: #141518;
  border: 1px solid #34384c;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
`;
export default function Referal() {
  useBubbleRemover(true);

  const { t } = useTranslation();
  const [icon, setIcon] = useState("/assets/icons/referal/copy.svg");
  const { direction } = useContext(DirectionContext);
  function CopyClipboard() {
    // Get the text field
    var copyText = document.getElementById("myInput");
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    setIcon("/assets/icons/referal/tick.svg");
    setTimeout(() => {
      setIcon("/assets/icons/referal/copy.svg");
    }, 3000);
  }

  let [referralLink, setReferralLink] = useState("");
  useEffect(() => {
    let ref = window.location.href;
    let baseUrl = ref.split("/")[0] + "//" + ref.split("/")[2];
    console.log(baseUrl);

    setReferralLink(
      baseUrl + "/register/?inviter=" + localStorage.getItem("id")
    );
  }, []);

  return (
    <>
      <Navbar />
      <PageWrapper gap="42px" column>
        <Container direction={direction}>
          <TextWrapper>
            <div> {t("refferal.title")} </div>
            <div> {t("refferal.content")} </div>
          </TextWrapper>
          <Flex gap="10px" column>
            <div> {t("refferal.subtitle")} </div>
            <CopyField>
              <input id="myInput" value={referralLink} readOnly></input>
              <CopyButton onClick={() => CopyClipboard()}>
                <img src={icon} alt="copy" />
                <div> {t("copy")} </div>
              </CopyButton>
            </CopyField>
          </Flex>
        </Container>
        <ReferalTable />
      </PageWrapper>
    </>
  );
}
