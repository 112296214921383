import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Flex } from "../Layouts";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";
import $ from "jquery";
import config from "../../config";

const Flexx = styled(Flex)`
  color: #fff;
  margin: 120px 0px;
  direction: ${({ direction }) => direction};
  justify-content: flex-end;
  h1:first-child {
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 24px !important;
  }

  @media (max-width: 1024px) {
    h1:first-child {
      font-size: 28px;
      line-height: 36px;
    }
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  gap: 100px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`;
const Frame = styled.iframe`
  border: none;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  justify-self: center;
  max-height: 350px;
  aspect-ratio: 16/9;
`;

const CardWrapper = styled(Flex)`
  gap: 40px;
  align-items: center;
  justify-content: center;
  img {
    max-width: 235px;
    border-radius: 20px;
    max-height: 350px;
  }
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export default function VideoCard() {
  const { t } = useTranslation();
  const { direction } = useContext(DirectionContext);

  let done = false;
  useEffect(() => {
    var settings = {
      url: config.SECTION_VIEWS,
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        section: "BossVideo",
      },
    };
    if (done === false) {
      $.ajax(settings).done(function (response) {});
    }
    done = true;
  }, []);

  return (
    <Flexx direction={direction} column>
      <h1>{t("dashboard.video.title")}</h1>
      <Grid>
        <Frame
          src="https://www.youtube.com/embed/2gXtXoHkohs"
          title="بنك الخليج الرقمي | Gulf Crypto Bank"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></Frame>
        <CardWrapper>
          <img src="/assets/fintech.jpg" />
          <img src="/assets/fintech-1.jpg" />
        </CardWrapper>
      </Grid>
    </Flexx>
  );
}
