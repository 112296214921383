import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu, Space } from "antd";
import GulfvsDolar from "../../GulfvsDolar";
import DatePicker from "../DatePicker";
import Buttons from "../../Buttons";
import LanguageCheck from "../../../langcheck";
import $ from "jquery";
import config from "../../../config";
import InputSkeleton from "../../InputSkeleton";
import { DirectionContext } from "../../../context/direction";
import { PriceContext } from "context/prices";

const Error = styled.div`
  color: #d91b60;
  font-size: 14px !important;
  position: relative;
  bottom: 0;
  line-height: 26px;
`;
const ModalContainer = styled.form`
  display: flex;

  flex-direction: column;
  align-items: center;
  font-weight: 800;
  font-size: 28px;
  color: #ffffff;
  line-height: 160%;
  padding: 30px 0px 60px 0px;
  animation-name: ${(props) => props.animationName};

  animation-duration: 2s;
`;
const InputTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 16px;
  margin-top: 16px;
  div:last-child {
    font-size: 16px;
    font-weight: 600 !important;
  }
`;
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: 2px solid #34384c;
  border-radius: 8px;
  font-weight: 600;

  font-size: 18px;
  padding: 4px 10px;
`;
const Inp = styled.input`
  background: transparent;
  border: none;
  outline: none;
  font-weight: 700;
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  ::focus {
    outline: none;
  }
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
const BorderedInput = styled.input`
  background: transparent;
  outline: none;
  width: 94%;
  color: #ffffff;
  border: 2px solid #34384c;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 0px 12px 12px !important;

  ::focus {
    outline: none;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  text-align: ${(props) => props.Align} !important;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #949ab2;
  svg {
    fill: #949ab2 !important;
  }
`;
export default function SupportModal() {
  const [timer, setTimer] = useState("");
  var today = new Date("2023-02-20");
  const [dater, setDater] = useState(today.toLocaleDateString("en-GB"));
  const [amount, setAmount] = useState("");
  const { t } = useTranslation();
  const Language = LanguageCheck();
  const [aligne, setAlign] = useState("");
  const [gulfcoinPrice, setGulfcoinPrice] = useState(0);
  const [cryptoMod, setCryptoMod] = useState(false);
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { align } = useContext(DirectionContext);

  let calc = calculatedAmount.toFixed(3);

  const [user, setUser] = useState({
    id: localStorage.getItem("id"),
    shares: calc,
    day: dater,
    time: timer,
    meetingdate: dater + " " + timer,
    amountError: "",
    dayError: "",
    timeError: "",
  });
  const { sharePrice } = useContext(PriceContext);

  useEffect(() => {
    setGulfcoinPrice(sharePrice);
    setIsLoading(false);
  }, [sharePrice]);

  useEffect(() => {
    if (isNaN(calculatedAmount)) {
      setCalculatedAmount(0);
    }
  }, [calculatedAmount]);

  useEffect(() => {
    Language === "ar" ? setAlign("right") : setAlign("left");
  }, [Language]);

  useEffect(() => {
    setCalculatedAmount(amount / gulfcoinPrice);
  }, [amount]);

  useEffect(() => {
    setUser({
      id: localStorage.getItem("id"),
      shares: calc,
      day: dater,
      time: timer,
      meetingdate: dater + " " + timer,
    });
  }, [amount]);

  const validate = () => {
    let isError = false;

    if (amount === "") {
      isError = true;
      user.amountError = t("validate");
    }
    if (calculatedAmount === 0) {
      isError = true;
      user.amountError = t("validate");
    }
    if (dater === "") {
      isError = true;
      user.dayError = t("validate");
    }
    if (timer === "") {
      isError = true;
      user.timeError = t("validate");
    } else isError = false;

    setUser({ ...user });
    console.log(isError);

    return isError;
  };
  let err;

  const handleSubmit = (event) => {
    event.preventDefault();
    document.forms[0].reset();
    err = validate();
    if (err === false) {
      $.ajax({
        type: "POST",
        url: config.API_URL,
        data: {
          action: "update_meeting_date",
          id: user.id,
          meetingdate: dater + " " + timer,
          shares: calc,
          day: dater,
          time: timer,
          token: localStorage.getItem("token"),
        },
        success: function (response) {
          window.location.href = "/booksuccess";
        },
        error: function (error) {
          console.error("Error:", error);
        },
      });
    }
  };

  return (
    <ModalContainer onSubmit={handleSubmit}>
      {t("investnow")}

      {isLoading ? (
        <InputSkeleton />
      ) : (
        <Grid>
          <InputWrapper Align={aligne}>
            <InputTitle align={align}>{t("modalamount")}</InputTitle>
            <BorderedInput
              value={amount}
              name="amount"
              type="number"
              placeholder="$"
              id="amount"
              onChange={(e) => setAmount(e.target.value)}
            />

            {user.amountError !== "" && <Error>{user.amountError}</Error>}
          </InputWrapper>

          <InputWrapper Align={aligne}>
            <InputWrapper>
              <InputTitle>{t("shares")}</InputTitle>
              <BorderedInput value={calc} readOnly={true} />
            </InputWrapper>
          </InputWrapper>
          <GulfvsDolar />
        </Grid>
      )}

      <DatePicker
        setTimer={setTimer}
        setDater={setDater}
        dayError={user.dayError}
        timeError={user.timeError}
      />

      <Buttons text={t("booknow")} />

      {/* <BookWarning /> */}
    </ModalContainer>
  );
}
function DropdownMenu() {
  const [selected, setSelected] = useState({
    name: "BUSD",
    icon: "/assets/icons/token/bsc.svg",
    value: "busd",
  });

  const Drop = styled(Dropdown)`
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  `;
  const CoinWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `;
  const SelectedCoin = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    @media (max-width: 768px) {
      img {
        display: none;
      }
      font-size: 14px;
    }
  `;
  const Men = styled(Menu)`
    background-color: #1d1f25 !important;
    border: 1px solid #34384c !important;

    .ant-dropdown-menu-item {
      color: #5d6588 !important;
      :hover {
        opacity: 0.5 !important;
      }
    }
  `;

  const Tokens = [
    {
      name: "USDT",
      icon: "/assets/icons/token/usdt.svg",
      value: "usdt",
    },
    {
      name: "BUSD",
      icon: "/assets/icons/token/bsc.svg",
      value: "busd",
    },
  ];

  const menu = (
    <Men>
      {Tokens.map((token, index) => {
        return (
          <Menu.Item onClick={() => setSelected(token)} key={index}>
            <CoinWrapper>
              <img src={token.icon} alt={token.value} />
              {token.name}
            </CoinWrapper>
          </Menu.Item>
        );
      })}
    </Men>
  );

  return (
    <Drop overlay={menu} trigger={["hover"]}>
      <SelectedCoin>
        <img src={selected.icon} alt={selected.value} />
        {selected.name}
        <Space>
          <img src="/assets/icons/downarrow.svg" />
        </Space>
      </SelectedCoin>
    </Drop>
  );
}
