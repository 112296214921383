import React, { useState, useEffect, useContext } from "react";
import Navbar from "../components/dashboard/Navbar";
import styled from "styled-components";
import ControlPanel from "../components/dashboard/ControlPanel";
import News from "../sections/News";
import Table from "../components/Table";
import { Navigate } from "react-router-dom";
import SocialSection from "../components/dashboard/SocialSection";
import VideoCard from "../components/dashboard/VideoCard";
import Video from "sections/Video";
import LazyLoad from "react-lazy-load";
import Emailnotif from "components/Emailnotif";
import $ from "jquery";
import config from "config";
import { SettingContext } from "index";
import Telegrampopup from "components/Telegrampopup";
import ErrorBoundary from "components/ErrorBoundary";
import { NewApiContext } from "context/newapi";
import useBubbleRemover from "hooks";
import KidsBanner from "components/dashboard/KidsBanner";
import Nosharepopup from "components/Noshareepopup";
import OperationNotif from "components/OperationNotif";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px 350px;
  background: url("assets/dashbg.png?v=1.0");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  gap: 26px;

  @media (max-width: 1750px) {
    padding: 34px 160px;
  }

  @media (max-width: 1380px) {
    padding: 34px 50px;
  }
  @media (max-width: 1180px) {
    padding: 34px 20px;
  }
`;

export default function Dashboard() {
  const [isLoged, setIsLoged] = useState();
  const userData = useContext(NewApiContext);
  const isEmailNotif = userData.user.verification_email === "1" ? true : false;
  const isPhoneVerified =
    userData.user.verification_phone === "1" ? true : false;
  const isKycVerified = userData.user.verification_kyc === "1" ? true : false;
  useBubbleRemover(true);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  const [viewPorts, setViewPorts] = useState({
    video: "100vh",
    videocard: "70vh",
    news: "50vh",
    social: "60vh",
  });

  useEffect(() => {
    const handleResize = () => {
      if (1600 >= window.innerWidth >= 1100) {
        setViewPorts({
          video: "100vh",
          videocard: "80vh",
          news: "50vh",
          social: "",
        });
      } else if (window.innerWidth <= 1100) {
        setViewPorts({
          video: "80vh",
          videocard: "130vh",
          news: "100vh",
          social: "80vh",
        });
      } else {
        setViewPorts({
          social: "60vh",
          videocard: "70vh",
          video: "100vh",
          news: "50vh",
        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [window]);

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      setIsLoged(false);
    } else {
      setIsLoged(true);
    }
  }, []);

  if (isLoged === false) {
    return <Navigate to="/login" />;
  }
  return (
    <ErrorBoundary>
      <Navbar />
      <OperationNotif mt={false} />
      <DashboardContainer>
        {/* <KidsBanner /> */}
        {!isEmailNotif ? <Emailnotif isEmailNotif={isEmailNotif} /> : null}

        {/* <Nosharepopup /> */}

        <ControlPanel
          isPhoneVerified={isPhoneVerified}
          isKycVerified={isKycVerified}
          isEmailNotif={isEmailNotif}
        />
        <Table />

        <LazyLoad height={viewPorts.social} width={"100%"} threshold={0.15}>
          <SocialSection />
        </LazyLoad>

        <LazyLoad height={viewPorts.videocard} width={"100%"} threshold={0.15}>
          <VideoCard />
        </LazyLoad>

        <LazyLoad height={viewPorts.video} width={"100%"} threshold={0.15}>
          <Video margin={"15% 0px"} display="none" />
        </LazyLoad>

        <LazyLoad height={viewPorts.news} width={"100%"} threshold={0.15}>
          <News />
        </LazyLoad>
      </DashboardContainer>
    </ErrorBoundary>
  );
}
