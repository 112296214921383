import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

export default function LogPageMobilIcon() {
    const Logo = styled.img`

    position: absolute;
    top: 50px;
    left: 50px;
    display:none;
    @media (max-width: 1024px) {
        display:block;
    }
    @media (max-width: 550px) {
        left: 30px;
    }
`
    return (
        <Link to="/">
            <Logo src="/assets/icons/gulf.png" />
        </Link>
    )
}
