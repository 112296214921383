import React, { useEffect } from 'react'
import styled from 'styled-components'
import Navbar from '../components/dashboard/Navbar'

const NewsWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-end;
background-color: #141518;

background-image: url("/assets/dashbg.png");
background-repeat: no-repeat;
background-size: cover;
background-position:center;
color:#fff;
padding:40px 190px 40px 250px;
@media (max-width: 1400px) {
    grid-template-columns: 1fr;
    }
    @media (max-width: 900px) {
        padding:40px 90px;
        background-position:unset;
    }
    @media (max-width: 500px) {
        padding:40px 30px;
    }
`


const Title = styled.text`
font-weight: 700;
font-size: 32px;
line-height: 39px;
color: #FFFFFF;
margin-bottom: 30px;
`
const Wrapper = styled.div`
display:flex;
flex-direction:column;
display:flex;
flex-direction:column;
direction:rtl;
gap:20px;
p{
    margin:6px 0px;
}

h2 {
    margin:8px 0px;
}

img {
    aspect-ratio: 11/5;
    border-radius: 16px;
    max-width: 80%;
}
@media (max-width: 900px) {
    img {
        max-width: 100%;
    }
}
`
const Header = styled.div`
display:flex;
flex-direction:column;

`

export default function FirstCryptoBank() {


    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    return (
        <>
            <Navbar />
            <NewsWrapper>


                <Wrapper>
                    <Header>
                        <Title>                    ما هو مشروع بنك الخليج الرقمي ؟
                        </Title>
                        {/* <Persona>By Globe Newswire</Persona> */}
                    </Header>

                    <p class="has-text-align-right">مشروع <strong>بنك الخليج الرقمي</strong> هو مشروع بنكي متكامل يسمح للمستخدمين بإيداع الأموال بجميع فئاتها من دولار و يورو و باوند والكثير من العملات العربية و العالمية كما يسمح أيضا بتحويلها من حساب الى حساب آخر أو إلى أي مكان في العالم بلا اي حدود و بحرية مالية مطلقة</p>

                    <p class="has-text-align-right">بذات الوقت يستطيع المستخدم من خلال بنك الخليج الرقمي شراء وبيع العملات الرقمية بأفضل و وأقل العمولات عالميا وكذلك تحويلها إلى حسابات داخليه أخرى او إلى محافظ إلكترونية خارجية حسب رغبته وعلى مدار الساعة</p>

                    <p class="has-text-align-right">كما يستطيع المستخدم سحب أمواله سواءً كانت نقدية او رقمية (بعد تحويلها الى نقدية) من جميع مراكز و نقاط التسليم المنتشرة و بكثرة في جميع الدول العربية و محال الصرافة</p>

                    <p class="has-text-align-right">يمكن للمستخدم استخراج بطاقة صراف آلي صادرة من بنك الخليج الرقمي يستطيع من خلالها سحب الأموال النقدية من رصيده من أي صراف آلي موجود في العالم ويحمل شعار الفيزا او الماستر كارد</p>

                    <p class="has-text-align-right">كما يمكنه استخدام بطاقته لإجراء جميع المشتريات من أي محل أو مركز تجاري يقبل الدفع ببطاقة فيزا أو ماستر كارد</p>
                    <img src='/assets/news/1n.png' alt='Gulf Crypto Bank - بنك الخليج الرقمي' />

                    <h2 class="wp-block-heading has-text-align-right">رؤية مشروع <strong>بنك الخليج الرقمي</strong></h2>

                    <ul>
                        <li>حرية مالية مطلقة في التحويلات إلى أي مكان في العالم بشكل فوري و آمن دون أي تأخير..</li>

                        <li>بوابات دفع الكترونية آمنة تستخدمها المتاجر الإلكترونية لاستقبال مدفوعاتهم الإلكترونية من جميع بقاع الأرض بلا استثناء</li>
                    </ul>

                    <h2 class="wp-block-heading has-text-align-right">مميزات مشروع بنك الخليج الرقمي</h2>

                    <ul>
                        <li>السرعة و السهولة في جميع عمليات التعاملات الإلكترونية</li>

                        <li>سهولة فتح و توثيق الحساب لأي عميل و من جميع الجنسيات</li>


                        <li>سهولة الوصول لجميع نقاط البيع و السحب الفوري</li>



                        <li>حساب متعدد الأصول لأكثر من 100 عملة نقدية و أكثر من 300 عملة رقمية عالمية</li>



                        <li>نظام يعمل في جميع أنحاء العالم</li>
                    </ul>



                    <h2 class="wp-block-heading has-text-align-right"> كن شريكا في بنك الخليج الرقمي </h2>



                    <ul>
                        <li>قامت الشركة المطورة لهذا النظام البنكي المالي بالسماح لكل مَن يرغب بالاستثمار في هذا المشروع الأول من نوعه في العالم العربي بأن يستطيع شراء أي كمية من الأسهم المطروحة للاكتتاب حيث طرحت الشركة المطورة لهذا المشروع 490 مليون سهم من أصل مليار سهم (حيث تحتفظ الشركة بما قيمته 51 بالمئة من إجمالي الأسهم المطروحة)، و بهذا تتيح لمَن يرغب بشراء الأسهم بأن يصبح شريكا و حاملا لأسهم هذا المشروع النوعي</li>



                        <li>قيمة السهم الواحد هو (1 GULF)</li>



                        <li>إجمالي رأس مال المشروع هو مليار GULF<br />هي العملة الرقمية الرسمية لهذا المشروع GULF</li>
                    </ul>



                    <h2 class="wp-block-heading has-text-align-right">هل مشروع بنك الخليج الرقمي يحمل اي تراخيص؟</h2>



                    <ul>
                        <li>نعم هو مشروع بنك فعلي لديه كافة التراخيص ورأس المال المسجل رسميا في انجلترا</li>



                        <li>جارٍ تسجيل و ترخيص بنك الخليج الرقمي في جميع الدول التي تسمح بتبادل العملات الرقمية وتداولها مثل جورجيا و استونيا و كذلك الإمارات العربية المتحدة و سنغافوره</li>



                        <li>ستعمل إدارة المشروع على إدراج هذا المشروع رسميا في بورصة لندن وكذلك بورصة نازداك خلال عام 2023</li>
                    </ul>

                    <img src='/assets/news/2n.png' alt='Gulf Crypto Bank - بنك الخليج الرقمي 2' />

                    <h2 class="wp-block-heading has-text-align-right">الخدمات التي سيقدمها بنك الخليج لعملائه</h2>



                    <p class="has-text-align-right">تماما كأي بنك تجاري <strong>بنك الخليج الرقمي</strong> سيقدم مجموعة مميزة من الخدمات</p>



                    <p class="has-text-align-right">اولًا: حساب متعدد العملات النقدية لأكثر من 100 عملة نقدية بما فيها جميع العملات العربية وكذلك العملات الرقمية</p>



                    <p class="has-text-align-right">ثانيا: سهولة التحويل في نفس الحساب من عملة رقمية إلى عملة نقدية والعكس</p>



                    <p class="has-text-align-right">ثالثا: التحويل الداخلي من حساب إلى حساب آخر فورا وخلال ثوانٍ حتى 100 الف دولار يوميا</p>



                    <p class="has-text-align-right">رابعا: خدمة بطاقات الصراف الآلي</p>



                    <p class="has-text-align-right">كل عميل سيحصل على بطاقة صراف تعمل على جميع آلات الصراف التي تحمل شعار فيزا و ماستر كارد في جميع أنحاء العالم</p>



                    <p class="has-text-align-right">خامسا: السحب النقدي من خلال اكثر من خمسين مركز تسليم في العالم العربي أو محال الصرافة المعتمدة لدينا في بلدك</p>



                    <p class="has-text-align-right">سادسا: شراء أي عملة رقمية او دولار رقمي بعمولات أقرب إلى الصفر</p>



                    <p class="has-text-align-right">سابعا: إمكانية سحب جميع اصولك من العملات الرقميه و تحويلها إلى أي محفظة خاصة من خلال خاصية إنشاء محفظتكمن داخل الحساب</p>



                    <p class="has-text-align-right">ثامنا: توفير بوابات دفع إلكترونية للمتاجر الإلكترونية و الشركات التي ترغب بالحصول على بوابات دفع إلكترونية بالفيزا و الماستر كارد</p>



                    <p class="has-text-align-right">تاسعا: توفير اجهزة نظام الدفع بالبطاقات للمحال التجارية و الشركات</p>



                    <p class="has-text-align-right">عاشرا: توفير خاصية القروض الشخصية و التجارية مقابل ضمانات الرهن وعلى أسس المرابحة الاسلامية</p>


                    <img src='/assets/news/3n.png' alt='Gulf Crypto Bank - بنك الخليج الرقمي 3' />

                    <h1 class="wp-block-heading has-text-align-right">فائدة المستثمر في <a href="https://gulfcryptobankshares.com/">بنك الخليج الرقمي</a></h1>



                    <ul>
                        <li>المستثمر هو شريك رسمي في جميع الأرباح السنوية التي يحققها البنك فكما ان الشركه المطورة لهذا المشروع تملك 51% من الأرباح السنوية فإن 49% من أرباح البنك السنوية تُوزع على المستثمرين كل مستثمر حسب نسبته</li>



                        <li>المستثمر سيحصل على بوابة مستثمر يستطيع من خلالها متابعة شؤون استثماره يوميا ومتابعة حجم العمليات اليومية للبنك و الأرباح التي يجنيها منذ بدء التشغيل و حتى جلسة توزيع الارباح السنوية</li>



                        <li>حضور الاجتماعات الدورية واجتماعات المجلس العمومي للشركاء سواءً عبر الإنترنت او بشكل حضوري</li>



                        <li>ارتفاع سعر السهم بارتفاع عملة GULF</li>



                        <li>ارتفاع سعر السهم جراء الإدراج في البورصات العالمية مثل بورصة لندن و بورصة نازداك</li>



                        <li>يمكن للمستثمر ان يستثمر بأي عدد من الأسهم ويحصل بها على شهادة استثمار كما يحصل على صك ملكية للأسهم و حساب مستثمر ويتم تدوين اسمه في لائحة المستثمرين على الموقع الرسمي لبنك الخليج الرقمي</li>



                        <li>إذا كان المستثمر يمتلك أكثر من مليون سهم يعتبر من كبار المستثمرين و يدخل اسمه في السجل التجاري لبنك الخليج الرقمي كما يحصل على عضوية مجلس المستثمرين ويشارك في التصويت على القرارات المطروحة للتصويت من قبل مجلس الإدارة</li>



                        <li>يحق للمستثمر طرح اسمه للبيع عند نهاية السنة المالية لبنك الخليج الرقمي و خلال شهر من توزيع الأرباح السنوية</li>
                    </ul>



                    <h2 class="wp-block-heading has-text-align-right">معطيات هامة في بنك الخليج الرقمي</h2>



                    <ul>
                        <li>السحب و الإيداع النقدي متوفر في جميع نقاط و مراكز السحب المنتشرة في جميع البلاد العربية بالاضافة الى تركيا و ايران و فلسطين " غزة و الضفة الغربية " و أكثر من عشر دول في القارات الامريكية والأوروبية سواء مكاتب تابعة لبنك الخليج الرقمي أو محال صرافة أو وكلاء تسليم معتمدين</li>



                        <li>السحب و الإيداع النقدي متوفر جميع أيام الأسبوع (باستثناء السبت و الاحد) من الساعة التاسعة صباحا و حتى الخامسة مساءً وبحد أقصى عشرة آلاف دولار يوميا</li>



                        <li>السحب و الإيداع من خلال بطاقة الصراف الآلي تعمل 24/7 على مدار الساعه و بحد أقصى عشرة آلاف دولار يوميا</li>



                        <li>المشتريات عبر الإنترنت من خلال البطاقة يُحدد حسب رغبة العميل و بحد أقصى خمسين ألف دولار يوميا</li>



                        <li>رأس مال المشروع 1 مليار Gulf<br />او ما يعادلها (حسب السعر الحالي 40 مليون دولار)</li>
                    </ul>

                    <p class="has-text-align-right">الشركة المطورة تستحوذ على 51% اي ما يعادل 510 مليون<br />GULF</p>



                    <p class="has-text-align-right">مطروح للاكتتاب 49% اي ما يعادل 490 مليون<br />GULF</p>



                    <h2 class="wp-block-heading has-text-align-right">الإدارات</h2>



                    <ul>
                        <li>مجلس الإدارة (على اتصال مباشر مع مجلس كبار المستثمرين)</li>



                        <li>الإدارة المالية و إدارة المخاطر</li>



                        <li>إدارة التطوير و التقنيات</li>



                        <li>إدارة الدعم الفني</li>



                        <li>إدارة الأفرع و الوكلاء</li>
                    </ul>

                    <img src='/assets/news/4n.png' alt='Gulf Crypto Bank - بنك الخليج الرقمي 4' />


                    <h2 class="wp-block-heading has-text-align-right">خاصية الإحالة</h2>



                    <p class="has-text-align-right">هذه الخاصية المميزة و التي تمنح رابطا خاصا لكل من يملك حسابا مفعلا في <strong>بنك الخليج الرقمي</strong> (الحساب المفعل هو الحساب الذي يتم إيداع ما قيمته 10 دولار او أكثر)<br />يتمكن العميل من خلال خاصية الإحالة ونشر رابطه بتحقيق دخل إضافي للعميل حيث يحصل العميل على دولار رقمي واحد من كل حساب مفعل يتم فتحه من خلال الرابط الخاص به, و الأهم من ذلك أن أي عملية يقوم بها الحساب المحال له سواءً كانت تحويلات او سحوبات أو إيداعات أو شراء عملات رقميه أو نقديه أو استصدار بطاقة صراف أو أي تعاملات من خلال بطاقة الصراف سيحصل مالك رابط الإحالة على نسبة من جميع هذه العمليات تودع بشكل تلقائي و فوري في حسابه.</p>



                    <h2 class="wp-block-heading has-text-align-right">مميزات بطاقة GCB</h2>



                    <p class="has-text-align-right">هي بطاقة ائتمانية تعمل في جميع دول العالم و تحمل اسم صاحبها وتمكّن صاحبها من سحب أي مبلغ نقدي من أي جهاز صراف آلي يحمل شعار الفيزا او الماستر كارد</p>



                    <p class="has-text-align-right">السحب اليومي نقدا يصل إلى عشرة آلاف دولار يوميا<br />السحب اليومي للمشتريات يصل الى خمسين ألف دولار يوميا<br />الشراء عبر الانترنت<br />خاصية دفع الفواتير و الاشتراكات الشهرية<br />_ تجعل البطاقه حاملها مؤهلا للحصول على العديد من الخصومات عند استخدامها في الكثير من المحال التجارية و الأسواق في العديد من دول العالم كما تؤهل حاملها للدخول إلى العديد من لونج درجة رجال الأعمال في العديد من المطارات العربية و العالمية مجانا دون اي مقابل</p>



                    <h2 class="wp-block-heading has-text-align-right">حديث الخبراء</h2>



                    <p class="has-text-align-right">حديث الخبراء هي عبارة عن زاوية يستطيع كل مَن يقوم بفتح حساب في <strong>بنك الخليج الرقمي</strong> بأن يضع فيها مقالاته و تحليلاته وكذلك توصياته فيما يخص أي عملة رقمية بحيث توفر هذه الخاصية ركنا لكل عميل يستطيع من خلاله تقديم ما يملك من خبرات في العالم الرقمي ويستطيع نشر مقالاته و توصياته سواءً كانت مقالات كتابية او مصورة ويمكن الحصول بذلك على متابعين إضافة إلى التفاعل على هذه المقالات و إمكانية نشرها على جميع مواقع السوشيال ميديا و قنوات التليجرام.</p>

                </Wrapper>

            </NewsWrapper >
        </>

    )
}
