import React, { createContext, useState, useEffect } from "react";
import $ from "jquery";
import config from "../config";
export const PriceContext = createContext();

// Create a provider component
export const PriceProvider = (props) => {
  const [gulfPrice, setGulfPrice] = useState(null);
  const [sharePrice, setSharePrice] = useState(null);

  // Send the API request and store the data
  useEffect(() => {
    $.ajax({
      url: config.GET_SSHARES,
      type: "GET",
      dataType: "json",
      success: function (response) {
        setSharePrice(response);
      },
      error: function (error) {
        console.error("Error fetching data:", error);
      },
    });
    $.ajax({
      url: config.GET_1GULF,
      type: "GET",
      dataType: "json",
      success: function (response) {
        setGulfPrice(response);
      },
      error: function (error) {
        console.error("Error fetching data:", error);
      },
    });
  }, []);
  const value = { gulfPrice, sharePrice };
  // Pass the context to the wrapped components
  return (
    <PriceContext.Provider value={value}>
      {props.children}
    </PriceContext.Provider>
  );
};
