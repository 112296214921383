import React, { useState, useEffect, memo, useContext } from "react";
import styled from "styled-components";
import { DirectionContext } from "context/direction";
import { useTranslation } from "react-i18next";
import { PriceContext } from "context/prices";

const PriceWrapper = memo(styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  grid-column: 1/3;
  white-space: nowrap;
`);
export default function Shareprice({ direction }) {
  const [gulfcoinPrice, setGulfcoinPrice] = useState(0);
  const { sharePrice } = useContext(PriceContext);
  const { align } = useContext(DirectionContext);
  const { t } = useTranslation();
  // console.log("gulfPrice: " +gulfPrice , "shareprice"+ sharePrice)

  useEffect(() => {
    if (sharePrice !== null) {
      setGulfcoinPrice(sharePrice.toFixed(4));
    } else {
      setGulfcoinPrice(0);
    }
  }, [sharePrice]);

  return (
    <PriceWrapper direction={direction} align={align}>
      {" "}
      <span>
        {" "}
        $ {gulfcoinPrice} = 1 {t("share")}
      </span>
    </PriceWrapper>
  );
}
