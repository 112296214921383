import * as React from "react";
import styled, { keyframes } from 'styled-components'

const outer = keyframes`
0% {transform: scale(0); opacity: 0;}
100% {transform: scale(1); opacity: 1;}

`
const inner = keyframes`

30% {transform: scale(0); opacity: 0;}
100% {transform: scale(1); opacity: 1;}

`

const OuterCircle = styled.div`
display:flex;
justify-content:center;
align-items:center;
width: 200px;
height: 200px;
background: #0f8a3c;

border-radius: 50%;
animation: ${outer} 1s ease-in-out ;


`
const InnerCircle = styled.div`

width: 160px;
height: 160px;
border-radius: 50%;
background: #FFFFFF;

animation: ${inner} 1s ease-in-out ;




`

const check = keyframes`
70% { stroke-dasharray: 0 100; }
100% { stroke-dasharray: 100 0; }
`;




const TickSvg = styled.svg`
animation: ${check} 1s ease-in-out ;
stroke: #2ee815;
fill: none;
margin-top:.5rem;
stroke-linecap: round;
stroke-linejoin: round;
width: 99%;
height:99%;
`


export const SuccessAnimation = () => {

    return (

        <OuterCircle>
            <InnerCircle>
                <TickSvg xmlns="http://www.w3.org/2000/svg" >
                    <g fill="none" stroke="#1df569" stroke-width="2">
                        <polyline stroke="#0f8a3c" stroke-width="14" points="43.5,77.8 63.7,97.9 112.2,49.4 " />
                    </g>
                </TickSvg>
            </InnerCircle>
        </OuterCircle>
    );
};
