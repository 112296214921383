import React, { createContext, useState, useEffect } from "react";
import config from "../config";
import $ from "jquery";
import { local } from "web3modal";

export const NewApiContext = createContext();

export const NewApiProvider = (props) => {
  const [userData, setUserData] = useState({
    user: {
      id: "",
      name: "",
      lastname: "",
      country: "",
      totalshares: "",
      imgVersion: "",
      banned: "",
      phone: "",
      phone2: "",
      bio: "",
      occupation: "",
      birthday: "",
      verification_kyc: "",
      verification_email: "",
      verification_phone: "",
      walletaddress: "",
      avatar: "",
      shouldVote: "",
    },
    verified: false,
    certificates: [
      {
        id: "",
        shares: "",
        isgold: "",
      },
    ],
  });

  async function getUserAvatar() {
    return new Promise((resolve) => {
      $.ajax({
        type: "post",
        url: config.AVATAR_EXIST + "?fileName=" + localStorage.getItem("id"),
        data: {
          token: localStorage.getItem("token"),
        },
        success: function (response) {
          let avatarUrl;
          if (response.status === "error") {
            avatarUrl = "/assets/icons/avatar.svg";
          } else {
            avatarUrl =
              config.AVATAR_COMPRESSED2 +
              "?filename=" +
              localStorage.getItem("id") +
              ".png";
          }
          resolve(avatarUrl);
        },
      });
    });
  }

  async function getKidAvatar(childId) {
    return new Promise((resolve) => {
      $.ajax({
        type: "post",
        url:
          config.AVATAR_EXIST +
          "?fileName=" +
          localStorage.getItem("id") +
          "_" +
          childId +
          ".png",
        data: {
          token: localStorage.getItem("token"),
        },
        success: function (response) {
          let avatarUrl;
          if (response.status === "error") {
            avatarUrl = "/assets/icons/avatar.svg";
            // console.log(response.status);
          } else {
            avatarUrl =
              config.AVATAR_COMPRESSED2 +
              "?filename=" +
              localStorage.getItem("id") +
              "_" +
              childId +
              ".png";
          }
          resolve(avatarUrl);
        },
      });
    });
  }

  async function getUserData() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: config.USER_INFO + "?id=" + localStorage.getItem("id"),
        method: "post",
        data: {
          token: localStorage.getItem("token"),
        },
        success: (response) => {
          resolve(response);
          if (response.includes("user is banned")) {
            if (window.location.pathname !== "/banned") {
              localStorage.clear();
              window.location.href = "/banned";
              return;
            }
            return;
          }
        },
        error: (error) => {
          console.error("Error fetching user data:", error);
          reject(error);
        },
      });
    });
  }

  useEffect(() => {
    async function fetchData() {
      const avatarUrl = await getUserAvatar();
      const userDataResponse = await getUserData();

      const kidAvatarUrls = await Promise.all(
        userDataResponse?.children.map((child) => getKidAvatar(child.id))
      );
      setUserData({
        user: {
          id: userDataResponse.user.id,
          name: userDataResponse.user.name,
          lastname: userDataResponse.user.lastname,
          country: userDataResponse.user.user_country,
          totalshares: userDataResponse.user.totalShares,
          phone: userDataResponse.user.phone,
          phone2: userDataResponse.user.phone2,
          bio: userDataResponse.user.bio,
          banned: userDataResponse.user.banned,
          occupation: userDataResponse.user.occupation,
          imgVersion: userDataResponse.user.imgVersion,
          birthday: userDataResponse.user.birthday,
          verification_kyc: userDataResponse.user.verification_kyc,
          verification_email: userDataResponse.user.verification_email,
          verification_phone: userDataResponse.user.verification_phone,
          walletaddress: userDataResponse.user.walletaddress,
          personal_address: userDataResponse.user.personal_address,
          avatar: avatarUrl,
          vote: userDataResponse.user.vote,
          // vote:"-1"
        },
        shouldVote: userDataResponse.shouldVote,
        // shouldVote: false, //this is for testing delete it later
        sharesWithoutKids: userDataResponse.sharesWithoutKids,
        //sharesWithoutKids: 5000, //this is for testing delete it later
        children: userDataResponse.children.map((child, index) => {
          return {
            id: child.id,
            name: child.name,
            birthday: child.birthday,
            shares: child.shares,
            img: kidAvatarUrls[index],
          };
        }),
        verified: userDataResponse.verified,
        certificates: userDataResponse.certificates.map((certificate) => {
          return {
            id: certificate.id,
            shares: certificate.shares,
            isgold: certificate.isgold,
          };
        }),
      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("imgVersion", userData.user.imgVersion);
  }, [userData.user.imgVersion]);

  return (
    <NewApiContext.Provider value={userData}>
      {props.children}
    </NewApiContext.Provider>
  );
};
