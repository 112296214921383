import React, { useContext, lazy } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider-dots.css";
import Slider from "react-slick";
import WindowSize from "../WindowSizer";
import { useTranslation } from "react-i18next";
import lozad from "lozad";
import { DirectionContext } from "context/direction";
import $ from "jquery";
import config from "../config";
const Sect = styled.section`
  position: relative;
  top: -60px;
  @media (max-width: 1024px) {
    top: -100px;
  }
`;
const CardSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-content: center;
  grid-gap: 20px 60px;
  justify-content: center;
  @media (max-width: 1500px) {
    grid-gap: 10px 30px;
  }
`;
const MobileText = styled.h1`
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 0px;
  text-align: ${(props) => props.align};
  margin-bottom: 30px;
  @media (max-width: 1024px) {
  }
`;
const Mobile = styled.div`
  margin-left: 14px;

  .slick-track {
    margin: auto !important;
  }

  .slick-prev:before {
    display: none;
    position: absolute;
    color: #777e90;
    font-size: 30px;
  }
  .slick-next:before {
    color: #777e90;
    display: none;
    position: absolute;
    font-size: 30px;
  }
  .slick-next {
    margin-right: 1vw;
  }
  .slick-prev {
    margin-left: 1vw;
    z-index: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  margin-bottom: 160px;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-align: right;
  @media (max-width: 1024px) {
    font-size: 28px;
  }
`;
const PrevNextButton = styled.button`
  background-image: url(${(props) => props.img}) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  width: 40px !important;
`;
const Item = styled.h1`
  place-self: flex-end;
  margin-right: 10px;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 0px;
  @media (max-width: 1024px) {
    font-size: 28px;
  }
  place-self: ${(props) => props.align};

  ${(props) =>
    props.align === "flex-start" &&
    `
  margin-left:10px;
`}
`;
export default function CardSection() {
  const { i18n } = useTranslation();
  const { rightLeft } = useContext(DirectionContext);
  const { align } = useContext(DirectionContext);
  const isMobilee = WindowSize();

  const Cards = [
    { hover: "card-ar/card1-h.png", name: "/card-ar/card1.png" },
    { hover: "card-ar/card2-h.png", name: "/card-ar/card2.png" },
    { hover: "card-ar/card3-h.png", name: "/card-ar/card3.png" },
    { hover: "card-ar/card4-h.png", name: "/card-ar/card4.png" },
    { hover: "card-ar/card5-h.png", name: "/card-ar/card5.png" },
    { hover: "card-ar/card6-h.png", name: "/card-ar/card6.png" },
    { hover: "card-ar/card7-h.png", name: "/card-ar/card7.png" },
    { hover: "card-ar/card8-h.png", name: "/card-ar/card8.png" },
  ];
  const MCards = [
    { name: "/mobile/card1.png" },
    { name: "/mobile/card2.png" },
    { name: "/mobile/card3.png" },
    { name: "/mobile/card4.png" },
    { name: "/mobile/card5.png" },
    { name: "/mobile/card6.png" },
    { name: "/mobile/card7.png" },
    { name: "/mobile/card8.png" },
  ];
  const EngMCards = [
    { name: "/mobile/card1-eng.png" },
    { name: "/mobile/card2-eng.png" },
    { name: "/mobile/card3-eng.png" },
    { name: "/mobile/card4-eng.png" },
    { name: "/mobile/card5-eng.png" },
    { name: "/mobile/card6-eng.png" },
    { name: "/mobile/card7-eng.png" },
    { name: "/mobile/card8-eng.png" },
  ];
  const EngCards = [
    { hover: "card-eng/card1-h.png", name: "/card-eng/card1.png" },
    { hover: "card-eng/card2-h.png", name: "/card-eng/card2.png" },
    { hover: "card-eng/card3-h.png", name: "/card-eng/card3.png" },
    { hover: "card-eng/card4-h.png", name: "/card-eng/card4.png" },
    { hover: "card-eng/card5-h.png", name: "/card-eng/card5.png" },
    { hover: "card-eng/card6-h.png", name: "/card-eng/card6.png" },
    { hover: "card-eng/card7-h.png", name: "/card-eng/card7.png" },
    { hover: "card-eng/card8-h.png", name: "/card-eng/card8.png" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevNextButton img="/assets/PrevArrow.svg" />,
    nextArrow: <PrevNextButton img="/assets/NextArrow.svg" />,
    arrows: true,
    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 3.4,
          infinite: false,
        },
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3.01,
          infinite: true,
          centerMode: true,
        },
      },

      {
        breakpoint: 1100,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3.01,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2.01,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1.01,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  useEffect(() => {
    const observer = lozad(".lozad", {
      loaded: function (el) {
        el.classList.add("fade");
      },
    });
    observer.observe();
  }, []);

  const { t } = useTranslation();

  let done = false;
  useEffect(() => {
    var settings = {
      url: config.SECTION_VIEWS,
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        section: "CardsSection",
      },
    };
    if (done === false) {
      $.ajax(settings).done(function (response) {});
    }
    done = true;
  }, []);

  return !isMobilee ? (
    <Wrapper>
      <Sect id="cards"></Sect>
      <Item align={align}>{t("cTitle")}</Item>
      <CardSectionWrapper>
        {i18n.language === "ar"
          ? Cards.map((card, index) => {
              return (
                <Card
                  className="lozad"
                  image={card.name}
                  text={card.text}
                  hover={card.hover}
                  key={index}
                  rel="preload"
                />
              );
            })
          : EngCards.map((card, index) => {
              return (
                <Card
                  image={card.name}
                  text={card.text}
                  hover={card.hover}
                  key={index}
                  rel="preload"
                />
              );
            })}
      </CardSectionWrapper>
    </Wrapper>
  ) : (
    <Mobile>
      <Sect id="cards"></Sect>
      <MobileText align={rightLeft}>{t("cTitle")}</MobileText>
      <Slider {...settings}>
        {i18n.language === "ar"
          ? MCards.map((card, index) => {
              return <Card image={card.name} rel="preload" key={index} />;
            })
          : EngMCards.map((card, index) => {
              return <Card image={card.name} rel="preload" key={index} />;
            })}
      </Slider>
    </Mobile>
  );
}
