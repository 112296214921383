import React, { useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import config from "../../config";

const Dragzone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 2px dashed #34384c;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }

  input {
    display: flex;
    z-index: 2;
    color: transparent;
    width: 100%;
    height: 130px;
  }
`;
const Drag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
`;
const StyledForm = styled.div`
  width: 100%;
  margin-bottom: 14px;
  cursor: pointer;
  input[type="file"] {
    background: transparent;
    border: none !important;
    opacity: 0;
    cursor: pointer;
  }
`;
const Comp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 14px !important;
  z-index: 1;
  cursor: pointer;
  margin-top: 12px;
  img {
    width: 56px;
    height: 56px;
  }
`;
var alreadyUploaded = false;

function DragDropUpload(fileInputRef, setIdStatus, idStatus, setKycc, setKyc) {
  if (alreadyUploaded) return;

  alreadyUploaded = true;
  // var fileInput = document.getElementById("fileToUpload");
  const file = fileInputRef.current.files[0];
  const token = localStorage.getItem("token");
  var formData = new FormData();
  formData.append("fileToUpload", file);
  formData.append("fileName", localStorage.getItem("id"));
  formData.append("token", token);
  fetch(config.KYC_UPLOAD, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      // document.getElementById("kycsuccess").style.display = "flex";
      // const response = JSON.parse(resp)
      console.log(response);
      if (response.status === 200) {
        console.log(response.status);

        if (setKycc) {
          setKycc(true);
        }
        if (setKyc) {
          setKyc(true);
        }

        document.getElementById("myForm").style.display = "none";
        if (setIdStatus) {
          setIdStatus({
            status: false,
            image: "/assets/icons/refresh.svg",
            text: "kycuploaded",
          });
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
export default function DragDrop({
  setIdStatus,
  idStatus,
  settings,
  setKycc,
  setKyc,
}) {
  const { t } = useTranslation();
  const fileInputRef = useRef();

  useEffect(() => {
    document
      .getElementById("myForm")
      .addEventListener("drag", function (event) {
        event.preventDefault();
        DragDropUpload(fileInputRef, setIdStatus, idStatus, setKycc, setKyc);
      });

    document
      .getElementById("myForm")
      .addEventListener("change", function (event) {
        event.preventDefault();
        DragDropUpload(fileInputRef, setIdStatus, idStatus, setKycc, setKyc);
      });
  }, []);

  return (
    <StyledForm id="myForm">
      <Dragzone id="dropzone">
        <input
          ref={fileInputRef}
          type="file"
          name="fileToUpload"
          id="fileToUpload"
        />

        <Comp>
          <img src="/assets/icons/dragdrop.svg" alt="dragdrop" />

          <Drag>{t("dragdropfield")}</Drag>
        </Comp>
      </Dragzone>
    </StyledForm>
  );
}
