import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DirectionContext } from "context/direction";
import { SettingContext } from "context/modals";
import { Link } from "react-router-dom";

const NotifWrapper = styled.div`
  display: flex;
  position: absolute;
  color: #bfbfbf;
  top: 80px;
  background: rgb(29 30 32);
  align-items: center;
  justify-content: center;
  gap: 12px;
  left: 0;
  right: 0;
  font-size: 16px;
  border: 1px solid rgb(98 92 95);
  text-align: left;
  cursor: pointer;
  a {
    font-weight: 800;
    color: #fff;
    text-decoration: underline;
  }
  z-index: 20;
  ${(props) =>
    props.direction === "rtl"
      ? "padding: 8px 1rem; text-align:right;"
      : "padding: 8px 1rem; "}
  box-shadow: 0px 0px 4px 0px rgb(255 255 255 / 28%);
  font-weight: 500;
  direction: ${(props) => props.direction};
  button {
    display: flex;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    align-items: center;
    align-self: flex-start;
  }
  @media (max-width: 768px) {
    margin: 0px 0px;
    top: 80px;
    padding: 8px 1rem;
  }
`;

export default function PersonalCheckPopup({ isEmailNotif }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const { direction } = useContext(DirectionContext);
  const { isSettingOpen, setSettingIsOpen } = useContext(SettingContext);
  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  return (
    isOpen && (
      <NotifWrapper onClick={() => setIsOpen(!isOpen)} direction={direction}>
        <button onClick={() => setIsOpen(!isOpen)}>
          <img src="/assets/icons/greycancel.svg" alt="cancel" />
        </button>

        {direction === "ltr" ? (
          <div>
            <strong>Warning:</strong> Please make sure of the identity of the
            people who are communicating with you. Do not share personal or
            banking information except with the people who are identified in our
            official website. If someone is communicating with you via phone,
            WhatsApp or Telegram and does not appear in our{" "}
            <Link to={"/supportcheck"}> Team Identifier </Link>, please contact
            our customer service immediately.
          </div>
        ) : (
          <div>
            <strong>تحذير:</strong> يرجى التأكد من هوية الأشخاص الذين يتواصلون
            معكم. لا تقوموا بمشاركة المعلومات الشخصية أو المصرفية إلا مع الأشخاص
            المعرفين في الموقع الرسمي لدينا. إذا كان هناك شخص يتواصل معك عبر
            الهاتف أو واتساب أو تلغرام ولا يظهر في الصفحة{" "}
            <Link to={"/supportcheck"}> "تحقق من موظفينا"</Link>، يرجى الاتصال
            بخدمة العملاء لدينا فوراً
          </div>
        )}
      </NotifWrapper>
    )
  );
}
