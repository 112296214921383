import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Signout from "./Signout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HamburgerMenu } from "../HamburgerMenu";
import WindowSize from "../../WindowSizer";
import Support from "./modals/SupportButton";
import { Flex } from "components/Layouts";
import DropdownMenu from "components/DesktopLang";
import { DirectionContext } from "context/direction";

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: ${(props) => props.direction};
  padding: 0 4rem;
  background-color: #141518;
  color: #fff;
  height: 80px;

  img:first-child {
    margin-top: 8px;
  }
  @media (max-width: 768px) {
    padding: 0 24px;
    justify-content: space-between;
  }
`;
const Logotext = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  @media (max-width: 1650px) {
    gap: 20px;
    width: 100%;
    justify-content: initial;
  }
  @media (max-width: 1580px) {
    gap: 16px;
    width: 100%;
    justify-content: initial;
  }

  @media (max-width: 1420px) {
    width: 100%;
    gap: 20px;
    justify-content: space-between;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-weight: 700;

  @media (max-width: 768px) {
    img {
      max-width: 170px;
    }
  }
  font-size: 18px;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
const Hide = styled.div`
  @media (max-width: 1420px) {
    display: none !important;
  }
`;
const Img = styled.img`
  position: absolute;
  width: unset !important;
  height: 22px !important;
  padding: 30px !important;
`;
const StyledBundle = styled(StyledLink)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export default function Navbar() {
  const { t } = useTranslation();
  const { direction } = useContext(DirectionContext);
  const [isMobilee, setIsMobilee] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1420) {
      setIsMobilee(true);
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
      setIsMobilee(false);
    }
  }, []);

  useEffect(() => {
    const HandleResize = () => {
      if (window.innerWidth > 1420) {
        setIsDesktop(true);
        setIsMobilee(false);
      } else {
        setIsMobilee(true);
        setIsDesktop(false);
      }
    };
    window.addEventListener("resize", HandleResize);

    return () => {
      window.removeEventListener("resize", HandleResize);
    };
  }, []);
  const [isLoged, setIsLoged] = useState();

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      setIsLoged(false);
    } else {
      setIsLoged(true);
    }
  }, []);
  return (
    <Nav direction={direction}>
      <Logotext>
        <StyledLink to={"/"}>
          <img src="/assets/rightlogo.svg" alt="gulfbank" />
        </StyledLink>
        {isMobilee ? (
          <HamburgerMenu />
        ) : (
          <>
            <StyledLink to={"/"}>{t("home")}</StyledLink>
            <StyledLink to={"/#progressbar"}>{t("nav3")}</StyledLink>
            <StyledLink to={"/#carrousel"}>{t("nav2")}</StyledLink>
            <StyledLink to={"/referral"}>{t("nav4")}</StyledLink>
            {/* <StyledLink to={"/licences"}>{t("licences")}</StyledLink> */}
           
            <StyledLink to="/supportcheck">{t("teamidentifier")}</StyledLink>
            {/* <StyledBundle to="/packages">
              <div>{t("navbundle")}</div>
              <Img src="/assets/packages/goldenmark.svg" />
            </StyledBundle> */}
            <StyledLink to="/bubbles">{t("kidszone")}</StyledLink>
            <StyledLink to="/candidates">{t("election")}</StyledLink>
            <a href="https://www.gcbex.com">{t("ourPlatform")}</a>
          </>
        )}
      </Logotext>
      <Hide>
        <Flex gap="20px" alignItem="center">
          <DropdownMenu />
          <Signout />
        </Flex>
      </Hide>
    </Nav>
  );
}
