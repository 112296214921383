import React, { useEffect } from "react";
import styled from "styled-components";
import { Container } from "../Layouts";
import SocialMedia from "./SocialMedia";
import { useTranslation } from "react-i18next";
import LanguageCheck from "../../langcheck";
import $ from "jquery";
import config from "../../config";
const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 60px;
  color: #ffffff;
  direction: ${(props) => props.direction};
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    place-items: center;
    grid-gap: 24px;
  }
`;
const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;

  div:last-child {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 1024px) {
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    gap: 14px;
    div:last-child {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }
`;
const Gridd = styled(Grid)`
  margin: 120px 0px 0px 0px;
  @media (max-width: 1024px) {
    margin: 40px 0px 80px 0px;
    img {
      max-width: 60%;
      order: -1;
    }
    margin-bottom: 80px;
  }
`;
export default function SocialSection() {
  const { t } = useTranslation();
  const direction = LanguageCheck() === "ar" ? "right" : "left";
  const ltr = LanguageCheck() === "ar" ? "rtl" : "ltr";
  let done = false;
  useEffect(() => {
    var settings = {
      url: config.SECTION_VIEWS,
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        section: "SocialMedia",
      },
    };
    if (done === false) {
      $.ajax(settings).done(function (response) {});
    }
    done = true;
  }, []);

  return (
    <Gridd direction={ltr}>
      <Container justify="center" gap="20px" column>
        <TextArea direction={direction}>
          <div> {t("social.title")} </div>
          <div>{t("social.subtitle")}</div>
        </TextArea>
        <SocialMedia
          youtube={"https://www.youtube.com/@gulfcryptobank"}
          whatsapp={"https://l.linklyhq.com/l/1itUk"}
          telegram={"https://t.me/gulfcoingroup"}
          tiktok={"https://www.tiktok.com/@gcryptobank"}
        />
      </Container>
      <a href="https://www.gcbex.com/"> <img src="/assets/GCB-logo.png" alt="social-media" /></a>
     
    </Gridd>
  );
}
