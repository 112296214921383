import Navbar from "components/Navbar";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const BanWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 80px);
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #1d1f25;
  color: #fff;
  line-height: 1;
  text-align: center;
  margin-top: 80px;
  div:nth-child(2) {
    font-size: 40px;
    font-style: normal;
    margin: 20px 0;
    font-weight: 700;
  }
  div:nth-child(3) {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    width: 40%;
  }

  @media (max-width: 768px) {
    height: 100vh;
    max-width: 100%;
    margin-top: 20px;

    div:nth-child(2) {
      font-size: 30px;
    }
    div:nth-child(3) {
      font-size: 20px;
      width: 100%;
    }
  }
`;

export default function Banneduser() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <BanWrapper>
        <img src="/assets/icons/banned.svg" alt="banned" />
        <div> {t("banned.title")} </div>
        <div> {t("banned.content")} </div>
      </BanWrapper>
    </>
  );
}
